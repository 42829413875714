import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useTheme } from "../../theme/ThemeContext";

const themePalette = {
  light: {
    textColor: "rgba(0, 0, 0, 0.9)", // Example color for light theme
    chartColor: "rgba(0, 0, 0, 0.8)", // Example chart color for light theme
    gridLineColor: "#e0e0e0", // Light theme grid line color
  },
  dark: {
    textColor: "rgba(255, 255, 255, 0.9)", // Example color for dark theme
    chartColor: "rgba(255, 255, 255, 0.8)", // Example chart color for dark theme
    gridLineColor: "#404040", // Dark theme grid line color
  },
};

const ScatterChart1 = ({ data, chartId, titleText, handlePointClick }) => {
  const chartRef = useRef(null); // Use useRef for the chart canvas
  const { theme } = useTheme(); // theme will be either 'light' or 'dark'
  const themeColors = themePalette[theme]; // Get colors for current theme

  useEffect(() => {
    const createChart = () => {
      if (!chartRef.current) return; // Ensure the ref is set

      const ctx = chartRef.current.getContext("2d");
      let chartStatus = Chart.getChart(chartId); // Check if chart instance exists
      if (chartStatus) {
        chartStatus.destroy(); // Destroy existing chart instance before creating a new one
      }

      const chart = new Chart(ctx, {
        type: "scatter",
        data: {
          datasets: [
            {
              label: "Scatter Dataset",
              data: data, // Use passed data
              backgroundColor: "rgba(255, 99, 132, 1)",
              pointRadius: 10,
              hoverRadius: 12, // Slightly larger hover radius
              pointHoverBackgroundColor: "red",
              hoverBorderWidth: 2,
            },
          ],
        },
        options: {
          onClick: function (event, elements, chart) {
            handlePointClick(event, elements, this);
          },
          onHover: (event, elements, chart) => {
            // Remove highlight class from all rows
            document.querySelectorAll(".highlight-row").forEach((row) => {
              row.classList.remove("highlight-row");
            });

            if (elements.length > 0) {
              const firstElement = elements[0];
              const hoveredData =
                chart.data.datasets[firstElement.datasetIndex].data[
                  firstElement.index
                ];
              const hoveredId = hoveredData.id; // Assuming each data point includes the ID

              // Find the corresponding table row and add the highlight class
              const rowToHighlight = document.querySelector(
                `[data-rbd-draggable-id='${hoveredId}']`
              );
              if (rowToHighlight) {
                rowToHighlight.classList.add("highlight-row");
              }
            }
          },
          scales: {
            x: {
              type: "linear",
              position: "bottom",
              ticks: {
                callback: function (value) {
                  return value + " ML";
                },
                color: themeColors.chartColor,
                font: {
                  size: 14, // Set the font size for the y-axis tick labels
                  weight: 600,
                },
              },
              grid: {
                display: false,
              },
              color: themeColors.gridLineColor,
            },
            y: {
              ticks: {
                callback: function (value) {
                  return "$" + value;
                },
                font: {
                  size: 14, // Set the font size for the y-axis tick labels
                  weight: 600,
                },
                color: themeColors.chartColor,
              },
              grid: {
                color: themeColors.gridLineColor,
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  const listingId = context.raw.id;
                  return `Listing ID: ${listingId}. Price Per ML: $${context.raw.y}. Volume: ${context.raw.x} ML`;
                },
              },
            },
            title: {
              display: true,
              text: titleText,
              color: themeColors.textColor,
              font: {
                size: 24, // Title font size
                weight: 500,
              },
            },
            legend: {
              display: false,
            },
          },
        },
      });
    };

    createChart();
  }, [data, chartId, titleText, theme, handlePointClick]); // Re-run when dependencies change

  return (
    <canvas
      ref={chartRef}
      id={chartId}
      style={{ height: "300px", maxHeight: "300px", width: "100%" }}
    ></canvas>
  );
};

export default ScatterChart1;

import React, { useEffect } from "react";
import Badge from "@atlaskit/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import CalendarHeatmap from "../../component/charts/calendarHeatmap";
import FinancialChart from "../../component/charts/financialChart"; // Adjust the import path as needed
import AreaSplineChart from "../../component/charts/areaSplineChart"; // Adjust the import path as needed

const PriceHistory = () => {
  useEffect(() => {}, []);

  return (
    <div className="base-page">
      <div>
        <h2>Trade Heat Map</h2>
        <h5 style={{ opacity: 0.8 }}>
          Visualize Trade Activity and Market Movements with Heat Maps
        </h5>
      </div>
      <div style={{ paddingTop: "100px" }}>
        <CalendarHeatmap />
      </div>

      <div></div>
    </div>
  );
};

export default PriceHistory;

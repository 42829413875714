import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isAuth } from "../../action/authAction";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WaterListingSearchSectionDashboard from "../../component/section/waterListingSearchSectionDashboard";
import HomepageDataSection from "../../component/section/homepageDataSection";
import Footer from "../../component/misc/footer";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ececec",
    padding: "20px",
  },
  halfGridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)", // Default grid layout
    gap: "10px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)", // Stacking the columns when width is less than 1200px
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)", // Stacking the columns when width is less than 1200px
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // Default grid layout
    gap: "10px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)", // Stacking the columns when width is less than 1200px
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)", // Stacking the columns when width is less than 1200px
    },
  },
}));

const Index = () => {
  const history = useHistory(); // Initialize useHistory
  const { theme } = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailConfirmationSuccess = queryParams.get("emailConfirmationSuccess");
  const marketQueryParam = queryParams.get("market"); // Get the 'market' query parameter

  const [market, setMarket] = useState(
    marketQueryParam ? decodeURIComponent(marketQueryParam) : null
  ); // Decode the query param
  const [zone, setZone] = useState(null);

  if (emailConfirmationSuccess && emailConfirmationSuccess === "true") {
    toast.success("Email has been confirmed!");
  }

  const handleZoneChange = (newZone) => {
    setZone(newZone);
  };

  const handleMarketChange = (newMarket) => {
    setMarket(newMarket);
  };

  // Effect to update 'market' whenever 'location.search' changes
  useEffect(() => {
    const marketQueryParam = new URLSearchParams(location.search).get("market");
    if (marketQueryParam) {
      setMarket(decodeURIComponent(marketQueryParam)); // Decode if encoded
    }
  }, [location.search]); // Listen for changes in the query string

  return (
    <div className="base-page">
      <div style={{ display: "flex", width: "100%" }}>
        <h2 style={{ width: "100%" }}>
          Hello {isAuth().name.split(" ")[0]} 👋
        </h2>
      </div>
      <br />
      <WaterListingSearchSectionDashboard
        onZoneChange={handleZoneChange}
        onMarketChange={handleMarketChange}
        selectedMarket={market}
        selectedZone={zone}
      />
      <HomepageDataSection
        market={market}
        zone={zone}
        onMarketChange={handleMarketChange}
        onZoneChange={handleZoneChange}
      />
    </div>
  );
};

export default Index;

export const forwardAllocationRegions = [
  {
    options: [
      {
        label: "All",
        value: "",
        tradeZone: "",
        tradeZoneDetails: "",
      },
    ],
  },
  {
    label: "NSW",
    options: [
      {
        label: "NSW Murrumbridgee_Zone 13",
        value: "NSW Murrumbridgee_Zone 13",
        tradeZone: "NSW Murrumbridgee_Zone 13",
        tradeZoneDetails: "NSW Murrumbridgee_Zone 13",
        state: "NSW",
      },

      {
        label: "NSW Murray (Below Choke)_Zone 11",
        value: "NSW Murray (Below Choke)_Zone 11",
        tradeZone: "NSW Murray (Below Choke)_Zone 11",
        tradeZoneDetails: "NSW Murray (Below Choke)_Zone 11",
        state: "NSW",
      },
      {
        label: "NSW Murray (Above Choke)_Zone 10",
        value: "NSW Murray (Above Choke)_Zone 10",
        tradeZone: "NSW Murray (Above Choke)_Zone 10",
        tradeZoneDetails: "NSW Murray (Above Choke)_Zone 10",
        state: "NSW",
      },
    ],
  },
  {
    label: "VIC",
    options: [
      {
        label: "VIC Murray (Below Choke)_Zone 7",
        value: "VIC Murray (Below Choke)_Zone 7",
        tradeZone: "VIC Murray (Below Choke)_Zone 7",
        tradeZoneDetails: "VIC Murray (Below Choke)_Zone 7",
        state: "VIC",
      },

      {
        label: "VIC Murray (Above Choke)_Zone 6",
        value: "VIC Murray (Above Choke)_Zone 6",
        tradeZone: "VIC Murray (Above Choke)_Zone 6",
        tradeZoneDetails: "VIC Murray (Above Choke)_Zone 6",
        state: "VIC",
      },

      {
        label: "VIC Goulburn_Zone 1A",
        value: "VIC Goulburn_Zone 1A",
        tradeZone: "VIC Goulburn_Zone 1A",
        tradeZoneDetails: "VIC Goulburn_Zone 1A",
        state: "VIC",
      },
    ],
  },
  {
    label: "SA",
    options: [
      {
        label: "SA River Murray_Zone 12",
        value: "SA River Murray_Zone 12",
        tradeZone: "SA River Murray_Zone 12",
        tradeZoneDetails: "SA River Murray_Zone 12",
        state: "SA",
      },
    ],
  },
];

import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button/standard-button";
import Form, { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import Spinner from "@atlaskit/spinner";
import { getOrderById } from "../../../action/waterOrderAction"; // Make sure this path is correct

const ViewWaterOrderModal = ({ isOpen, onClose, orderId }) => {
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null); // Handle errors

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      try {
        const response = await getOrderById(orderId);
        if (response.ok) {
          const data = await response.json();
          setOrderDetails(data);
        } else {
          setError("Failed to fetch order details.");
        }
      } catch (err) {
        setError("An error occurred while fetching order details.");
      } finally {
        setLoading(false);
      }
    };

    if (orderId && isOpen) {
      fetchOrderDetails();
    }
  }, [orderId, isOpen]);

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <h3 style={{ color: "white" }}>Order Details</h3>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            {loading ? (
              <Spinner size="large" />
            ) : error ? (
              <div style={{ color: "red" }}>{error}</div>
            ) : orderDetails ? (
              <Form>
                {({ formProps }) => (
                  <form {...formProps} id="viewOrderForm">
                    <Field name="orderId" label="Order ID:">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          value={orderDetails._id}
                          isDisabled
                        />
                      )}
                    </Field>
                    <Field name="volumeML" label="Volume (ML):">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          value={orderDetails.volumeML}
                          isDisabled
                        />
                      )}
                    </Field>
                    <Field name="pricePerML" label="Price Per ML:">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          value={orderDetails.pricePerML}
                          isDisabled
                        />
                      )}
                    </Field>
                    <Field name="totalAmount" label="Total Amount:">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          value={`$${orderDetails.totalAmount.toFixed(2)}`}
                          isDisabled
                        />
                      )}
                    </Field>
                    <Field name="status" label="Order Status:">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          value={orderDetails.status}
                          isDisabled
                        />
                      )}
                    </Field>
                    {/* <Field name="buyer" label="Buyer:">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          value={orderDetails.buyerId.name}
                          isDisabled
                        />
                      )}
                    </Field>
                    <Field name="seller" label="Seller:">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          value={orderDetails.sellerId.name}
                          isDisabled
                        />
                      )}
                    </Field> */}
                  </form>
                )}
              </Form>
            ) : (
              <div>No order details available.</div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ViewWaterOrderModal;

import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

// Fetch bids sent by the user
export const getBidsSent = async () => {
  try {
    const response = await fetch(`${API}/bids/sent`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response; // Assuming data is the response with bids
  } catch (error) {
    console.error("Error fetching bids sent:", error);
    return { error };
  }
};

// Fetch bids received by the user
export const getBidsReceived = async () => {
  try {
    const response = await fetch(`${API}/bids/received`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response; // Assuming data is the response with bids
  } catch (error) {
    console.error("Error fetching bids received:", error);
    return { error };
  }
};

export const deleteBid = async (bidId) => {
  try {
    const response = await fetch(`${API}/bid/${bidId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    return response; // Assuming data is the response with bids
  } catch (error) {
    console.error("Error deleting bid:", error);
    return { error };
  }
};

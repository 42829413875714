import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import axios from "axios";
import { API } from "../../config/config";
import Button from "@atlaskit/button/standard-button";

const CalendarHeatmap = () => {
  const svgRef = useRef();
  const [tradeData, setTradeData] = useState([]);
  const [displayedYear, setDisplayedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Fetch trade data from the backend
    const fetchData = async (year) => {
      try {
        const response = await axios.get(
          `${API}/water-history/trade-count/${year}`
        );
        const data = response.data.map((d) => ({
          date: new Date(d.date),
          count: d.count,
        }));

        const allDates = generateAllDatesInRange(
          `${year}-01-01`,
          `${year}-12-31`
        );
        const filledData = allDates.map((date) => {
          const found = data.find(
            (d) => d3.timeFormat("%Y-%m-%d")(d.date) === date
          );
          return found ? found : { date: new Date(date), count: 0 };
        });

        setTradeData(filledData);
      } catch (error) {
        console.error("Error fetching trade data:", error);
      }
    };

    fetchData(displayedYear);
  }, [displayedYear]);

  useEffect(() => {
    if (tradeData.length > 0) {
      renderChart(tradeData);
    }
  }, [tradeData]);

  const generateAllDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      dates.push(d3.timeFormat("%Y-%m-%d")(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const renderChart = (data) => {
    const width = 960;
    const height = 200; // Increased height to fit month labels
    const cellSize = 17;

    const svg = d3
      .select(svgRef.current)
      .attr("width", "100%")
      .attr("height", height)
      .style("font", "10px sans-serif");

    const color = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.count)])
      .range(["#FECC5C", "#BD0026"]); // Vibrant color range from light to bright

    const formatDay = (d) => "SMTWTFS"[d];
    const formatDate = d3.timeFormat("%Y-%m-%d");

    const year = d3.groups(data, (d) => d.date.getUTCFullYear()).reverse();

    const cell = svg
      .selectAll("g")
      .data(year)
      .join("g")
      .attr(
        "transform",
        (d, i) => `translate(40,${height * i + cellSize * 1.5})`
      );

    cell
      .append("text")
      .attr("x", -5)
      .attr("y", -5)
      .attr("text-anchor", "end")
      .attr("font-weight", "bold")
      .attr("fill", "white") // Set text color to white
      .text(([key]) => key);

    cell
      .append("g")
      .attr("text-anchor", "end")
      .selectAll("text")
      .data(d3.range(7))
      .join("text")
      .attr("x", -5)
      .attr("y", (d, i) => (i + 0.5) * cellSize)
      .attr("dy", "0.31em")
      .attr("fill", "white") // Set text color to white
      .text(formatDay);

    cell
      .append("g")
      .selectAll("rect")
      .data((d) => d[1])
      .join("rect")
      .attr("width", cellSize - 2) // Adjust width for spacing
      .attr("height", cellSize - 2) // Adjust height for spacing
      .attr(
        "x",
        (d) => d3.timeWeek.count(d3.utcYear(d.date), d.date) * cellSize + 40
      )
      .attr("y", (d) => d.date.getUTCDay() * cellSize)
      .attr("fill", (d) => (d.count ? color(d.count) : "#1E1C24")) // Set default color to grey if no value
      .attr("stroke", "#101118") // Add dark stroke color between squares
      .attr("stroke-width", 2) // Adjust stroke width for spacing
      .attr("rx", 3) // Add rounded corners
      .attr("ry", 3) // Add rounded corners
      .append("title")
      .text((d) => `${formatDate(d.date)}: ${d.count}`);

    // Add month labels
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    svg
      .selectAll(".month")
      .data(months)
      .enter()
      .append("text")
      .attr("class", "month")
      .attr("x", (d, i) => i * 80 + 50) // Adjust x position as needed
      .attr("y", 10)
      .attr("fill", "white") // Set text color to white
      .attr("font-weight", "bold")
      .text((d) => d);

    // Remove unwanted horizontal lines
    svg.selectAll("line").remove();
  };

  const handleYearChange = (direction) => {
    const currentYear = new Date().getFullYear();
    setDisplayedYear((prevYear) => {
      if (direction === "back") {
        return prevYear - 1;
      } else if (direction === "forward" && prevYear < currentYear) {
        return prevYear + 1;
      }
      return prevYear;
    });
  };

  return (
    <div
      style={{
        //backgroundColor: "#201E26",
        padding: "20px",
        borderRadius: "20px",
      }}
    >
      <span>
        <h2 style={{ color: "white" }}>
          {displayedYear} Water Allocation Trades
        </h2>
      </span>
      <div style={{ overflowX: "auto" }}>
        <div style={{ width: "1000px" }}>
          <svg ref={svgRef}></svg>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          //justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Button appearance="subtle" onClick={() => handleYearChange("back")}>
          ← Back
        </Button>

        <Button appearance="subtle" onClick={() => handleYearChange("forward")}>
          Forward →
        </Button>
      </div>
    </div>
  );
};

export default CalendarHeatmap;

/* global mapboxgl */
import React, { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is installed
import { API } from "../../config/config";

const MapBoxComponent = ({
  styleUrl = "mapbox://styles/mapbox/streets-v11",
  center = [-74.5, 40],
  zoom = 9,
  width = 600,
  height = 400,
  stateName = "New South Wales",
  apiUrl = "",
}) => {
  const [dams, setDams] = useState([]);
  const [map, setMap] = useState(null);

  useEffect(() => {
    // Initialize the map
    mapboxgl.accessToken =
      "pk.eyJ1IjoidHJ1ZWJsdWV3YXRlcmV4Y2hhbmdlIiwiYSI6ImNscjc1eHFwMjJkc2oyaW44Z2J6aTIzNXUifQ.s3B9-VXtgKMg01_eZ61dnw"; // Replace with your Mapbox access token    ;

    const initializedMap = new mapboxgl.Map({
      container: "map", // container ID
      style: styleUrl, // style URL
      center: center, // starting position [lng, lat]
      zoom: zoom, // starting zoom
    });

    setMap(initializedMap);
  }, [styleUrl, center, zoom]);

  useEffect(() => {
    const createCustomMarker = (dam) => {
      const el = document.createElement("div");
      el.className = "custom-marker";
      el.innerHTML = `<span>${dam.dam_name}</span>`;

      new mapboxgl.Marker(el).setLngLat([dam.long, dam.lat]).addTo(map);
    };

    // Fetch dam data and add to map when map is loaded
    if (map) {
      map.on("load", () => {
        axios
          .get(`${API}${apiUrl}`)
          .then((response) => {
            const damsGeoJSON = {
              type: "FeatureCollection",
              features: response.data.dams.map((dam) => ({
                type: "Feature",
                properties: {
                  damName: dam.dam_name,
                  damNameFullVolume: `${dam.dam_name} - Max: ${dam.full_volume}ML`,
                  fullVolume: dam.full_volume,
                },
                geometry: {
                  type: "Point",
                  coordinates: [dam.long, dam.lat],
                },
              })),
            };

            map.addSource("dams", {
              type: "geojson",
              data: damsGeoJSON,
              cluster: true,
              clusterMaxZoom: 5,
              clusterRadius: 25,
            });

            map.addLayer({
              id: "clusters",
              type: "circle",
              source: "dams",
              filter: ["has", "point_count"],
              paint: {
                "circle-color": [
                  "step",
                  ["get", "point_count"],
                  "#51bbd6",
                  100,
                  "#f1f075",
                  750,
                  "#f28cb1",
                ],
                "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  100,
                  30,
                  750,
                  40,
                ],
              },
            });

            map.addLayer({
              id: "cluster-count",
              type: "symbol",
              source: "dams",
              filter: ["has", "point_count"],
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-size": 12,
              },
            });

            map.addLayer({
              id: "unclustered-point-labels",
              type: "symbol",
              source: "dams",
              filter: ["!", ["has", "point_count"]],
              layout: {
                "text-field": ["get", "damNameFullVolume"],
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-size": 12,
                "text-offset": [0, 1.5], // Offset text to make it appear above the marker
                "text-anchor": "top",
              },
              paint: {
                "text-color": "white",
                "text-halo-color": "black",
                "text-halo-width": 3,
              },
            });

            map.on("click", "clusters", (e) => {
              const features = map.queryRenderedFeatures(e.point, {
                layers: ["clusters"],
              });
              const clusterId = features[0].properties.cluster_id;
              map
                .getSource("dams")
                .getClusterExpansionZoom(clusterId, (err, zoom) => {
                  if (err) return;

                  map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                  });
                });
            });
          })
          .catch((error) => console.error("Error fetching dam data:", error));
      });
    }
  }, [map, apiUrl]);

  return <div id="map"></div>;
};

export default MapBoxComponent;

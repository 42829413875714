import React, { useEffect, useState, Fragment, useRef } from "react";
import Button from "@atlaskit/button/standard-button";
import DynamicTable from "@atlaskit/dynamic-table";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Badge from "@atlaskit/badge";
import { useTheme } from "../../../theme/ThemeContext";
import {
  getWaterListingById,
  getBidHistory,
  rejectBid,
  acceptBid,
} from "../../../action/waterListingAction"; // Import the action
import { getBrokerageFeeWaterTrade } from "../../../action/userAction";
import { makeStyles } from "@material-ui/core/styles";
import AcceptBuyListing from "./acceptBuyListing";
import EditListing from "./editListing";
import PlaceBid from "./placeBid";
import moment from "moment";
import { postListingAlert } from "../../../action/listingAlertAction";
import NotificationAllIcon from "@atlaskit/icon/glyph/notification-all";
import { buyWaterListing } from "../../../action/waterListingAction";
import { Stepper, Step, StepLabel } from "@mui/material";

const useStyles = makeStyles(() => ({
  modalDarkTextColor1: {
    color: "white",
  },
  modalLightTextColor1: {
    color: "black",
  },
  locationBox: {
    padding: "15px",
    borderRadius: "15px",
    background: "black",
    color: "white",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  col1: {
    width: "75%",
    fontWeight: 700,
  },
  col2: {
    width: "25%",
  },
}));

const ViewListing = ({ isOpen, onClose, listingId, waterListing }) => {
  const [listingDetails, setListingDetails] = useState(null);
  const [brokerageFeePercentage, setBrokerageFeePercentage] = useState(null);
  const [brokerageFeeAmount, setBrokerageFeeAmount] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPlaceBidOpen, setIsPlaceBidOpen] = useState(false);
  const [isLoadingBidHistory, setIsLoadingBidHistory] = useState(true); // Loading state for bid history
  const [recentBidHistory, setRecentBidHistory] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isProcessingTransaction, setIsProcessingTransaction] = useState(false);

  const { theme } = useTheme();
  const classes = useStyles();

  const bidHistoryHead = {
    cells: [
      { key: "pricePerML", content: "Price Per ML" },
      { key: "date", content: "Date" },
      { key: "status", content: "Status" },
      { key: "actions", content: "" },
    ],
  };

  const steps = ["View Listing", "Confirm Purchase", "Completed"];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Function to handle the Buy button click
  const handleBuyClick = async (listingId) => {
    if (listingId) {
      setIsProcessingTransaction(true); // Show processing message

      try {
        const data = await buyWaterListing({ waterListingId: listingId });
        if (data.error) {
          throw new Error(data.error);
        }
        toast.success(
          "Success! You will receive an invoice from a TBWE team member soon."
        );
        setIsProcessingTransaction(false); // Stop processing
        setListingDetails((prevDetails) => ({
          ...prevDetails,
          status: "Closed", // Update the status to Closed
        }));
        setActiveStep(2); // Move to completion step
      } catch (error) {
        setIsProcessingTransaction(false); // Stop processing on error
        console.error("Error fetching listing details:", error);
        toast.error(error.message);
      }
    }
  };

  const handlePlaceBidClick = () => {
    setIsPlaceBidOpen(true);
  };

  // Function to execute when user confirms the purchase
  const handleConfirmPurchase = () => {
    // Implement the logic for confirming purchase here
    console.log("Purchase confirmed");
    setIsConfirmModalOpen(false);
    onClose(); // Close the main modal if needed
  };

  const handleEditListing = () => {
    setIsEditModalOpen(false);
  };

  const handlePlaceBid = () => {
    setIsPlaceBidOpen(false);
    onClose(); // Closes the main model
    // Set toast notification
    //handle if user doesnt have access due to licenses restriction
  };

  const fetchBidHistory = async () => {
    let waterListingId = listingId ? listingId : waterListing._id;
    try {
      const data = await getBidHistory(waterListingId); // Adjust parameters as needed
      if (data.error) {
        throw new Error(data.error);
      }
      console.log("fetchBidHistory: ", data);
      setRecentBidHistory(data); // Assuming data is the array of water listings
      setIsLoadingBidHistory(false);
    } catch (error) {
      console.error("Error fetching bid history:", error);
      toast.error("Failed to fetch bid history");
      setIsLoadingBidHistory(false);
    }
  };

  const createAcceptBidButton = (bid) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user &&
      user._id &&
      listingDetails.ownerId.toString() === user._id &&
      listingDetails.status === "Open" &&
      bid.status !== "Rejected"
    ) {
      return (
        <div style={{ float: "right" }}>
          <Button onClick={() => handleAcceptBid(bid)}>Accept</Button>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const createRejectBidButton = (bid) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user &&
      user._id &&
      listingDetails.ownerId.toString() === user._id &&
      listingDetails.status === "Open" &&
      bid.status !== "Rejected"
    ) {
      return (
        <div style={{ float: "right", marginRight: "10px" }}>
          <Button onClick={() => handleRejectBid(bid)}>Reject</Button>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const createPlaceABidButton = (bid) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user &&
      user._id &&
      listingDetails.ownerId.toString() !== user._id &&
      listingDetails.status === "Open"
    ) {
      return (
        <Button appearance="subtle" onClick={handlePlaceBidClick}>
          Place a bid
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const createBuyButton = (listingId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user &&
      user._id &&
      listingDetails.ownerId.toString() !== user._id &&
      listingDetails.status === "Open"
    ) {
      return (
        <Button
          appearance="primary"
          onClick={() => handleBuyClick(listingId)}
          isDisabled={isProcessingTransaction} // Disable if processing
        >
          Match
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const createConfirmPurchaseBtn = (listingId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user &&
      user._id &&
      listingDetails.ownerId.toString() !== user._id &&
      listingDetails.status === "Open"
    ) {
      return (
        <Button appearance="primary" onClick={() => setActiveStep(1)}>
          Match Order
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const createEditButton = (listingId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user &&
      user._id &&
      listingDetails.ownerId.toString() === user._id &&
      listingDetails.status === "Open"
    ) {
      return (
        <Button onClick={() => setIsEditModalOpen(true)} appearance="primary">
          Edit
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const renderBidHistory = () => {
    // Check if recentBidHistory exists and is not empty
    console.log("recentBidHistory renderBidHistory", recentBidHistory);

    if (recentBidHistory && recentBidHistory.length > 0) {
      return recentBidHistory.map((bid) => ({
        key: bid._id,
        cells: [
          { key: "pricePerML", content: `$${bid.pricePerML.toFixed(2)}` },
          { key: "date", content: `${moment(bid.createdAt).fromNow()}` },
          { key: "status", content: `${bid.status}` },
          {
            key: "action",
            content: (
              <span>
                {createAcceptBidButton(bid)} {createRejectBidButton(bid)}
              </span>
            ),
          },
        ],
      }));
    } else {
      // Return a single row with "No bids found" message if recentBidHistory is null, undefined, or empty
      return [
        {
          key: "no bids",
          cells: [{ key: "pricePerML", content: "No bids found." }],
        },
      ];
    }
  };

  const fetchListingDetails = async () => {
    if (listingId) {
      console.log("listingId: ", listingId);
      try {
        const data = await getWaterListingById(listingId);
        if (data.error) {
          throw new Error(data.error);
        }
        setListingDetails(data); // Assuming the response contains the listing details
        if (
          data.status === "Completed" ||
          data.status === "Accepted" ||
          data.status === "Closed"
        ) {
          setActiveStep(2);
        }
      } catch (error) {
        console.error("Error fetching listing details:", error);
        toast.error("Failed to fetch listing details");
      }
    }
  };

  const handleRejectBid = async (bid) => {
    try {
      const postData = {
        bidId: bid._id,
      };

      const data = await rejectBid(postData);
      if (data.error) {
        throw new Error(data.error);
      } else {
        toast.success("Bid rejected successfully.");

        //onClose(); // Close the modal after submission
      }
    } catch (error) {
      console.error("Error creating listing alert:", error);
      toast.error(`Bid rejection error: ${error}`);
    }
  };

  const handleAcceptBid = async (bid) => {
    try {
      const postData = {
        bidId: bid._id,
      };

      const data = await acceptBid(postData);
      if (data.error) {
        throw new Error(data.error);
      } else {
        toast.success("Bid accepted successfully.");
        setActiveStep(2);
        setListingDetails((prevDetails) => ({
          ...prevDetails,
          status: "Closed", // Update the status to Closed
        }));
        onClose(); // Close the modal after submission
      }
    } catch (error) {
      console.error("Error creating listing alert:", error);
      toast.error(`Error: ${error}`);
    }
  };

  const handleCreateListingAlert = async (listingId, description) => {
    try {
      const postData = {
        waterListingId: listingId,
        description: description,
      };

      const data = await postListingAlert(postData);
      if (data.error) {
        throw new Error(data.error);
      } else {
        toast.success("Listing alert created successfully");

        //onClose(); // Close the modal after submission
      }
    } catch (error) {
      console.error("Error creating listing alert:", error);
      toast.error(`Error: ${error}`);
    }
  };

  const fetchBrokerageFee = async () => {
    try {
      let waterListingId = null;
      if (listingId) {
        waterListingId = listingId;
      } else if (waterListing) {
        waterListingId = waterListing._id;
      }

      const response = await getBrokerageFeeWaterTrade(waterListingId);
      if (response.ok) {
        const data = await response.json();
        console.log("fetchBrokerageFee: ", data);
        setBrokerageFeePercentage(
          data.brokerageFeePercentageIncReferralDiscount
        );
        setBrokerageFeeAmount(data.brokerageFeeAmountIncReferralDiscount);
      } else {
        setBrokerageFeePercentage(3);
        setBrokerageFeeAmount("N/A");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isOpen) {
      fetchBidHistory();
      fetchBrokerageFee();
      if (!waterListing && listingId) {
        fetchListingDetails();
      } else if (waterListing) {
        setListingDetails(waterListing);
      }
    }
  }, [isOpen, listingId, waterListing, listingDetails?.status]);

  return (
    <div>
      {/* AcceptBuyListing modal */}
      <AcceptBuyListing
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        doAction={handleConfirmPurchase}
        waterListing={listingDetails}
      />

      <EditListing
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSuccess={handleEditListing}
        waterListing={listingDetails}
      />

      <PlaceBid
        isOpen={isPlaceBidOpen}
        onClose={() => setIsPlaceBidOpen(false)}
        onSuccess={handlePlaceBid}
        waterListing={listingDetails}
        brokerageFeePercentage={brokerageFeePercentage}
      />
      <ModalTransition>
        {isOpen && (
          <Modal autoFocus={false} onClose={onClose} width="large">
            {listingDetails ? (
              <>
                <div style={{ padding: "20px" }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={index}>
                        <StepLabel>
                          <span style={{ color: "white" }}>{label}</span>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <ModalHeader>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <h3
                      className={
                        theme === "dark"
                          ? classes.modalDarkTextColor1
                          : classes.modalLightTextColor1
                      }
                      style={{ fontWeight: 700 }}
                    >
                      {" "}
                      {listingDetails.listingType}
                      <span style={{ padding: "10px" }}>
                        <Badge
                          appearance={
                            listingDetails.status === "Open"
                              ? "primary"
                              : "added"
                          }
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              padding: "4px",
                              fontWeight: 500,
                            }}
                          >
                            {listingDetails.status}
                          </span>
                        </Badge>
                      </span>
                    </h3>
                  </div>
                </ModalHeader>
                {(activeStep === 0 || activeStep === 2) && (
                  <>
                    <ModalBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 700,
                        }}
                      >
                        {listingDetails.tradeZone}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          <Button
                            style={{ paddingTop: "5px" }}
                            onClick={() =>
                              handleCreateListingAlert(
                                listingDetails._id,
                                `${listingDetails.tradeZone} - ${listingDetails.listingType}`
                              )
                            }
                          >
                            <span style={{ marginTop: "10px" }}>
                              <NotificationAllIcon label="Listing alert" />
                            </span>
                          </Button>
                        </div>
                      </div>

                      {listingDetails?.ownerDetails?.name && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <br />

                          <h3
                            className={
                              theme === "dark"
                                ? classes.modalDarkTextColor1
                                : classes.modalLightTextColor1
                            }
                            style={{ fontWeight: 700 }}
                          >
                            {" "}
                            <span style={{ padding: "10px" }}>
                              <Badge>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    padding: "4px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Listing Owner:{" "}
                                  {listingDetails?.ownerDetails?.name}
                                </span>
                              </Badge>
                            </span>
                          </h3>
                        </div>
                      )}

                      <div style={{ padding: "30px" }}>
                        <DynamicTable
                          head={{
                            cells: [
                              { key: "field", content: "Field" },
                              { key: "value", content: "Value" },
                            ],
                          }}
                          rows={[
                            {
                              key: "allocationType",
                              cells: [
                                {
                                  key: "field",
                                  content: <strong>Market Type</strong>,
                                },
                                {
                                  key: "value",
                                  content:
                                    listingDetails.allocationType || "N/A",
                                },
                              ],
                            },
                            {
                              key: "season",
                              cells: [
                                {
                                  key: "field",
                                  content: <strong>Season</strong>,
                                },
                                {
                                  key: "value",
                                  content: listingDetails.season || "N/A",
                                },
                              ],
                            },
                            {
                              key: "deliveryDate",
                              cells: [
                                {
                                  key: "field",
                                  content: <strong>Delivery Date</strong>,
                                },
                                {
                                  key: "value",
                                  content: listingDetails.deliveryDate
                                    ? new Date(
                                        listingDetails.deliveryDate
                                      ).toLocaleDateString()
                                    : "N/A",
                                },
                              ],
                            },
                            {
                              key: "volumeML",
                              cells: [
                                {
                                  key: "field",
                                  content: <strong>Volume (ML)</strong>,
                                },
                                {
                                  key: "value",
                                  content: `${listingDetails.volumeML} ML`,
                                },
                              ],
                            },
                            {
                              key: "pricePerML",
                              cells: [
                                {
                                  key: "field",
                                  content: <strong>Price Per ML</strong>,
                                },
                                {
                                  key: "value",
                                  content: `$${listingDetails.pricePerML.toFixed(
                                    2
                                  )}`,
                                },
                              ],
                            },
                            ...(listingDetails.allocationType ===
                            "Lease of Entitlement"
                              ? [
                                  {
                                    key: "leaseOfEntitlementAmountOfYears",
                                    cells: [
                                      {
                                        key: "field",
                                        content: (
                                          <strong>
                                            Lease Duration (Years)
                                          </strong>
                                        ),
                                      },
                                      {
                                        key: "value",
                                        content:
                                          listingDetails.leaseOfEntitlementAmountOfYears ||
                                          "N/A",
                                      },
                                    ],
                                  },
                                  {
                                    key: "leaseOfEntitlementStartDate",
                                    cells: [
                                      {
                                        key: "field",
                                        content: (
                                          <strong>Lease Start Date</strong>
                                        ),
                                      },
                                      {
                                        key: "value",
                                        content: new Date(
                                          listingDetails.leaseOfEntitlementStartDate
                                        ).toLocaleDateString(),
                                      },
                                    ],
                                  },
                                ]
                              : []),
                            ...(brokerageFeePercentage === null
                              ? [
                                  {
                                    key: "brokerageFee",
                                    cells: [
                                      {
                                        key: "field",
                                        content: <strong>Brokerage Fee</strong>,
                                      },
                                      { key: "value", content: "N/A" },
                                    ],
                                  },
                                  {
                                    key: "total",
                                    cells: [
                                      {
                                        key: "field",
                                        content: <strong>Total</strong>,
                                      },
                                      { key: "value", content: "N/A" },
                                    ],
                                  },
                                ]
                              : [
                                  {
                                    key: "brokerageFee",
                                    cells: [
                                      {
                                        key: "field",
                                        content: (
                                          <strong>
                                            Brokerage Fee (
                                            {brokerageFeePercentage}%)
                                          </strong>
                                        ),
                                      },
                                      {
                                        key: "value",
                                        content: `$${brokerageFeeAmount}`,
                                      },
                                    ],
                                  },
                                  {
                                    key: "subTotal",
                                    cells: [
                                      {
                                        key: "field",
                                        content: <strong>Sub Total</strong>,
                                      },
                                      {
                                        key: "value",
                                        content: `$${(
                                          listingDetails.pricePerML *
                                          listingDetails.volumeML
                                        ).toFixed(2)}`,
                                      },
                                    ],
                                  },
                                  {
                                    key: "gst",
                                    cells: [
                                      {
                                        key: "field",
                                        content: <strong>GST (10%)</strong>,
                                      },
                                      {
                                        key: "value",
                                        content: `$${(
                                          brokerageFeeAmount * 0.1
                                        ).toFixed(2)}`,
                                      },
                                    ],
                                  },
                                  {
                                    key: "total",
                                    cells: [
                                      {
                                        key: "field",
                                        content: <strong>Total</strong>,
                                      },
                                      {
                                        key: "value",
                                        content: `$${(
                                          listingDetails.pricePerML *
                                            listingDetails.volumeML +
                                          brokerageFeeAmount * 1.1
                                        ).toFixed(2)}`,
                                      },
                                    ],
                                  },
                                ]),
                          ]}
                        />
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              opacity: 0.7,
                            }}
                          >
                            * Excludes water authority transfer fees
                          </div>
                        </div>

                        <br />
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h5>Bid History</h5>
                          </div>
                          <div style={{ justifyContent: "center" }}>
                            {isLoadingBidHistory ? (
                              <p>Loading bid history...</p>
                            ) : (
                              <DynamicTable
                                head={bidHistoryHead}
                                rows={renderBidHistory()}
                                defaultPage={1}
                                loadingSpinnerSize="large"
                                isLoading={isLoadingBidHistory}
                                isFixedSize
                              />
                            )}
                            {/* <DynamicTable
                          head={bidHistoryHead}
                          rows={renderBidHistory()}
                          defaultPage={1}
                          loadingSpinnerSize="large"
                          isLoading={false}
                        /> */}
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      {listingDetails && (
                        <span style={{ marginRight: "80px" }}>
                          {" "}
                          {listingDetails._id.toString()}
                        </span>
                      )}
                      <Button
                        id="close-modal"
                        appearance="subtle"
                        onClick={onClose}
                      >
                        Close
                      </Button>
                      {listingDetails && listingDetails.status !== "Closed" && (
                        <>
                          {createPlaceABidButton()}
                          {createConfirmPurchaseBtn(
                            listingDetails._id.toString()
                          )}

                          {createEditButton(listingDetails._id.toString())}
                        </>
                      )}
                    </ModalFooter>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px",
                      }}
                    >
                      <h5>
                        Are you sure you would like to match this water listing?
                      </h5>
                    </div>
                    {isProcessingTransaction && (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          color: "red",
                        }}
                      >
                        Please wait as we process your order...
                      </div>
                    )}
                    <ModalFooter>
                      {listingDetails && (
                        <span style={{ marginRight: "80px" }}>
                          {" "}
                          {listingDetails._id.toString()}
                        </span>
                      )}

                      <Button onClick={handleBack}>Back</Button>
                      {createBuyButton(listingDetails._id.toString())}
                    </ModalFooter>
                  </>
                )}
              </>
            ) : (
              <p>Loading listing details...</p>
            )}
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default ViewListing;

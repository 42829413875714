// src/actions/infoTileAction.js
import { API } from "../config/config";
import cookie from "js-cookie";

export const fetchData = async (dataType, market, zone) => {
  switch (dataType) {
    case "Sell Orders":
      return await fetchSellOrders(market, zone);
    case "Buy Orders":
      return await fetchBuyOrders(market, zone);
    case "Average Price Per ML":
      return await fetchAveragePricePerML(market, zone);
    case "Average Volume Per Trade":
      return await fetchAverageVolumePerTrade(market, zone);
    case "User Count":
      return await fetchUserCount();
    case "Trade Volume":
      return fetchTradeVolume(market, zone);
    case "Trade Count":
      return fetchTradeCount(market, zone);
    default:
      return null;
  }
};

const fetchSellOrders = async (market, zone) => {
  try {
    const response = await fetch(
      `${API}/water-listings/open/sell-count?market=${market}&zone=${zone}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch sell orders");
    }
    const data = await response.json();
    return data.count;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchBuyOrders = async (market, zone) => {
  try {
    const response = await fetch(
      `${API}/water-listings/open/buy-count?market=${market}&zone=${zone}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch buy orders");
    }
    const data = await response.json();
    return data.count;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchAveragePricePerML = async (market, zone) => {
  try {
    const response = await fetch(
      `${API}/water-listings/analytics/average-price-per-ml?market=${market}&zone=${zone}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch average price per ML");
    }
    const data = await response.json();
    let averagePrice = `$${data.averagePriceML.toFixed(2)}`;
    return averagePrice;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchAverageVolumePerTrade = async (market, zone) => {
  try {
    const response = await fetch(
      `${API}/water-listings/analytics/average-volume-per-trade?market=${market}&zone=${zone}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch average volume per trade");
    }
    const data = await response.json();
    return `${data.average}ML`;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchUserCount = async () => {
  try {
    const response = await fetch(`${API}/users/analytics`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    const data = await response.json();
    return data.userCount;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchTradeVolume = async (market, zone) => {
  try {
    const response = await fetch(
      `${API}/water-orders/analytics/trade-volume?market=${market}&zone=${zone}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch trade volume");
    }
    const data = await response.json();
    return `$${data.totalAmount.toFixed(2)}`;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchTradeCount = async (market, zone) => {
  try {
    const response = await fetch(
      `${API}/water-orders/analytics/trade-count?market=${market}&zone=${zone}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch trade volume");
    }
    const data = await response.json();
    return `${data.tradeCount}`;
  } catch (error) {
    console.error(error);
    return null;
  }
};

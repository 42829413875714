import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getRecentFinancialData } from "../../action/waterTradeAction";

const useStyles = makeStyles(() => ({
  halfGridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px",
    margin: "10px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  dataSectionTitle: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
    fontWeight: "600",
    margin: "0px",
    border: "5px solid #0F1118",
  },
  subTitle: {
    fontWeight: "700",
    opacity: "0.7",
    padding: "10px",
    fontSize: "18px",
  },
  sectionBorder: {
    padding: "10px",
  },
}));

const WaterTradeInfoSection = ({ title, market, zone }) => {
  const classes = useStyles();
  const [tradeInfo, setTradeInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTradeInfo = async () => {
      setLoading(true);
      try {
        const data = await getRecentFinancialData({ market, zone });

        // Ensure data exists and has no error
        if (data && !data.error) {
          console.log("WaterTradeInfoSection: ", data);
          setTradeInfo(data); // This should be the most recent trade
        } else {
          console.error("Failed to fetch trade data:", data?.error);
        }
      } catch (error) {
        console.error("An error occurred while fetching trade data:", error);
      }
      setLoading(false);
    };

    fetchTradeInfo();
  }, [market, zone]);

  return (
    <div>
      <div>
        {title && <h5 className={classes.dataSectionTitle}>{title}</h5>}
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={classes.subTitle}>{zone || market}</div>
        </div>
        <div className={classes.halfGridContainer}>
          <div className={classes.sectionBorder}>
            <div>OPEN</div>
            <div>PREV CLOSE</div>
            <div>LAST PRICE</div>
            <hr />
            <div>TODAY'S CHANGE</div>
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className={classes.sectionBorder}>
              <div>${tradeInfo?.open || "N/A"}</div>
              <div>${tradeInfo?.close || "N/A"}</div>
              <div>${tradeInfo?.lastPrice || "N/A"}</div>
              <hr />
              <div>
                $
                {tradeInfo
                  ? (tradeInfo.lastPrice - tradeInfo.close).toFixed(2)
                  : "N/A"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaterTradeInfoSection;

import React, { useState, useEffect } from "react";
import Select from "@atlaskit/select";
import { markets } from "../../component/regions/markets";
import { carryOverParkingRegions } from "../../component/regions/carryOverParkingRegions";
import { forwardAllocationRegions } from "../../component/regions/forwardAllocationRegions";
import { leaseOfEntitlementRegions } from "../../component/regions/leaseOfEntitlementRegions";
import { permanentEntitlementRegions } from "../../component/regions/permanentEntitlementRegions";
import { temporaryAllocationRegionsConsolidated } from "../../component/regions/temporaryAllocationRegionsConsolidated";
import Button from "@atlaskit/button/new";

const WaterListingSearch = ({
  onZoneChange,
  onMarketChange,
  selectedMarket,
  selectedZone,
}) => {
  const [market, setMarket] = useState(null); // local market state
  const [zoneOptions, setZoneOptions] = useState([]); // zone options state
  const [zone, setZone] = useState(null); // local zone state

  // This function updates the available zones based on the selected market
  const updateZoneOptions = (marketValue) => {
    switch (marketValue) {
      case "Temporary Allocation":
        setZoneOptions(temporaryAllocationRegionsConsolidated);
        break;
      case "Forward Allocation":
        setZoneOptions(forwardAllocationRegions);
        break;
      case "Carry Over Parking":
        setZoneOptions(carryOverParkingRegions);
        break;
      case "Lease of Entitlement":
        setZoneOptions(leaseOfEntitlementRegions);
        break;
      case "Permanent Entitlement":
        setZoneOptions(permanentEntitlementRegions);
        break;
      default:
        setZoneOptions([]);
    }
  };

  // Function to find the market option that matches the selected market value
  const findMarketOption = (marketValue) => {
    // Flatten the nested market options
    const flattenedMarkets = markets.flatMap((group) => group.options);
    return flattenedMarkets.find(
      (marketOption) => marketOption.value === marketValue
    );
  };

  // Sync the local market and zone with the selectedMarket and selectedZone props
  useEffect(() => {
    if (selectedMarket) {
      const marketOption = findMarketOption(selectedMarket);
      if (marketOption) {
        setMarket(marketOption); // Set market to the matching option
        updateZoneOptions(selectedMarket); // Update zone options based on market
      }
    }
    if (selectedZone) {
      setZone({ label: selectedZone, value: selectedZone });
    }
  }, [selectedMarket, selectedZone]);

  const handleMarketChange = (selectedOption) => {
    setMarket(selectedOption);
    updateZoneOptions(selectedOption?.value);
    setZone(null); // Reset the selected zone when the market changes
    if (onMarketChange) {
      onMarketChange(selectedOption?.value);
    }
  };

  const handleZoneChange = (selectedOption) => {
    setZone(selectedOption);
    if (onZoneChange) {
      onZoneChange(selectedOption?.value);
    }
  };

  const handleClear = () => {
    setMarket(null); // Reset market selection
    setZoneOptions([]); // Clear zone options
    setZone(null); // Clear zone selection
    if (onMarketChange) {
      onMarketChange(null);
    }
    if (onZoneChange) {
      onZoneChange(null);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap", // Ensure wrapping on small screens
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          gap: "20px", // Spacing between items
        }}
      >
        <div style={{ flex: "1 1 180px", minWidth: "180px" }}>
          <Select
            inputId="single-select-market"
            className="single-select"
            classNamePrefix="react-select"
            options={markets}
            placeholder="Select Your Market"
            onChange={handleMarketChange}
            value={market} // Bind selected market to the local state
          />
        </div>
        <div style={{ flex: "1 1 180px", minWidth: "180px" }}>
          <Select
            inputId="single-select-zone"
            className="single-select"
            classNamePrefix="react-select"
            options={zoneOptions}
            placeholder="Select Zone"
            isDisabled={!market} // Disable if no market selected
            onChange={handleZoneChange}
            value={zone} // Bind selected zone to the local state
          />
        </div>
        <div style={{ flex: "1 1 100px", minWidth: "100px" }}>
          <Button onClick={handleClear}>Clear</Button>
        </div>
      </div>
    </div>
  );
};

export default WaterListingSearch;

import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Stepper, Step, StepLabel } from "@mui/material";
import DynamicTable from "@atlaskit/dynamic-table";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@atlaskit/button/standard-button";
import { getWaterTradeById } from "../../../action/waterTradeAction";

const useStyles = makeStyles(() => ({
  modalDarkTextColor1: {
    color: "white",
  },
  modalLightTextColor1: {
    color: "black",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  col1: {
    width: "75%",
    fontWeight: 700,
  },
  col2: {
    width: "25%",
  },
}));

const steps = ["View Trade", "Confirm Details", "Completed"];

const ViewRegistryTrade = ({ isOpen, onClose, tradeId }) => {
  const [tradeDetails, setTradeDetails] = useState(null);
  const [activeStep, setActiveStep] = useState(2);
  const classes = useStyles();

  // Simulating a function to fetch trade details
  useEffect(() => {
    if (isOpen && tradeId) {
      // Simulated API call to fetch trade details by tradeId
      const fetchTradeDetails = async () => {
        console.log("VIEW REGISTRY TRADE: Fetching trade details...", tradeId);
        const fetchedTradeDetails = await getWaterTradeById(tradeId);
        if (fetchedTradeDetails.ok) {
          setTradeDetails(await fetchedTradeDetails.json());
        }
      };
      fetchTradeDetails();
    }
  }, [isOpen, tradeId]);

  // Dynamic Table to display trade details
  const tradeDetailsTable = tradeDetails && [
    {
      key: "waterTradeId",
      cells: [
        { key: "field", content: <strong>Trade ID</strong> },
        { key: "value", content: tradeDetails.waterTradeId },
      ],
    },
    {
      key: "tradeType",
      cells: [
        { key: "field", content: <strong>Trade Type</strong> },
        { key: "value", content: tradeDetails.tradeType || "N/A" },
      ],
    },
    {
      key: "zoneFrom",
      cells: [
        { key: "field", content: <strong>Zone From</strong> },
        { key: "value", content: tradeDetails.zoneFrom },
      ],
    },
    {
      key: "zoneTo",
      cells: [
        { key: "field", content: <strong>Zone To</strong> },
        { key: "value", content: tradeDetails.zoneTo },
      ],
    },
    {
      key: "volumeML",
      cells: [
        { key: "field", content: <strong>Volume (ML)</strong> },
        { key: "value", content: tradeDetails.volumeML },
      ],
    },
    {
      key: "pricePerML",
      cells: [
        { key: "field", content: <strong>Price Per ML</strong> },
        { key: "value", content: `$${tradeDetails?.pricePerML.toFixed(2)}` },
      ],
    },
    {
      key: "totalPrice",
      cells: [
        { key: "field", content: <strong>Total Price</strong> },
        { key: "value", content: `$${tradeDetails.totalPrice.toFixed(2)}` },
      ],
    },
    {
      key: "tradeApprovalTimestamp",
      cells: [
        { key: "field", content: <strong>Approval Date</strong> },
        {
          key: "value",
          content: new Date(
            tradeDetails.tradeApprovalTimestamp
          ).toLocaleString(),
        },
      ],
    },
  ];

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="large">
          <div style={{ padding: "20px" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>
                    {" "}
                    <span style={{ color: "white" }}>{label}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          <ModalHeader>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <h3 style={{ fontWeight: 700 }}>Registry Trade Details</h3>
            </div>
          </ModalHeader>

          <ModalBody>
            {tradeDetails ? (
              <div style={{ padding: "30px" }}>
                <DynamicTable
                  head={{
                    cells: [
                      { key: "field", content: "Field" },
                      { key: "value", content: "Value" },
                    ],
                  }}
                  rows={tradeDetailsTable}
                />
              </div>
            ) : (
              <p>Loading trade details...</p>
            )}
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ViewRegistryTrade;

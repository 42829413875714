export const markets = [
  {
    options: [
      {
        label: "Temporary Allocation",
        value: "Temporary Allocation",
      },
      {
        label: "Forward Allocation",
        value: "Forward Allocation",
      },
      {
        label: "Carry Over Parking",
        value: "Carry Over Parking",
      },
      {
        label: "Lease of Entitlement",
        value: "Lease of Entitlement",
      },
      {
        label: "Permanent Entitlement",
        value: "Permanent Entitlement",
      },
    ],
  },
];

import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button";
import DynamicTable from "@atlaskit/dynamic-table";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import EmptyState from "../../component/misc/EmptyState";
import CreateReferralModal from "../../component/modals/referral/createReferralModal";
import ViewQRCodeModal from "../../component/modals/referral/viewQRCodeModal";
import UserInfoModal from "../../component/modals/user/userInfoModal";
import {
  getReferrals,
  updateReferralStatus,
} from "../../action/referralAction";
import {
  getReferralPayments,
  updateReferralPaymentStatus,
} from "../../action/referralPaymentAction";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faRotate, faUser } from "@fortawesome/free-solid-svg-icons";
import ViewListingModal from "../../component/modals/listing/viewListing"; // Import the modal
import { toast } from "react-toastify";

const ReferralPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [referralPayments, setReferralPayments] = useState([]);
  const [isCreateReferralModalOpen, setCreateReferralModalOpen] =
    useState(false);
  const [isViewQRCodeModalOpen, setViewQRCodeModalOpen] = useState(false);
  const [isUserInfoModalOpen, setUserInfoModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [selectedListingId, setSelectedListingId] = useState(null); // New state for selected listing

  const [isViewListingModalOpen, setViewListingModalOpen] = useState(false); // New state for View Listing Modal

  const fetchReferrals = async () => {
    try {
      const response = await getReferrals();
      setReferrals(response);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  const fetchReferralPayments = async () => {
    try {
      const response = await getReferralPayments();
      setReferralPayments(response);
    } catch (error) {
      console.error("Error fetching referral payments:", error);
    }
  };

  // Handle the status change
  const handleChangeStatus = async (paymentId, status) => {
    try {
      const response = await updateReferralPaymentStatus(paymentId, status);
      if (response) {
        // Update the local state with the new status
        setReferralPayments((prevPayments) =>
          prevPayments.map((payment) =>
            payment._id === paymentId ? { ...payment, status } : payment
          )
        );
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleChangeReferralStatus = async (referrerId, status) => {
    try {
      const newStatus = status === "Active" ? "Inactive" : "Active";
      const response = await updateReferralStatus(referrerId, newStatus);

      if (response) {
        fetchReferrals();
        toast.success("Referral status updated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update referral status");
    }
  };

  useEffect(() => {
    fetchReferrals();
    fetchReferralPayments();
  }, []);

  // Open View QR Code modal with the selected referral
  const handleViewQRCode = (referral) => {
    setSelectedReferral(referral);
    setViewQRCodeModalOpen(true);
  };

  // Open View Listing modal with selected listing
  const handleViewListing = (listing) => {
    setSelectedListingId(listing.waterListingId); // Store selected listing
    setViewListingModalOpen(true); // Open listing modal
  };

  // Open User Info Modal with selected user
  const handleViewUserInfo = (userId) => {
    setSelectedUserId(userId);
    setUserInfoModalOpen(true);
  };

  // Rendering Referrals Table
  const renderReferralsTable = () => {
    const tableHead = {
      cells: [
        { key: "referrer", content: "Referrer" },
        { key: "referralCode", content: "Referral Code" },
        { key: "status", content: "Status" },
        { key: "actions", content: "Actions" },
      ],
    };

    const rows = referrals.map((referral) => ({
      key: referral._id,
      cells: [
        { key: "referrer", content: referral.referrerId?.name || "Unknown" },
        { key: "referralCode", content: referral.referralCode },
        { key: "status", content: referral.status },
        {
          key: "actions",
          content: (
            <DropdownMenu
              trigger={({ triggerRef, ...props }) => (
                <Button {...props} ref={triggerRef}>
                  ...
                </Button>
              )}
            >
              <DropdownItemGroup>
                <DropdownItem
                  iconBefore={<FontAwesomeIcon icon={faQrcode} />}
                  onClick={() => handleViewQRCode(referral)}
                >
                  View QR
                </DropdownItem>
                <DropdownItem
                  iconBefore={<FontAwesomeIcon icon={faUser} />}
                  onClick={() => handleViewUserInfo(referral.referrerId?._id)}
                >
                  View User Info
                </DropdownItem>
                <DropdownItem
                  iconBefore={<FontAwesomeIcon icon={faRotate} />}
                  onClick={() =>
                    handleChangeReferralStatus(referral._id, referral.status)
                  }
                >
                  Change Referral Status
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          ),
        },
      ],
    }));

    return (
      <div style={{ overflowX: "auto" }}>
        {" "}
        {/* Make the table scrollable */}
        <DynamicTable
          head={tableHead}
          rows={rows}
          isLoading={false}
          rowsPerPage={10}
          emptyView={<EmptyState message="No referrals found" />}
        />
      </div>
    );
  };

  // Rendering Referral Payments Table
  const renderReferralPaymentsTable = () => {
    const tableHead = {
      cells: [
        { key: "referrer", content: "Referrer" },
        { key: "amount", content: "Amount" },
        { key: "status", content: "Status" },
        { key: "actions", content: "Actions" },
      ],
    };

    const rows = referralPayments.map((payment) => ({
      key: payment._id,
      cells: [
        { key: "referrer", content: payment.referrer?.name || "Unknown" },
        {
          key: "amount",
          content: `$${payment.referrerPayoutAmount.toFixed(2)}`,
        },
        { key: "status", content: payment.status },

        {
          key: "actions",
          content: (
            <DropdownMenu
              trigger={({ triggerRef, ...props }) => (
                <Button {...props} ref={triggerRef}>
                  ...
                </Button>
              )}
            >
              <DropdownItemGroup>
                <DropdownItem onClick={() => handleViewListing(payment)}>
                  View Listing
                </DropdownItem>
                <DropdownItem>
                  Change Status
                  <DropdownMenu>
                    <DropdownItemGroup>
                      <DropdownItem
                        onClick={() =>
                          handleChangeStatus(payment._id, "pending")
                        }
                      >
                        Pending
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleChangeStatus(payment._id, "completed")
                        }
                      >
                        Completed
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleChangeStatus(payment._id, "cancelled")
                        }
                      >
                        Cancelled
                      </DropdownItem>
                    </DropdownItemGroup>
                  </DropdownMenu>
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          ),
        },
      ],
    }));

    return (
      <div style={{ overflowX: "auto" }}>
        {" "}
        {/* Make the table scrollable */}
        <DynamicTable
          head={tableHead}
          rows={rows}
          rowsPerPage={10}
          isLoading={false}
          emptyView={<EmptyState message="No referral payments found" />}
        />
      </div>
    );
  };

  return (
    <div className="base-page">
      <CreateReferralModal
        isOpen={isCreateReferralModalOpen}
        onClose={() => setCreateReferralModalOpen(false)}
        onSuccess={() => fetchReferrals()} // Refresh referrals after success
        onFailure={() => setCreateReferralModalOpen(false)}
      />
      <ViewQRCodeModal
        isOpen={isViewQRCodeModalOpen}
        onClose={() => setViewQRCodeModalOpen(false)}
        referral={selectedReferral}
      />
      <UserInfoModal
        isOpen={isUserInfoModalOpen}
        onClose={() => setUserInfoModalOpen(false)}
        userId={selectedUserId}
      />

      {selectedListingId && (
        <ViewListingModal
          isOpen={isViewListingModalOpen}
          onClose={() => setViewListingModalOpen(false)}
          listingId={selectedListingId} // Pass the selected listing to the modal
        />
      )}

      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ fontWeight: 700 }}>Referrals</h2>
          <Button
            appearance="primary"
            onClick={() => setCreateReferralModalOpen(true)}
          >
            Create Referral
          </Button>
        </div>
        <Tabs onChange={(index) => setActiveTab(index)}>
          <TabList>
            <Tab>Referrals</Tab>
            <Tab>Referral Payments</Tab>
          </TabList>
          <TabPanel>
            <div style={{ width: "100%", padding: "20px" }}>
              {renderReferralsTable()}
            </div>
          </TabPanel>
          <TabPanel>
            <div style={{ width: "100%", padding: "20px" }}>
              {renderReferralPaymentsTable()}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default ReferralPage;

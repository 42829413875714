import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@atlaskit/button/standard-button";
import { useHistory } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const ContactUsInfoCTA = () => {
  const history = useHistory(); // Create history object

  const handleEmailUs = () => {
    window.location.href =
      "mailto:info@tbwater.com.au?subject=Inquiry%20from%20Website";
  };

  return (
    <div className="homepage-info-cta">
      <div
        className="tile tile-large"
        style={{ padding: "30px", minHeight: "100px !important" }}
      >
        <div className="tbwe-text" style={{ paddingLeft: "20px" }}>
          Contact Us
        </div>
        <br />
        <p>
          {/* <strong>
            Australia’s Leading and Most Progressive Water Broking Company
          </strong> */}
        </p>
      </div>
    </div>
  );
};

export default ContactUsInfoCTA;

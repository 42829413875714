import React from "react";
import ResetPasswordModal from "../component/modals/ResetPasswordModal";
import { useParams } from "react-router-dom";
import { isAuth } from "../action/authAction"; // Import your isAuth function

const ResetPassword = () => {
  const { token } = useParams();
  const user = isAuth(); // Check if the user is signed in

  let headerText = "Reset Password";
  if (user) {
    headerText = "Change Password";
  }
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "30px" }}
      >
        <div
          style={{
            width: "500px",
            //boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            border: "2px solid rgba(99, 99, 99, 0.2)",
            padding: "50px",
            borderRadius: "20px",
          }}
        >
          <h2 className="text-center pb-4" style={{ fontWeight: "700" }}>
            {headerText}
          </h2>
          <ResetPasswordModal token={token} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;

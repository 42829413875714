import FinancialChart from "../../component/charts/financialChart";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    backgroundColor: "#24222b",
    padding: "20px",
    borderRadius: "5px",
    paddingBottom: "40px",
  },
}));

const FinancialChartTile = ({ title, market, zone }) => {
  const classes = useStyles();

  return (
    <div className={classes.homepageTileDark}>
      <h5 style={{ fontWeight: 700 }}>{title}</h5>
      <FinancialChart />
    </div>
  );
};

export default FinancialChartTile;

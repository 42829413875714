import React from "react";
import UpdatePhoneNumberModal from "../component/modals/UpdatePhoneNumberModal";
import { useParams } from "react-router-dom";
import { isAuth } from "../action/authAction"; // Import your isAuth function

const PhoneNumberPage = () => {
  const user = isAuth(); // Check if the user is signed in

  let headerText = "Enter Phone Number";

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "30px",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "500px",
            border: "2px solid rgba(99, 99, 99, 0.2)",
            padding: "50px",
            borderRadius: "20px",
            height: "fit-content",
          }}
        >
          <h2 className="text-center pb-4" style={{ fontWeight: "700" }}>
            {headerText}
          </h2>
          <UpdatePhoneNumberModal />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PhoneNumberPage;

import React, { useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";

const AcceptBuyListing = ({ isOpen, onClose, doAction, waterListing }) => {
  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal autoFocus={false} onClose={onClose} className="centeredModal">
            <ModalHeader>
              <ModalTitle>
                <b>Confirm Listing Purchase</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              Are you sure you want to purchase this water listing?
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button appearance="warning" onClick={doAction}>
                Yes I'm Sure
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default AcceptBuyListing;

import React, { useEffect } from "react";
import WaterTradeInfoSection from "../../component/section/waterTradeInfoSection";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    backgroundColor: "#24222b",
    padding: "20px",
    borderRadius: "5px",
  },
}));

const WaterTradeInfoTile = ({ title, market, zone }) => {
  const classes = useStyles();

  useEffect(() => {}, [market, zone, title]);

  return (
    <div className={classes.homepageTileDark}>
      <WaterTradeInfoSection title={title} market={market} zone={zone} />
    </div>
  );
};

export default WaterTradeInfoTile;

import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import {
  getPurchasedWaterOrders,
  getSoldWaterOrders,
  getAllWaterOrders,
  updateWaterOrderStatus, // Import the update function
} from "../../action/waterOrderAction";
import { requireRole, isAuth } from "../../action/authAction"; // Import `isAuth` to check role
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import ViewWaterOrderModal from "../../component/modals/waterOrder/viewWaterOrderModal";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ececec",
    padding: "20px",
  },
  ordersPanel: {
    marginBottom: "20px",
  },
}));

const AllOrders = () => {
  const [purchasedOrders, setPurchasedOrders] = useState([]);
  const [soldOrders, setSoldOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isViewOrderModalOpen, setIsViewOrderModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const { theme } = useTheme();
  const classes = useStyles();
  const isAdmin = isAuth()?.role === 99; // Check if user is admin

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const purchasedResponse = await getPurchasedWaterOrders();
      const soldResponse = await getSoldWaterOrders();
      const allOrdersResponse = await getAllWaterOrders();

      if (purchasedResponse.ok && soldResponse.ok && allOrdersResponse.ok) {
        const purchasedData = await purchasedResponse.json();
        const soldData = await soldResponse.json();
        const allOrdersData = await allOrdersResponse.json();
        setPurchasedOrders(purchasedData);
        setSoldOrders(soldData);
        setAllOrders(allOrdersData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setIsLoading(false);
    }
  };

  const toggleStatus = async (orderId, currentStatus) => {
    const newStatus =
      currentStatus === "Payment Pending" ? "Paid" : "Payment Pending";
    try {
      const response = await updateWaterOrderStatus(orderId, newStatus);
      if (response.ok) {
        fetchOrders(); // Refresh orders after updating
      } else {
        console.error("Failed to update order status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const renderRows = (orders) => {
    if (!Array.isArray(orders) || orders.length === 0) {
      return [
        {
          key: "no-orders",
          cells: [
            {
              key: "no-orders-label",
              content: <div>No orders</div>,
              colSpan: 4,
            },
          ],
        },
      ];
    }

    return orders.map((order) => {
      const oppositeStatus =
        order.status === "Payment Pending" ? "Paid" : "Payment Pending";

      return {
        key: order._id,
        cells: [
          { key: "orderId", content: order._id },
          { key: "amount", content: `$${order.pricePerML.toFixed(2)}` },
          { key: "status", content: order.status },
          {
            key: "actions",
            content: (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ marginRight: "10px" }}>
                  <Button onClick={() => handleOrderClick(order._id)}>
                    View Order
                  </Button>
                </div>
                {isAdmin && (
                  <Button onClick={() => toggleStatus(order._id, order.status)}>
                    Change to{" "}
                    {oppositeStatus === "Payment Pending" ? "Pending" : "Paid"}
                  </Button>
                )}
              </div>
            ),
          },
        ],
      };
    });
  };

  const tableHead = {
    cells: [
      { key: "orderId", content: "Order ID" },
      { key: "amount", content: "Price Per ML" },
      { key: "status", content: "Status" },
      {
        key: "actions",
        content: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            Actions{" "}
          </div>
        ),
      },
    ],
  };

  const handleOrderClick = (orderId) => {
    setSelectedOrderId(orderId);
    setIsViewOrderModalOpen(true);
  };

  return (
    <div className="base-page">
      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h2 style={{ fontWeight: "700" }}>Water Orders</h2>
        <Tabs>
          <TabList>
            <Tab>All Orders</Tab>
            <Tab>Purchased Orders</Tab>
            <Tab>Sold Orders</Tab>
          </TabList>
          <TabPanel>
            <div style={{ paddingTop: "40px", minWidth: "100%" }}>
              {isLoading ? (
                <p>Loading orders...</p>
              ) : allOrders.length ? (
                <div style={{ overflowX: "auto" }}>
                  <DynamicTable
                    head={tableHead}
                    rows={renderRows(allOrders)}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={false}
                    rowsPerPage={10}
                  />
                </div>
              ) : (
                <p>No orders available.</p>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div style={{ paddingTop: "40px", minWidth: "100%" }}>
              {isLoading ? (
                <p>Loading orders...</p>
              ) : purchasedOrders.length ? (
                <div style={{ overflowX: "auto" }}>
                  <DynamicTable
                    head={tableHead}
                    rows={renderRows(purchasedOrders)}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={false}
                    rowsPerPage={10}
                  />
                </div>
              ) : (
                <p>No purchased orders available.</p>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div style={{ paddingTop: "40px", minWidth: "100%" }}>
              {isLoading ? (
                <p>Loading orders...</p>
              ) : soldOrders.length ? (
                <div style={{ overflowX: "auto" }}>
                  <DynamicTable
                    head={tableHead}
                    rows={renderRows(soldOrders)}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={false}
                    rowsPerPage={10}
                  />
                </div>
              ) : (
                <p>No sold orders available.</p>
              )}
            </div>
          </TabPanel>
        </Tabs>
        <ViewWaterOrderModal
          isOpen={isViewOrderModalOpen}
          onClose={() => setIsViewOrderModalOpen(false)}
          orderId={selectedOrderId}
        />
      </div>
    </div>
  );
};

export default requireRole([1, 99])(AllOrders);

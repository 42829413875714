import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const getPriceAlerts = async () => {
  try {
    const response = await fetch(`${API}/price-alerts`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const postPriceAlert = async (priceAlertData) => {
  try {
    const response = await fetch(`${API}/price-alert`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(priceAlertData),
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const deletePriceAlert = async (priceAlertId) => {
  try {
    const response = await fetch(`${API}/delete-price-alert`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({ priceAlertId: priceAlertId }),
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

import React, { useState, useEffect } from "react";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import DynamicTable from "@atlaskit/dynamic-table";
import Select from "@atlaskit/select";
import { PopupSelect } from "@atlaskit/select";
import EmptyState from "../../component/misc/EmptyState";
import {
  getBidsSent,
  getBidsReceived,
  deleteBid,
} from "../../action/bidsAction"; // Add deleteBid action
import Lozenge from "@atlaskit/lozenge";
import ViewListing from "../../component/modals/listing/viewListing";
import { toast } from "react-toastify";
import Button from "@atlaskit/button";

const Bids = () => {
  const [bidsSent, setBidsSent] = useState([]);
  const [bidsReceived, setBidsReceived] = useState([]);
  const [selectedWaterListing, setSelectedWaterListing] = useState(null);
  const [showWaterListingModal, setShowWaterListingModal] = useState(false);
  const [searchValueSent, setSearchValueSent] = useState(null);
  const [searchValueReceived, setSearchValueReceived] = useState(null);

  useEffect(() => {
    fetchBidsSent();
    fetchBidsReceived();
  }, []);

  const fetchBidsSent = async () => {
    try {
      const response = await getBidsSent();
      if (response.ok) {
        const data = await response.json();
        setBidsSent(data);
      }
    } catch (error) {
      console.error("Error fetching bids sent:", error);
    }
  };

  const fetchBidsReceived = async () => {
    try {
      const response = await getBidsReceived();
      if (response.ok) {
        const data = await response.json();
        setBidsReceived(data);
      }
    } catch (error) {
      console.error("Error fetching bids received:", error);
    }
  };

  const handleViewWaterListing = (waterListingId) => {
    setSelectedWaterListing(waterListingId);
    setShowWaterListingModal(true);
  };

  const closeWaterListingModal = () => {
    setShowWaterListingModal(false);
    setSelectedWaterListing(null);
  };

  const handleSearchChangeSent = (selectedOption) => {
    setSearchValueSent(selectedOption ? selectedOption.value : null);
  };

  const handleSearchChangeReceived = (selectedOption) => {
    setSearchValueReceived(selectedOption ? selectedOption.value : null);
  };

  const handleDeleteBid = async (bidId) => {
    try {
      const response = await deleteBid(bidId);
      if (response.ok) {
        toast.success("Bid deleted successfully.");
        fetchBidsSent(); // Refresh the list
      } else {
        throw new Error("Failed to delete the bid.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const searchOptions = (bids) =>
    bids.map((bid) => ({
      label: `Bid ${bid._id}`,
      value: bid._id,
    }));

  const renderBidsRows = (bids) =>
    bids.map((bid, index) => ({
      key: `row-${index}`,
      cells: [
        { key: "id", content: `Bid ${bid._id}` },
        { key: "price", content: `${bid.pricePerML} /ML` },
        { key: "status", content: <Lozenge>{bid.status}</Lozenge> },
        { key: "isWinningBid", content: bid.isWinningBid ? "Yes" : "No" },
        {
          key: "actions",
          content: (
            <PopupSelect
              options={[
                {
                  label: "View Listing",
                  value: "view",
                },
                ...(bid.status === "Open" ||
                (bid.status === "Rejected" && !bid.isWinningBid)
                  ? [
                      {
                        label: "Delete Bid",
                        value: "delete",
                      },
                    ]
                  : []),
              ]}
              onChange={(option) => {
                if (option.value === "view") {
                  handleViewWaterListing(bid.waterListingId);
                } else if (option.value === "delete") {
                  handleDeleteBid(bid._id);
                }
              }}
              target={({ isOpen, ...triggerProps }) => (
                <Button {...triggerProps}>...</Button>
              )}
            />
          ),
        },
      ],
    }));

  return (
    <div className="base-page">
      {selectedWaterListing && (
        <ViewListing
          isOpen={showWaterListingModal}
          onClose={closeWaterListingModal}
          listingId={selectedWaterListing}
        />
      )}
      <div style={{ display: "flex", width: "100%" }}>
        <h2 style={{ fontWeight: "bold", width: "100%" }}>Bids</h2>
      </div>
      <Tabs id="manage-bids">
        <TabList>
          <Tab>Bids Sent</Tab>
          <Tab>Bids Received</Tab>
        </TabList>
        <TabPanel>
          <div style={{ minWidth: "100%", paddingTop: "50px" }}>
            <Select
              options={searchOptions(bidsSent)}
              onChange={handleSearchChangeSent}
              placeholder="Search bids sent..."
              isClearable
              value={searchOptions(bidsSent).find(
                (option) => option.value === searchValueSent
              )}
            />
            {/* Make table scrollable in mobile */}
            <div style={{ overflowX: "auto" }}>
              <DynamicTable
                head={{
                  cells: [
                    { key: "id", content: "Bid ID" },
                    { key: "price", content: "Price per ML" },
                    { key: "status", content: "Status" },
                    { key: "isWinningBid", content: "Winning Bid" },
                    { key: "actions", content: "Actions" },
                  ],
                }}
                rows={renderBidsRows(
                  bidsSent.filter(
                    (bid) => !searchValueSent || bid._id === searchValueSent
                  )
                )}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={10}
                isRankable
                emptyView={<EmptyState message="No bids sent to display." />}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div style={{ minWidth: "100%", paddingTop: "50px" }}>
            <Select
              options={searchOptions(bidsReceived)}
              onChange={handleSearchChangeReceived}
              placeholder="Search bids received..."
              isClearable
              value={searchOptions(bidsReceived).find(
                (option) => option.value === searchValueReceived
              )}
            />
            <div style={{ overflowX: "auto" }}>
              <DynamicTable
                head={{
                  cells: [
                    { key: "id", content: "Bid ID" },
                    { key: "price", content: "Price per ML" },
                    { key: "status", content: "Status" },
                    { key: "isWinningBid", content: "Winning Bid" },
                    { key: "actions", content: "Actions" },
                  ],
                }}
                rows={renderBidsRows(
                  bidsReceived.filter(
                    (bid) =>
                      !searchValueReceived || bid._id === searchValueReceived
                  )
                )}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={10}
                isRankable
                emptyView={
                  <EmptyState message="No bids received to display." />
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Bids;

import React, { useEffect, useState } from "react";
import Button from "@atlaskit/button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Lozenge from "@atlaskit/lozenge";
import { getUser } from "../../../action/userAction";

const UserInfoModal = ({ isOpen, onClose, userId = null, user = null }) => {
  const [userData, setUserData] = useState(user);

  useEffect(() => {
    if (!user && userId) {
      const fetchUserData = async () => {
        try {
          const response = await getUser(userId);
          if (response && !response.error) {
            setUserData(response);
          } else {
            console.error("Failed to fetch user data:", response.error);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    }
  }, [user, userId]);

  if (!userData) {
    return null; // Return nothing if no user data is available
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <b>User Information</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div style={{ padding: "20px" }}>
              <div style={{ marginBottom: "10px" }}>
                <b>Name:</b> {userData.name}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Email:</b> {userData.email}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Organisation:</b> {userData.organisationId?.name || "N/A"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Pending Organisation Approval:</b>{" "}
                {userData.pendingOrganisationApproval ? "Yes" : "No"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Organisation Owner:</b>{" "}
                {userData.organisationOwner ? "Yes" : "No"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Role:</b>{" "}
                {userData.role === 99
                  ? "Admin"
                  : userData.role === 1
                  ? "Manager"
                  : "User"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Description:</b> {userData.description || "N/A"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Brokerage Fee Percentage:</b>{" "}
                {userData.brokerageFeePercentage}%
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Admin Verified:</b> {userData.adminVerified ? "Yes" : "No"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Seat Status:</b>{" "}
                <Lozenge
                  appearance={
                    userData.seatStatus === "Active"
                      ? "success"
                      : userData.seatStatus === "Deactive"
                      ? "removed"
                      : "inprogress"
                  }
                >
                  {userData.seatStatus}
                </Lozenge>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Theme:</b> {userData.theme}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Phone Number:</b> {userData.phoneNumber || "N/A"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <b>Referred By:</b>{" "}
                {userData.referredBy ? userData.referredBy.name : "N/A"}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button appearance="primary" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default UserInfoModal;

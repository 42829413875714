import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@atlaskit/button/standard-button";
import { API } from "../../config/config";
import cookie from "js-cookie"; // Assuming you're using js-cookie to manage tokens
import { toast } from "react-toastify"; // Assuming you are using react-toastify for toast messages
import "react-toastify/dist/ReactToastify.css";

const XeroAuth = () => {
  const history = useHistory();
  const location = useLocation(); // Use useLocation to access the current URL

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(location.search); // Use location.search instead of window.location.search
      const code = urlParams.get("code");

      if (code) {
        const token = cookie.get("jwt_auth");

        if (token) {
          try {
            // Get the full callback URL including the query parameters
            const callbackUrl = `${location.pathname}${location.search}`; // Construct the full callback URL

            const response = await fetch(`${API}/xero/callback`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ callbackUrl }),
              credentials: "include",
            });

            if (response.ok) {
              toast.success("Xero authentication successful!");
            } else {
              console.error(
                "Failed to process Xero callback:",
                response.statusText
              );
              toast.error("Xero authentication failed!");
            }
          } catch (error) {
            console.error("Error during Xero Callback handling:", error);
            toast.error("Error during Xero authentication!");
          }
        } else {
          console.error("Authentication token is missing");
          toast.error("Authentication token is missing");
        }
      }
    };

    handleCallback();
  }, [location]);

  const AuthenticateXero = async () => {
    const token = cookie.get("jwt_auth");

    if (token) {
      try {
        const response = await fetch(`${API}/xero/auth`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          const consentUrl = data.consentUrl;

          // Redirect to the Xero consent URL
          window.location.href = consentUrl;
        } else {
          console.error("Failed to get Xero consent URL:", response.statusText);
          toast.error("Failed to initiate Xero authentication!");
        }
      } catch (error) {
        console.error("Error during Xero Authentication:", error);
        toast.error("Error during Xero authentication!");
      }
    } else {
      console.error("Authentication token is missing");
      toast.error("Authentication token is missing");
    }
  };

  return (
    <div className="base-page">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3 style={{ fontWeight: 700 }}>Xero Auth</h3>
      </div>
      <hr />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button appearance="primary" onClick={AuthenticateXero}>
          Link Xero Organisation
        </Button>
      </div>
    </div>
  );
};

export default XeroAuth;

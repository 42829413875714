const EmptyState = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        //padding: "20px",
        width: "100%", // Ensure it takes full width
        clear: "both",
      }}
    >
      {" "}
      <img
        src="/images/desert_800.png"
        alt="No data"
        style={{ width: "200px", height: "150px", objectFit: "cover" }}
      />
      <p style={{ fontWeight: 600, opacity: 0.8 }}>
        {message || "There are currently no entries to display here."}
      </p>
    </div>
  );
};

export default EmptyState;

import React, { useEffect } from "react";
import Badge from "@atlaskit/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { requireRole } from "./../../action/authAction"; // Import the requireAdmin function

const UserProfile = () => {
  useEffect(() => {}, []);

  return (
    <div className="base-page">
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          placeItems: "center",
        }}
      >
        <div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <FontAwesomeIcon style={{ fontSize: "50px" }} icon={faUser} />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <Badge appearance="added">User Profile</Badge>
          </div>
          <h1 style={{ justifyContent: "center", display: "flex" }}>User</h1>

          <p style={{ justifyContent: "center", display: "flex" }}>
            View all trades made on true blue water exchange. Coming Soon!
          </p>
        </div>
      </div>
    </div>
  );
};

export default requireRole([99])(UserProfile);

const getMarketName = (market) => {
  switch (market) {
    case "":
      return "All Markets";
    case "Temporary Allocation":
      return "Temporary Allocation";
    case "Permanent Entitlement":
      return "Permanent Allocation";
    case "Forward Allocation":
      return "Forward Allocation";
    case "Carry Over Parking":
      return "Carry Over Parking";
    case "Lease of Entitlement":
      return "Lease of Entitlement";
    default:
      return "All Markets";
  }
};

const zoneMapping = {
  // NSW
  "NSW Murrumbridgee_Zone 13": 14,
  "NSW Murrumbridgee_Zone 13 - High Security": 14,
  "NSW Murrumbridgee_Zone 13 - General Security": 14,
  "NSW Murrumbridgee_Zone 13 - Supplementary": 14,
  "NSW Murrumbridgee_Zone 13 - Private Diverter": 14,
  "NSW Murrumbridgee_Zone 13 - Murrumbidgee Irrigation Area": 14,
  "NSW Murrumbridgee_Zone 13 - Coleambally Irrigation": 14,

  "NSW Murray (Below Choke)_Zone 11": 12,
  "NSW Murray (Below Choke)_Zone 11 - High Security": 12,
  "NSW Murray (Below Choke)_Zone 11 - General Security": 12,
  "NSW Murray (Below Choke)_Zone 11 - Supplementary": 12,
  "NSW Murray (Below Choke)_Zone 11 - Private Diverter": 12,
  "NSW Murray (Below Choke)_Zone 11 - Western Murray Irrigation": 12,

  "NSW Murray (Above Choke)_Zone 10": 11,
  "NSW Murray (Above Choke)_Zone 10 - High Security": 11,
  "NSW Murray (Above Choke)_Zone 10 - General Security": 11,
  "NSW Murray (Above Choke)_Zone 10 - Supplementary": 11,
  "NSW Murray (Above Choke)_Zone 10 - Private Diverter": 11,
  "NSW Murray (Above Choke)_Zone 10 - Western Murray Irrigation": 11,

  // VIC
  "VIC Murray (Below Choke)_Zone 7 - High Reliability": 10,
  "VIC Murray (Below Choke)_Zone 7 - Low Reliability": 10,
  "VIC Murray (Below Choke)_Zone 7": 10,

  "VIC Murray (Above Choke)_Zone 6 - High Reliability": 8,
  "VIC Murray (Above Choke)_Zone 6 - Low Reliability": 8,
  "VIC Murray (Above Choke)_Zone 6": 8,

  "VIC Lower Broken Creek_Zone 6B - High Reliability": 9,
  "VIC Lower Broken Creek_Zone 6B - Low Reliability": 9,
  "VIC Lower Broken Creek_Zone 6B": 9,

  "VIC Loddon_Zone 5A - High Reliability": 7,
  "VIC Loddon_Zone 5A - Low Reliability": 7,
  "VIC Loddon_Zone 5A": 7,

  "VIC Campaspe_Zone 4 - High Reliability": 5,
  "VIC Campaspe_Zone 4 - Low Reliability": 5,
  "VIC Campaspe_Zone 4": 5,

  "VIC Lower Goulburn_Zone 3 - High Reliability": 4,
  "VIC Lower Goulburn_Zone 3 - Low Reliability": 4,
  "VIC Lower Goulburn_Zone 3": 4,

  "VIC broker_Zone 2 - High Reliability": 3,
  "VIC broker_Zone 2 - Low Reliability": 3,
  "VIC broker_Zone 2": 3,

  "VIC Goulburn_Zone 1A - High Reliability": 1,
  "VIC Goulburn_Zone 1A - Low Reliability": 1,
  "VIC Goulburn_Zone 1A": 1,

  "VIC Goulburn_Zone 1B - High Reliability": 1,
  "VIC Goulburn_Zone 1B - Low Reliability": 1,
  "VIC Goulburn_Zone 1B": 1,

  // SA
  "SA River Murray_Zone 12 - Class 3A": 13,
  "SA River Murray_Zone 12 - Class 1": 13,
  "SA River Murray_Zone 12 - Private Diverter": 13,
  "SA River Murray_Zone 12 - Central Irrigation Trust": 13,
  "SA River Murray_Zone 12 - Renmark Irrigation Trust": 13,
  "SA River Murray_Zone 12": 13,
};

const zoneReliabilityMapping = {
  // NSW
  "NSW Murrumbridgee_Zone 13 - High Security": "High Security",
  "NSW Murrumbridgee_Zone 13 - General Security": "General Security",
  "NSW Murray (Below Choke)_Zone 11 - High Security": "High Security",
  "NSW Murray (Below Choke)_Zone 11 - General Security": "General Security",
  "NSW Murray (Above Choke)_Zone 10 - High Security": "High Security",
  "NSW Murray (Above Choke)_Zone 10 - General Security": "General Security",

  // VIC
  "VIC Murray (Below Choke)_Zone 7 - High Reliability": "High Security",
  "VIC Murray (Below Choke)_Zone 7 - Low Reliability": "Low Reliability",
  "VIC Murray (Above Choke)_Zone 6 - High Reliability": "High Security",
  "VIC Murray (Above Choke)_Zone 6 - Low Reliability": "Low Reliability",
  "VIC Lower Broken Creek_Zone 6B - High Reliability": "High Security",
  "VIC Lower Broken Creek_Zone 6B - Low Reliability": "Low Reliability",
  "VIC Loddon_Zone 5A - High Reliability": "High Security",
  "VIC Loddon_Zone 5A - Low Reliability": "Low Reliability",
  "VIC Campaspe_Zone 4 - High Reliability": "High Security",
  "VIC Campaspe_Zone 4 - Low Reliability": "Low Reliability",
  "VIC Lower Goulburn_Zone 3 - High Reliability": "High Security",
  "VIC Lower Goulburn_Zone 3 - Low Reliability": "Low Reliability",
  "VIC broker_Zone 2 - High Reliability": "High Security",
  "VIC broker_Zone 2 - Low Reliability": "Low Reliability",
  "VIC Goulburn_Zone 1A - High Reliability": "High Security",
  "VIC Goulburn_Zone 1A - Low Reliability": "Low Reliability",
  "VIC Goulburn_Zone 1B - High Reliability": "High Security",
  "VIC Goulburn_Zone 1B - Low Reliability": "Low Reliability",

  // SA
  "SA River Murray_Zone 12 - Class 3A": "High Security",
  "SA River Murray_Zone 12 - Class 1": "High Security",
};

// Function to get reliability based on the zone name
const getReliability = (zoneName) => {
  console.log("getReliability: ", zoneName);
  console.log("getReliability: ", zoneReliabilityMapping[zoneName]);

  return zoneReliabilityMapping[zoneName] || null; // Return the corresponding reliability or null if not found
};

// Function to get the waterZoneId based on the selection label
const getZoneId = (zoneName) => {
  console.log("getZoneId: ", zoneName);
  console.log("getZoneId: ", zoneMapping[zoneName]);

  return zoneMapping[zoneName] || null; // Return the corresponding waterZoneId or null if not found
};

// Function to get all trade zones in the same zone
const getOtherTradeZones = (zoneName) => {
  const zoneId = getZoneId(zoneName);
  if (zoneId === -2) {
    return [];
  }

  return Object.keys(zoneMapping).filter(
    (key) => zoneMapping[key] === zoneId && key !== zoneName
  );
};

// Function to get all zone names associated with a specific zoneId
const getZoneNamesById = (zoneId) => {
  return Object.keys(zoneMapping).filter((key) => zoneMapping[key] === zoneId);
};

module.exports = {
  getMarketName,
  getZoneId,
  getOtherTradeZones,
  getZoneNamesById,
  getReliability,
};

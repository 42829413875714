import React, { useEffect, useState } from "react";
import { isAuth, signup, signin, authenticate } from "../../action/authAction";
import { withRouter, useLocation, Link } from "react-router-dom"; // Added Link for navigation
import Textfield from "@atlaskit/textfield";
import { useTheme } from "../../theme/ThemeContext";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import { getActiveReferrals } from "../../action/referralAction";

const SignUpModal = ({ history }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    error: "",
    loading: false,
    message: "",
    showForm: true,
    agreeToTerms: false,
  });

  const [referralCode, setReferralCode] = useState(null);
  const [referrals, setReferrals] = useState([]);
  const [selectedReferral, setSelectedReferral] = useState(null);

  const location = useLocation();

  useEffect(() => {
    async function fetchReferrals() {
      try {
        const referralData = await getActiveReferrals();
        if (!referralData.error) {
          const formattedReferrals = referralData.map((ref) => ({
            label: ref.referralCode,
            value: ref.referralCode,
          }));
          setReferrals(formattedReferrals);

          const params = new URLSearchParams(location.search);
          const code = params.get("referral");
          if (code) {
            setReferralCode(code);
            const matchedReferral = formattedReferrals.find(
              (ref) => ref.value === code
            );
            if (matchedReferral) {
              setSelectedReferral(matchedReferral);
            }
          }
        }
      } catch (error) {
        console.log("Error fetching referrals: ", error);
      }
    }

    isAuth() && history.push("/");
    fetchReferrals();
  }, [history, location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (values.password !== values.confirmPassword) {
      setValues({ ...values, error: "Passwords do not match", loading: false });
      return;
    }

    if (!values.agreeToTerms) {
      setValues({
        ...values,
        error: "You must agree to the terms and conditions",
        loading: false,
      });
      return;
    }

    setValues({ ...values, loading: true, error: "" });

    try {
      const user = {
        name: values.name,
        email: values.email,
        password: values.password,
        referralCode: selectedReferral ? selectedReferral.value : referralCode,
      };
      const data = await signup(user);

      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        setValues({
          ...values,
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          error: "",
          loading: false,
          message: data.message,
          showForm: false,
        });

        const signInData = await signin({
          email: values.email,
          password: values.password,
        });
        if (!signInData || signInData.error) {
          setValues({
            ...values,
            error: signInData.error || "Sign in error",
            loading: false,
          });
        } else {
          authenticate(signInData, () => {
            if (isAuth() && isAuth().role === 99) {
              history.push("/admin");
            } else {
              history.push("/dashboard");
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: "", [name]: e.target.value });
  };

  const handleReferralSelect = (selectedOption) => {
    setSelectedReferral(selectedOption);
  };

  const handleCheckboxChange = () => {
    setValues({ ...values, agreeToTerms: !values.agreeToTerms });
  };

  const signupForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field name="name" label="Full Name:">
          {({ fieldProps }) => (
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Type your name"
              onChange={handleChange("name")}
              required
              type="text"
              value={values.name}
            />
          )}
        </Field>

        <Field name="email" label="Email:">
          {({ fieldProps }) => (
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Type your email"
              onChange={handleChange("email")}
              required
              type="email"
              value={values.email}
            />
          )}
        </Field>

        <Field name="password" label="Password:">
          {({ fieldProps }) => (
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Type your password"
              onChange={handleChange("password")}
              required
              type="password"
              value={values.password}
            />
          )}
        </Field>

        <Field name="confirmPassword" label="Confirm Password:">
          {({ fieldProps }) => (
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Confirm Password"
              onChange={handleChange("confirmPassword")}
              required
              type="password"
              value={values.confirmPassword}
            />
          )}
        </Field>

        {/* Conditionally render Referral Code Select */}
        {!referralCode && (
          <Field name="referralCode" label="Referral Code (Optional):">
            {({ fieldProps }) => (
              <Select
                inputId="referral-select"
                options={referrals}
                placeholder="Select a referral code"
                onChange={handleReferralSelect}
                value={selectedReferral}
              />
            )}
          </Field>
        )}

        {/* Checkbox for agreeing to terms */}
        <br />
        <div className="form-group">
          <input
            type="checkbox"
            checked={values.agreeToTerms}
            onChange={handleCheckboxChange}
          />{" "}
          I agree to the{" "}
          <Link
            style={{ color: "white" }}
            to="/terms-and-conditions"
            target="_blank"
          >
            terms and conditions
          </Link>
        </div>
      </div>
      <br />
      <button
        type="submit"
        className="btn btn-primary"
        style={{ float: "right" }}
      >
        Sign Up
      </button>
    </form>
  );

  return (
    <div className="container">
      {values.loading && <div className="alert alert-info">Loading...</div>}
      {values.error && <div className="alert alert-danger">{values.error}</div>}
      {values.message && (
        <div className="alert alert-info">{values.message}</div>
      )}
      {values.showForm && signupForm()}
    </div>
  );
};

export default withRouter(SignUpModal);

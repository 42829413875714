import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isAuth } from "../action/authAction";
import NotFound from "../pages/error/notFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { matchPath } from "react-router"; // Import matchPath from react-router

const AccountConfirmationMiddleware = ({ children }) => {
  const history = useHistory();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const route = useLocation().pathname;
  const queryString = useLocation().search;

  // Handle resize to update mobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Authentication and confirmation logic
  useEffect(() => {
    const user = isAuth();
    if (!user) {
      const returnUrl = encodeURIComponent(route + queryString);
      history.push(`/signin?returnUrl=${returnUrl}`);
      setIsConfirmed(false);
    } else if (
      !user.organisationId &&
      user.pendingOrganisationApproval === false
    ) {
      history.push("/create-organisation");
    } else if (!user.adminVerified || !user.organisationId) {
      history.push("/confirm-account");
      setIsConfirmed(false);
    } else {
      setIsConfirmed(true);
    }
  }, [history, route]);

  // // Redirect to moveToLargerDevice if confirmed and on mobile
  // useEffect(() => {
  //   if (isConfirmed && isMobile) {
  //     const returnUrl = encodeURIComponent(route);
  //     history.push(`/moveToLargerDevice?returnUrl=${returnUrl}`);
  //   }
  // }, [isConfirmed, isMobile, history]);

  // Render the children components if the user is confirmed, otherwise return null
  const isRouteMatched = () => {
    return (
      Array.isArray(children) &&
      children.some((child) => {
        const { path } = child.props;
        const match = matchPath(route, { path, exact: true });
        return match !== null;
      })
    );
  };

  // Check if any child route matches the current route
  if (!isRouteMatched()) {
    return (
      <Route>
        {" "}
        <NotFound />
      </Route>
    );
  }
  return isConfirmed === null ? null : isConfirmed ? children : null;
};

export default AccountConfirmationMiddleware;

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromGroundWater,
  faAnglesRight,
  faTarpDroplet,
  faDroplet,
  faTruckDroplet,
  faChartLine,
  faBell,
  faScroll,
  faTags,
  faUsers,
  faUserTag,
  faPeopleGroup,
  faFire,
} from "@fortawesome/free-solid-svg-icons";
import HomeIcon from "@atlaskit/icon/glyph/home";
import RadioIcon from "@atlaskit/icon/glyph/radio";
import CreditcardFilledIcon from "@atlaskit/icon/glyph/creditcard-filled";
import NewListing from "../modals/listing/newListing";
import Button from "@atlaskit/button";
import { Footer } from "@atlaskit/side-navigation";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@atlaskit/icon/glyph/add";
import VidRaisedHandIcon from "@atlaskit/icon/glyph/vid-raised-hand";
import {
  LinkItem,
  NavigationFooter,
  NavigationHeader,
  NestableNavigationContent,
  Section,
  SideNavigation,
} from "@atlaskit/side-navigation";
import { isAuth } from "../../action/authAction";

const DynamicSidebar = () => {
  const history = useHistory();
  const location = useLocation(); // Assign location object here
  const [showNewListingModal, setShowNewListingModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const [isMobile, setIsMobile] = useState(false);

  // Open and close modal for new listing
  const openNewListingModal = () => {
    setShowNewListingModal(true);
  };

  const closeNewListingModal = () => {
    setShowNewListingModal(false);
  };

  // Function to process route and return a regex if dynamic, otherwise return the route itself
  const processRoute = (route) => {
    if (route.includes(":")) {
      // Replace dynamic segments like :id with a regex pattern
      const regexPattern = route.replace(/:[^\s/]+/g, "[^/]+");
      return new RegExp(`^${regexPattern}$`);
    }
    return `^${route}$`; // Ensure exact match for static routes
  };

  // Function to check if the current route matches any in a given list
  const isRouteSelected = (rawRoutes) => {
    return rawRoutes.some((rawRoute) => {
      const processedRoute = processRoute(rawRoute);
      const routeRegex = new RegExp(processedRoute);
      return routeRegex.test(selectedRoute);
    });
  };

  // Update selectedRoute when the location changes
  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);

  // Check if the window is in mobile mode and hide the sidebar
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {!isMobile && ( // Only show sidebar if not in mobile view
        <div
          style={{
            height: "calc(100vh - 52px)",
            position: "sticky",
            top: "52px",
          }}
        >
          <NewListing
            isOpen={showNewListingModal}
            onClose={closeNewListingModal}
          />
          <SideNavigation id="test" label="project" testId="side-navigation">
            <NavigationHeader>
              <LinkItem
                onClick={() => history.push("/dashboard")}
                iconBefore={<HomeIcon label="" />}
                iconAfter={<RadioIcon label="" />}
                isSelected={isRouteSelected(["/dashboard"])}
              >
                Dashboard
              </LinkItem>
              {isAuth() && isAuth().role === 99 && (
                <LinkItem
                  onClick={() => history.push("/admin")}
                  iconBefore={<VidRaisedHandIcon label="" />}
                  isSelected={isRouteSelected(["/admin"])}
                >
                  Admin Dashboard
                </LinkItem>
              )}
              <LinkItem
                iconBefore={<AddIcon label="" />}
                onClick={() => openNewListingModal()}
                isSelected={isRouteSelected(["/water-trading/submit-listing"])}
              >
                Create Water Listing
              </LinkItem>
            </NavigationHeader>
            <NestableNavigationContent
              initialStack={[]}
              testId="nestable-navigation-content"
            >
              {isAuth() && isAuth().role === 99 && (
                <Section title="Admin" isList>
                  <LinkItem
                    onClick={() => history.push("/users")}
                    iconBefore={<FontAwesomeIcon icon={faUsers} />}
                    isSelected={isRouteSelected(["/users"])}
                  >
                    Users
                  </LinkItem>
                  <LinkItem
                    onClick={() => history.push("/manage-water-licenses")}
                    iconBefore={<FontAwesomeIcon icon={faScroll} />}
                    isSelected={isRouteSelected(["/manage-water-licenses"])}
                  >
                    Manage Water Licenses
                  </LinkItem>
                  <LinkItem
                    onClick={() => history.push("/referrals")}
                    iconBefore={<FontAwesomeIcon icon={faPeopleGroup} />}
                    isSelected={isRouteSelected(["/referrals"])}
                  >
                    Referrals
                  </LinkItem>
                </Section>
              )}

              <Section title="Water Trade Markets" isList>
                <LinkItem
                  iconBefore={
                    <FontAwesomeIcon icon={faArrowUpFromGroundWater} />
                  }
                  onClick={() =>
                    history.push(
                      `/dashboard?market=${encodeURIComponent(
                        "Temporary Allocation"
                      )}`
                    )
                  }
                  isSelected={isRouteSelected([
                    `/dashboard?market=${encodeURIComponent(
                      "Temporary Allocation"
                    )}`,
                  ])}
                >
                  Temporary Allocation
                </LinkItem>
                <LinkItem
                  iconBefore={
                    <FontAwesomeIcon icon={faArrowUpFromGroundWater} />
                  }
                  onClick={() =>
                    history.push(
                      `/dashboard?market=${encodeURIComponent(
                        "Permanent Entitlement"
                      )}`
                    )
                  }
                  isSelected={isRouteSelected([
                    `/dashboard?market=${encodeURIComponent(
                      "Permanent Entitlement"
                    )}`,
                  ])}
                >
                  Permanent Entitlement
                </LinkItem>
                <LinkItem
                  iconBefore={<FontAwesomeIcon icon={faTruckDroplet} />}
                  onClick={() =>
                    history.push(
                      `/dashboard?market=${encodeURIComponent(
                        "Carry Over Parking"
                      )}`
                    )
                  }
                  isSelected={isRouteSelected([
                    `/dashboard?market=${encodeURIComponent(
                      "Carry Over Parking"
                    )}`,
                  ])}
                >
                  Carry Over Parking
                </LinkItem>
                <LinkItem
                  iconBefore={<FontAwesomeIcon icon={faTarpDroplet} />}
                  onClick={() =>
                    history.push(
                      `/dashboard?market=${encodeURIComponent(
                        "Lease of Entitlement"
                      )}`
                    )
                  }
                  isSelected={isRouteSelected([
                    `/dashboard?market=${encodeURIComponent(
                      "Lease of Entitlement"
                    )}`,
                  ])}
                >
                  Lease of Entitlement
                </LinkItem>
                <LinkItem
                  iconBefore={<FontAwesomeIcon icon={faAnglesRight} />}
                  onClick={() =>
                    history.push(
                      `/dashboard?market=${encodeURIComponent(
                        "Forward Allocation"
                      )}`
                    )
                  }
                  isSelected={isRouteSelected([
                    `/dashboard?market=${encodeURIComponent(
                      "Forward Allocation"
                    )}`,
                  ])}
                >
                  Forward Allocation
                </LinkItem>
                <LinkItem
                  iconBefore={<FontAwesomeIcon icon={faTags} />}
                  onClick={() => history.push("/water-trading/bids")}
                  isSelected={isRouteSelected(["/water-trading/bids"])}
                >
                  Bids
                </LinkItem>
              </Section>
              <Section title="Other" isList>
                <LinkItem
                  onClick={() => history.push("/user/listings")}
                  iconBefore={<FontAwesomeIcon icon={faUserTag} />}
                >
                  My Water Listings
                </LinkItem>
                <LinkItem
                  onClick={() => history.push("/history")}
                  iconBefore={<FontAwesomeIcon icon={faChartLine} />}
                >
                  Price History
                </LinkItem>
                <LinkItem
                  onClick={() => history.push("/heat-map")}
                  iconBefore={<FontAwesomeIcon icon={faFire} />}
                >
                  Trade Heat Map
                </LinkItem>
                <LinkItem
                  onClick={() => history.push("/price-alerts")}
                  iconBefore={<FontAwesomeIcon icon={faBell} />}
                >
                  Alerts
                </LinkItem>
                <LinkItem
                  onClick={() => history.push("/water-licenses")}
                  iconBefore={<FontAwesomeIcon icon={faScroll} />}
                  isSelected={isRouteSelected(["/water-licenses"])}
                >
                  Water Licenses
                </LinkItem>
              </Section>
              <Section title="Purchases" isList>
                <LinkItem
                  onClick={() => history.push("/orders/all")}
                  iconBefore={<CreditcardFilledIcon label="" />}
                >
                  Orders
                </LinkItem>
              </Section>
            </NestableNavigationContent>
            <NavigationFooter>
              <Footer>
                <span style={{ opacity: "0.7" }}>
                  Move faster with True Blue Water Exchange
                </span>
              </Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  appearance="subtle-link"
                  style={{ fontSize: "15px" }}
                  onClick={() => history.push("/terms-and-conditions")}
                  spacing="none"
                >
                  T&C's
                </Button>

                <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  {" "}
                  {" ∙ "}
                </span>
                <Button
                  appearance="subtle-link"
                  style={{ fontSize: "15px" }}
                  onClick={() => history.push("/fees")}
                  spacing="none"
                >
                  Fees
                </Button>
              </div>
            </NavigationFooter>
          </SideNavigation>
        </div>
      )}
    </div>
  );
};

export default DynamicSidebar;

export const temporaryAllocationRegionsConsolidated = [
  // {
  //   options: [
  //     {
  //       label: "All",
  //       value: "",
  //     },
  //   ],
  // },
  {
    label: "NSW",
    options: [
      {
        label: "NSW Murrumbridgee_Zone 13",
        value: "NSW Murrumbridgee_Zone 13",
        tradeZoneDetails: "NSW Murrumbridgee_Zone 13",
        state: "NSW",
      },

      {
        label: "NSW Murray (Below Choke)_Zone 11",
        value: "NSW Murray (Below Choke)_Zone 11",
        tradeZoneDetails: "NSW Murray (Below Choke)_Zone 11",
        state: "NSW",
      },

      {
        label: "NSW Murray (Above Choke)_Zone 10",
        value: "NSW Murray (Above Choke)_Zone 10",
        tradeZoneDetails: "NSW Murray (Above Choke)_Zone 10",
        state: "NSW",
      },
    ],
  },
  {
    label: "VIC",
    options: [
      {
        label: "VIC Murray (Below Choke)_Zone 7",
        value: "VIC Murray (Below Choke)_Zone 7",
        tradeZoneDetails: "VIC Murray (Below Choke)_Zone 7",
        state: "VIC",
      },

      {
        label: "VIC Murray (Above Choke)_Zone 6",
        value: "VIC Murray (Above Choke)_Zone 6",
        tradeZoneDetails: "VIC Murray (Above Choke)_Zone 6",
        state: "VIC",
      },

      {
        label: "VIC Lower Broken Creek_Zone 6B",
        value: "VIC Lower Broken Creek_Zone 6B",
        tradeZoneDetails: "VIC Lower Broken Creek_Zone 6B",
        state: "VIC",
      },

      {
        label: "VIC Loddon_Zone 5A",
        value: "VIC Loddon_Zone 5A",
        tradeZoneDetails: "VIC Loddon_Zone 5A",
        state: "VIC",
      },

      {
        label: "VIC Campaspe_Zone 4",
        value: "VIC Campaspe_Zone 4",
        tradeZoneDetails: "VIC Campaspe_Zone 4",
        state: "VIC",
      },

      {
        label: "VIC Lower Goulburn_Zone 3",
        value: "VIC Lower Goulburn_Zone 3",
        tradeZoneDetails: "VIC Lower Goulburn_Zone 3",
        state: "VIC",
      },

      {
        label: "VIC broker_Zone 2",
        value: "VIC broker_Zone 2",
        tradeZoneDetails: "VIC broker_Zone 2",
        state: "VIC",
      },

      {
        label: "VIC Goulburn_Zone 1A",
        value: "VIC Goulburn_Zone 1A",
        tradeZoneDetails: "VIC Goulburn_Zone 1A",
        state: "VIC",
      },

      {
        label: "VIC Goulburn_Zone 1B",
        value: "VIC Goulburn_Zone 1B",
        tradeZoneDetails: "VIC Goulburn_Zone 1B",
        state: "VIC",
      },
    ],
  },
  {
    label: "SA",
    options: [
      {
        label: "SA River Murray_Zone 12",
        value: "SA River Murray_Zone 12",
        tradeZoneDetails: "SA River Murray_Zone 12",
        state: "SA",
      },
    ],
  },
];

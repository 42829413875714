import React, { useEffect, useState } from "react";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button";
import Heading from "@atlaskit/heading";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import Lozenge from "@atlaskit/lozenge";
import Spinner from "@atlaskit/spinner";
import { Box, Grid, Stack, xcss } from "@atlaskit/primitives";
import { css } from "@emotion/react";
import {
  getWaterLicenseById,
  verifyWaterLicense,
  rejectWaterLicense,
  setWaterLicensePendingVerification,
} from "../../../action/waterLicenseAction";
import UserInfoModal from "../../modals/user/userInfoModal"; // Import the user info modal

const gridStyles = xcss({
  gridTemplateColumns: "1fr",
});

const closeContainerStyles = xcss({
  gridArea: "close",
  gridRowStart: "1",
  gridColumnStart: "1",
});

const contentContainerStyles = xcss({
  maxHeight: "400px", // Set a maximum height for the content area
  overflowY: "auto", // Enable vertical scrolling if content exceeds the height
});

const WaterLicenseInfoModal = ({ isOpen, onClose, licenseId }) => {
  const [licenseDetails, setLicenseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUserModalOpen, setUserModalOpen] = useState(false); // State to manage user modal visibility
  const [selectedUserId, setSelectedUserId] = useState(null); // State to manage selected user ID

  useEffect(() => {
    const fetchLicenseDetails = async () => {
      if (licenseId) {
        try {
          setLoading(true);
          const response = await getWaterLicenseById(licenseId);
          if (response.ok) {
            const data = await response.json();
            setLicenseDetails(data);
          } else {
            setError("Failed to load water license details.");
          }
        } catch (error) {
          setError(
            "An error occurred while fetching the water license details."
          );
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLicenseDetails();
  }, [licenseId]);

  const handleApprove = async () => {
    try {
      const response = await verifyWaterLicense(licenseId);
      if (response) {
        setLicenseDetails((prevState) => ({
          ...prevState,
          verified: "Verified",
        }));
      }
    } catch (error) {
      console.error("Error approving water license:", error);
    }
  };

  const handleReject = async () => {
    try {
      const response = await rejectWaterLicense(licenseId);
      if (response) {
        setLicenseDetails((prevState) => ({
          ...prevState,
          verified: "Rejected",
        }));
      }
    } catch (error) {
      console.error("Error rejecting water license:", error);
    }
  };

  const handlePendingApproval = async () => {
    try {
      const response = await setWaterLicensePendingVerification(licenseId);
      if (response) {
        setLicenseDetails((prevState) => ({
          ...prevState,
          verified: "Pending Verification",
        }));
      }
    } catch (error) {
      console.error("Error setting water license to pending approval:", error);
    }
  };

  const handleViewUser = (userId) => {
    setSelectedUserId(userId);
    setUserModalOpen(true);
  };

  const renderLicenseDetails = () => {
    if (loading) {
      return (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          `}
        >
          <Spinner size="large" />
        </div>
      );
    }

    if (error) {
      return (
        <p
          css={css`
            color: red;
            text-align: center;
            font-size: 16px;
          `}
        >
          {error}
        </p>
      );
    }

    if (!licenseDetails) {
      return <p>No details available.</p>;
    }

    return (
      <Box padding="space.500">
        <Stack>
          <Heading as="h4" size="small">
            Owner of Water Account:
          </Heading>
          <p>
            <Button
              appearance="link"
              onClick={() => handleViewUser(licenseDetails.userId._id)}
            >
              {licenseDetails.userId.name}
            </Button>
          </p>

          <Heading as="h4" size="small">
            License Zone:
          </Heading>
          <p>{licenseDetails.licenseZone || "N/A"}</p>

          <Heading as="h4" size="small">
            NOW Ref:
          </Heading>
          <p>{licenseDetails.nowRef || "N/A"}</p>

          <Heading as="h4" size="small">
            WAL:
          </Heading>
          <p>{licenseDetails.wal || "N/A"}</p>

          <Heading as="h4" size="small">
            ABA Number:
          </Heading>
          <p>{licenseDetails.abaNumber || "N/A"}</p>

          <Heading as="h4" size="small">
            WEE Number:
          </Heading>
          <p>{licenseDetails.weeNumber || "N/A"}</p>

          <Heading as="h4" size="small">
            Account Number:
          </Heading>
          <p>{licenseDetails.accountNumber || "N/A"}</p>

          <Heading as="h4" size="small">
            Number of Units:
          </Heading>
          <p>{licenseDetails.numberOfUnits || "N/A"} ML</p>

          <Heading as="h4" size="small">
            Market Type:
          </Heading>
          <p>{licenseDetails.marketType || "N/A"}</p>

          <Heading as="h4" size="small">
            Verified:
          </Heading>
          <Lozenge
            appearance={
              licenseDetails.verified === "Verified" ? "success" : "default"
            }
          >
            {licenseDetails.verified}
          </Lozenge>
        </Stack>
      </Box>
    );
  };

  const renderButtons = () => {
    const buttons = [];

    if (licenseDetails?.verified !== "Pending Verification") {
      buttons.push(
        <Button key="pending" onClick={handlePendingApproval}>
          Pending Approval
        </Button>
      );
    }

    if (licenseDetails?.verified !== "Rejected") {
      buttons.push(
        <Button key="reject" appearance="danger" onClick={handleReject}>
          Decline
        </Button>
      );
    }
    if (licenseDetails?.verified !== "Verified") {
      buttons.push(
        <Button key="approve" appearance="primary" onClick={handleApprove}>
          Approve
        </Button>
      );
    }

    return buttons;
  };

  return (
    <ModalTransition>
      {isOpen && (
        <ModalDialog onClose={onClose} width="medium">
          <Grid
            gap="space.0"
            templateAreas={["content close"]}
            xcss={gridStyles}
          >
            <Grid xcss={closeContainerStyles} justifyContent="end">
              <Button
                appearance="subtle"
                iconBefore={<CrossIcon />}
                onClick={onClose}
              />
            </Grid>
            <Grid xcss={contentContainerStyles}>{renderLicenseDetails()}</Grid>
          </Grid>
          <Box padding="space.300">
            <Stack alignInline="center">
              <ButtonGroup>{renderButtons()}</ButtonGroup>
            </Stack>
          </Box>
        </ModalDialog>
      )}

      {/* User Info Modal */}
      <UserInfoModal
        isOpen={isUserModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={selectedUserId}
      />
    </ModalTransition>
  );
};

export default WaterLicenseInfoModal;

import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button";
import { PopupSelect } from "@atlaskit/select";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import Select from "@atlaskit/select";
import { requireRole } from "./../../action/authAction";
import DynamicTable from "@atlaskit/dynamic-table";
import EmptyState from "../../component/misc/EmptyState";
import Lozenge from "@atlaskit/lozenge";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllPendingVerificationWaterLicenses,
  getAllVerifiedWaterLicenses,
  getAllRejectedWaterLicenses,
  verifyWaterLicense,
  rejectWaterLicense,
  setWaterLicensePendingVerification,
} from "../../action/waterLicenseAction";
import WaterLicenseInfoModal from "../../component/modals/waterLicense/waterLicenseInfoModal"; // Import the modal
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCheck,
  faTimes,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const ManageWaterLicenses = () => {
  const [unverifiedWaterLicenses, setUnverifiedWaterLicenses] = useState([]);
  const [verifiedWaterLicenses, setVerifiedWaterLicenses] = useState([]);
  const [rejectedWaterLicenses, setRejectedWaterLicenses] = useState([]);
  const [selectedLicenseId, setSelectedLicenseId] = useState(null);
  const [isWaterLicenseModalOpen, setWaterLicenseModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  const fetchUnverifiedWaterLicenses = async () => {
    try {
      const response = await getAllPendingVerificationWaterLicenses();
      if (response.ok) {
        const data = await response.json();
        setUnverifiedWaterLicenses(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVerifiedWaterLicenses = async () => {
    try {
      const response = await getAllVerifiedWaterLicenses();
      if (response.ok) {
        const data = await response.json();
        setVerifiedWaterLicenses(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRejectedWaterLicenses = async () => {
    try {
      const response = await getAllRejectedWaterLicenses();
      if (response.ok) {
        const data = await response.json();
        setRejectedWaterLicenses(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerify = async (licenseId) => {
    await verifyWaterLicense(licenseId);
    refreshAllWaterLicenseData();
  };

  const handleReject = async (licenseId) => {
    await rejectWaterLicense(licenseId);
    refreshAllWaterLicenseData();
  };

  const handlePendingVerification = async (licenseId) => {
    await setWaterLicensePendingVerification(licenseId);
    refreshAllWaterLicenseData();
  };

  const handleViewWaterLicense = (licenseId) => {
    setSelectedLicenseId(licenseId);
    setWaterLicenseModalOpen(true);
  };

  const generateOptions = (status) => {
    const options = [
      {
        label: (
          <span>
            <FontAwesomeIcon icon={faEye} /> View License Details
          </span>
        ),
        value: "view",
      },
    ];

    if (status !== "Verified") {
      options.push({
        label: (
          <span>
            <FontAwesomeIcon icon={faCheck} /> Verify
          </span>
        ),
        value: "verify",
      });
    }

    if (status !== "Rejected") {
      options.push({
        label: (
          <span>
            <FontAwesomeIcon icon={faTimes} /> Reject
          </span>
        ),
        value: "reject",
      });
    }

    if (status !== "Pending Verification") {
      options.push({
        label: (
          <span>
            <FontAwesomeIcon icon={faClock} /> Pending Verification
          </span>
        ),
        value: "pending",
      });
    }

    return options;
  };

  const handleSelectChange = (option, licenseId) => {
    switch (option.value) {
      case "view":
        handleViewWaterLicense(licenseId);
        break;
      case "verify":
        handleVerify(licenseId);
        break;
      case "reject":
        handleReject(licenseId);
        break;
      case "pending":
        handlePendingVerification(licenseId);
        break;
      default:
        break;
    }
  };

  const handleSearchChange = (selectedOption) => {
    setSearchValue(selectedOption ? selectedOption.value : null);
  };

  const searchOptions = unverifiedWaterLicenses.map((license) => ({
    label: license.ownerOfWaterAccount,
    value: license._id,
  }));

  const renderWaterLicenseRows = (licenses) => {
    return licenses.map((license, index) => ({
      key: `row-${index}`,
      cells: [
        { key: "name", content: license.ownerOfWaterAccount },
        { key: "licenseZone", content: license.licenseZone || "N/A" },
        { key: "marketType", content: license.marketType || "N/A" },
        {
          key: "verified",
          content: (
            <Lozenge
              appearance={
                license.verified === "Verified"
                  ? "success"
                  : license.verified === "Rejected"
                  ? "removed"
                  : "moved"
              }
            >
              {license.verified}
            </Lozenge>
          ),
        },
        {
          key: "actions",
          content: (
            <div
              onClick={(e) => {
                // Prevent the row's onClick event from firing when the dropdown is clicked
                e.stopPropagation();
              }}
            >
              <PopupSelect
                options={generateOptions(license.verified)}
                onChange={(option) => handleSelectChange(option, license._id)}
                target={({ isOpen, ...triggerProps }) => (
                  <Button {...triggerProps}>...</Button>
                )}
              />
            </div>
          ),
        },
      ],
      onClick: () => handleViewWaterLicense(license._id), // Row click to view water license
    }));
  };

  const refreshAllWaterLicenseData = () => {
    fetchUnverifiedWaterLicenses();
    fetchVerifiedWaterLicenses();
    fetchRejectedWaterLicenses();
  };

  useEffect(() => {
    fetchUnverifiedWaterLicenses();
    fetchVerifiedWaterLicenses();
    fetchRejectedWaterLicenses();
  }, []);

  return (
    <div className="base-page">
      <WaterLicenseInfoModal
        isOpen={isWaterLicenseModalOpen}
        onClose={() => setWaterLicenseModalOpen(false)}
        licenseId={selectedLicenseId}
      />
      <div style={{ width: "100%" }}>
        <h2 style={{ fontWeight: 700 }}>Manage Water Licenses</h2>
      </div>
      <Tabs
        onChange={(index) => console.log("Selected Tab", index + 1)}
        id="manage-water-licenses"
      >
        <TabList>
          <Tab>Unverified Water Licenses</Tab>
          <Tab>Verified Water Licenses</Tab>
          <Tab>Rejected Water Licenses</Tab>
        </TabList>
        <TabPanel>
          <div style={{ minWidth: "100%", paddingTop: "50px" }}>
            <Select
              options={searchOptions}
              onChange={handleSearchChange}
              placeholder="Search water licenses..."
              isClearable
              value={searchOptions.find(
                (option) => option.value === searchValue
              )}
            />
            {/* Wrapping the table in a scrollable div */}
            <div style={{ overflowX: "auto" }}>
              <DynamicTable
                head={{
                  cells: [
                    { key: "name", content: "Name" },
                    { key: "licenseZone", content: "License Zone" },
                    { key: "marketType", content: "Market Type" },
                    { key: "verified", content: "Verified" },
                    { key: "actions", content: "Actions" },
                  ],
                }}
                rows={renderWaterLicenseRows(
                  unverifiedWaterLicenses.filter(
                    (license) => !searchValue || license._id === searchValue
                  )
                )}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={20}
                isRankable
                emptyView={
                  <EmptyState message="No water licenses to display." />
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div style={{ minWidth: "100%", paddingTop: "50px" }}>
            <Select
              options={searchOptions}
              onChange={handleSearchChange}
              placeholder="Search water licenses..."
              isClearable
              value={searchOptions.find(
                (option) => option.value === searchValue
              )}
            />
            <div style={{ overflowX: "auto" }}>
              <DynamicTable
                head={{
                  cells: [
                    { key: "name", content: "Name" },
                    { key: "licenseZone", content: "License Zone" },
                    { key: "marketType", content: "Market Type" },
                    { key: "verified", content: "Verified" },
                    { key: "actions", content: "Actions" },
                  ],
                }}
                rows={renderWaterLicenseRows(
                  verifiedWaterLicenses.filter(
                    (license) => !searchValue || license._id === searchValue
                  )
                )}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={20}
                isRankable
                emptyView={
                  <EmptyState message="No water licenses to display." />
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div style={{ minWidth: "100%", paddingTop: "50px" }}>
            <Select
              options={searchOptions}
              onChange={handleSearchChange}
              placeholder="Search water licenses..."
              isClearable
              value={searchOptions.find(
                (option) => option.value === searchValue
              )}
            />
            <div style={{ overflowX: "auto" }}>
              <DynamicTable
                head={{
                  cells: [
                    { key: "name", content: "Name" },
                    { key: "licenseZone", content: "License Zone" },
                    { key: "marketType", content: "Market Type" },
                    { key: "verified", content: "Verified" },
                    { key: "actions", content: "Actions" },
                  ],
                }}
                rows={renderWaterLicenseRows(
                  rejectedWaterLicenses.filter(
                    (license) => !searchValue || license._id === searchValue
                  )
                )}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={20}
                isRankable
                emptyView={
                  <EmptyState message="No water licenses to display." />
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default requireRole([99])(ManageWaterLicenses);

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Links Section */}
        <div className="footer-links">
          <a href="/about" className="footer-link">
            About Us
          </a>
          <a href="/terms-and-conditions" className="footer-link">
            T&C's
          </a>
          <a href="/fees" className="footer-link">
            Fees
          </a>
        </div>

        {/* Copyright Section */}
        <div className="footer-copyright">
          <p>© 2024 True Blue Water Exchange. All rights reserved.</p>
        </div>

        {/* Social Media Icons Section */}
        <div className="footer-social">
          <a
            href="mailto:info@tbwater.com.au"
            className="social-icon"
            style={{ fontSize: "1.5rem", color: "white" }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            href="https://x.com/Truebluewaterex"
            target="_blank"
            className="social-icon"
            style={{ fontSize: "1.5rem", color: "white" }}
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a
            href="https://www.facebook.com/officialtbwe"
            target="_blank"
            className="social-icon"
            style={{ fontSize: "1.5rem", color: "white" }}
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

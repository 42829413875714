import React, { useEffect, useState, Fragment } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Form, { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import { Checkbox } from "@atlaskit/checkbox";
import { getUserById, updateUser } from "../../../action/userAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditUserModal = ({ isOpen, onClose, userId, onUserUpdated }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    brokerageFeePercentage: "",
    adminVerified: false,
  });

  useEffect(() => {
    if (userId && isOpen) {
      const fetchUser = async () => {
        try {
          const response = await getUserById(userId);
          console.log("edit user modal response", response);
          if (response) {
            setFormValues({
              name: response.name,
              email: response.email,
              brokerageFeePercentage: response.brokerageFeePercentage,
              adminVerified: response.adminVerified,
            });
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
          toast.error("Failed to load user details");
        }
      };
      fetchUser();
    }
  }, [userId, isOpen]);

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (name) => {
    setFormValues({ ...formValues, [name]: !formValues[name] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateUser(userId, formValues);
      if (response && !response.error) {
        toast.success("User updated successfully");
        onClose();
        onUserUpdated(); // Refresh the users list
      } else {
        throw new Error(response.error || "Error updating user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user");
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <h3 style={{ color: "white" }}>Edit User</h3>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Form>
              {({ formProps }) => (
                <form {...formProps} onSubmit={handleSubmit} id="editUserForm">
                  <Field name="name" label="Name:" isRequired>
                    {({ fieldProps }) => (
                      <Textfield
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange("name", e.target.value)
                        }
                        value={formValues.name}
                      />
                    )}
                  </Field>
                  <Field name="email" label="Email:" isRequired>
                    {({ fieldProps }) => (
                      <Textfield
                        type="email"
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange("email", e.target.value)
                        }
                        value={formValues.email}
                      />
                    )}
                  </Field>
                  <Field
                    name="brokerageFeePercentage"
                    label="Brokerage Fee Percentage:"
                    isRequired
                  >
                    {({ fieldProps }) => (
                      <Textfield
                        type="number"
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange(
                            "brokerageFeePercentage",
                            e.target.value
                          )
                        }
                        value={formValues.brokerageFeePercentage}
                      />
                    )}
                  </Field>
                  {/* New fields for email confirmation */}
                  <Field name="adminVerified" label="Admin Verified:">
                    {({ fieldProps }) => (
                      <Checkbox
                        {...fieldProps}
                        isChecked={formValues.adminVerified}
                        label="Admin Verified"
                        onChange={() => handleCheckboxChange("adminVerified")}
                      />
                    )}
                  </Field>
                </form>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button
              appearance="primary"
              type="submit"
              form="editUserForm"
              autoFocus
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default EditUserModal;

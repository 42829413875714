import React, { useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../../theme/ThemeContext";
import { placeBid } from "../../../action/waterListingAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles(() => ({
  summaryDarkBackground: {
    backgroundColor: "#22272B",
    borderRadius: "20px",
    padding: "20px",
  },
}));

const PlaceBid = ({
  isOpen,
  onClose,
  onSuccess,
  onFailure,
  waterListing,
  brokerageFeePercentage,
}) => {
  const { theme } = useTheme();
  const classes = useStyles();

  const [values, setValues] = useState({
    pricePerML: "",
    gst: 0,
  });

  // Constants for tax calculations
  const GST_PERCENTAGE = 0.1; // 10% GST

  const handleChange = (name) => (e) => {
    const updatedPricePerML = e.target.value;
    setValues({
      ...values,
      [name]: updatedPricePerML,
      message: "",
      //gst: calculateGST(parseFloat(updatedPricePerML) || 0).toFixed(2),
      // brokerageFeeAmount: calculateBrokerageFeeAmount(
      //   parseFloat(updatedPricePerML) || 0
      // ).toFixed(2),
      total: calculateTotal(parseFloat(updatedPricePerML) || 0).toFixed(2),
    });
  };

  // const calculateBrokerageFeeAmount = (price) => {
  //   return (price * waterListing.volumeML * brokerageFeePercentage) / 100;
  // };

  // // Calculate GST based on the entered price
  // const calculateGST = (price) => {
  //   return (
  //     (price * waterListing.volumeML +
  //       (price * waterListing.volumeML * brokerageFeePercentage) / 100) *
  //     GST_PERCENTAGE
  //   );
  // };

  // Calculate total price including GST
  const calculateTotal = (price) => {
    return price * waterListing.volumeML;
  };

  const handleSubmitPlaceBid = async () => {
    if (values.pricePerML === "") {
      toast.error("Enter a bid value.");
      console.log("values: ", values, waterListing._id);
    } else {
      try {
        const data = await placeBid({
          pricePerML: values.pricePerML,
          waterListingId: waterListing._id,
        }); // Adjust parameters as needed

        if (data.error) {
          throw new Error(data.error);
        } else {
          onSuccess();
          toast.success("Bid submitted!");
          onClose();
        }
      } catch (error) {
        console.error("Error submitting bid:", error);
        toast.error(`Failed to submit bid. ${error}`);
      }
    }
  };

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal autoFocus={false} onClose={onClose} className="centeredModal">
            <ModalHeader>
              <ModalTitle>
                <b>Place a Bid</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>
                <form>
                  <div className="form-group">
                    <Field name="pricePerML" label="Price Per ML:">
                      {({ fieldProps }) => (
                        <Textfield
                          elemBeforeInput={
                            <span style={{ paddingLeft: "10px" }}>
                              <FontAwesomeIcon icon={faDollarSign} />
                            </span>
                          }
                          appearance="standard"
                          label="Standard"
                          placeholder="e.g. 35"
                          onChange={handleChange("pricePerML")}
                          required
                          type="number"
                          value={values.pricePerML}
                        />
                      )}
                    </Field>
                  </div>
                </form>
                <br />
                <div className={classes.summaryDarkBackground}>
                  <b>Summary</b>
                  <div>Volume (ML): {waterListing.volumeML} ML</div>
                  {/* <div>
                    Brokerage Fee ({brokerageFeePercentage}%): $
                    {values.brokerageFeeAmount}
                  </div>
                  <div>GST (10%): ${values.gst}</div> */}
                  <b>Total (Excl. Fees and GST): ${values.total}</b>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button appearance="warning" onClick={handleSubmitPlaceBid}>
                Place Bid
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default PlaceBid;

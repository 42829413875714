// src/component/modals/ConfirmActionModal.js
import React from "react";
import ModalDialog, {
  ModalTransition,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
// Custom styling for the modal content

const ConfirmActionModal = ({
  isOpen,
  onClose,
  onConfirm,
  heading,
  message,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalTransition>
      <ModalDialog
        heading={heading}
        onClose={onClose}
        width="medium" // Adjust the width if needed, e.g., 'medium', 'large'
        actions={null} // Disable default actions to use custom buttons
      >
        <ModalHeader>
          <ModalTitle>
            <b>{heading}</b>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button appearance="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button appearance="primary" onClick={onConfirm}>
            Yes, confirm
          </Button>
        </ModalFooter>
      </ModalDialog>
    </ModalTransition>
  );
};

export default ConfirmActionModal;

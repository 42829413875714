import React, { useEffect, useState } from "react";
import Badge from "@atlaskit/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEllipsisH,
  faEye,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@atlaskit/button/standard-button";
import CreateNewNotificationModal from "../../component/modals/notifications/createNewNotificationModal";
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from "@atlaskit/dropdown-menu";
import {
  deletePriceAlert,
  getPriceAlerts,
} from "../../action/priceAlertAction";
import {
  deleteListingAlert,
  getListingAlerts,
} from "../../action/listingAlertAction";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import DynamicTable from "@atlaskit/dynamic-table";
import { toast } from "react-toastify";
import ViewListing from "../../component/modals/listing/viewListing";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ececec",
    padding: "20px",
  },
  actionButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ellipsisButton: {
    border: "none",
    background: "none",
    cursor: "pointer",
    padding: "0px",
    fontSize: "20px",
  },
  dropdownMenu: {
    minWidth: "100px", // Adjust as needed for the dropdown size
  },
}));

const PriceAlerts = () => {
  const { theme } = useTheme();
  const classes = useStyles();

  const [selectedWaterListingId, setSelectedWaterListingId] = useState(null);
  const [showCreateNewNotificationModal, setShowCreateNewNotificationModal] =
    useState(false);
  const [showWaterListingModal, setShowWaterListingModal] = useState(false);
  const [priceAlerts, setPriceAlerts] = useState([]);
  const [isPriceAlertsLoading, setIsPriceAlertsLoading] = useState(false);

  const [listingAlerts, setListingAlerts] = useState([]);
  const [isListingAlertsLoading, setIsListingAlertsLoading] = useState(false);

  const handleViewWaterListing = (listingId) => {
    setSelectedWaterListingId(listingId);
    setShowWaterListingModal(true);
  };

  const closeWaterListingModal = () => {
    setShowWaterListingModal(false);
    setSelectedWaterListingId(null);
  };

  const openCreateModal = () => {
    setShowCreateNewNotificationModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateNewNotificationModal(false);
  };

  const createNewPriceAlertSuccess = () => {
    FetchPriceAlerts();
  };

  useEffect(() => {
    FetchPriceAlerts();
    FetchListingAlerts();
  }, []);

  const formatCurrency = (dollarAmount) => {
    const formatedAmount = dollarAmount.toFixed(2);
    return `$${formatedAmount}`;
  };

  const HandleDeletePriceAlert = async (priceAlertId) => {
    try {
      const response = await deletePriceAlert(priceAlertId);

      if (response.ok) {
        toast.success("Price alert deleted successfully.");
        await FetchPriceAlerts();
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const HandleDeleteListingAlert = async (listingAlertId) => {
    try {
      const response = await deleteListingAlert(listingAlertId);

      if (response.ok) {
        toast.success("Listing alert deleted successfully.");
        await FetchListingAlerts();
      } else {
        toast.error(`${response.error}`);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const FetchPriceAlerts = async () => {
    try {
      const response = await getPriceAlerts();

      if (response.ok) {
        const data = await response.json();
        setPriceAlerts(data);
      }
      setIsPriceAlertsLoading(false);
    } catch (error) {
      console.error("Error fetching price alerts:", error);
      setIsPriceAlertsLoading(false);
    }
  };

  const FetchListingAlerts = async () => {
    try {
      const response = await getListingAlerts();

      if (response.ok) {
        const data = await response.json();
        setListingAlerts(data);
      }
      setIsListingAlertsLoading(false);
    } catch (error) {
      console.error("Error fetching listing alerts:", error);
      setIsListingAlertsLoading(false);
    }
  };

  const renderPriceAlertRows = () => {
    if (!Array.isArray(priceAlerts) || priceAlerts.length === 0) {
      return [
        {
          key: "no-priceAlerts",
          cells: [
            {
              key: "no-priceAlerts-label",
              content: <div>No Price Alerts</div>,
              colSpan: 4,
            },
          ],
        },
      ];
    }

    return priceAlerts.map((priceAlert) => ({
      key: priceAlert.id,
      cells: [
        { key: "region", content: priceAlert.region },
        { key: "direction", content: priceAlert.direction },
        { key: "pricePerML", content: formatCurrency(priceAlert.pricePerML) },
        {
          key: "actions",
          content: (
            <DropdownMenu
              trigger={
                <button className={classes.ellipsisButton}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </button>
              }
              className={classes.dropdownMenu}
            >
              <DropdownItemGroup>
                <DropdownItem
                  iconBefore={<FontAwesomeIcon icon={faEye} />}
                  onClick={() => handleViewWaterListing(priceAlert._id)}
                >
                  View
                </DropdownItem>
                <DropdownItem
                  iconBefore={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => HandleDeletePriceAlert(priceAlert._id)}
                >
                  Delete
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          ),
        },
      ],
    }));
  };

  const renderListingAlertRows = () => {
    if (!Array.isArray(listingAlerts) || listingAlerts.length === 0) {
      return [
        {
          key: "no-listingAlerts",
          cells: [
            {
              key: "no-listingAlerts-label",
              content: <div>No Listing Alerts</div>,
              colSpan: 4,
            },
          ],
        },
      ];
    }

    return listingAlerts.map((listingAlert) => ({
      key: listingAlert.id,
      cells: [
        { key: "description", content: listingAlert.description },
        { key: "alertStatus", content: listingAlert.alertStatus },
        {
          key: "actions",
          content: (
            <DropdownMenu
              trigger={
                <button className={classes.ellipsisButton}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </button>
              }
              className={classes.dropdownMenu}
            >
              <DropdownItemGroup>
                <DropdownItem
                  iconBefore={<FontAwesomeIcon icon={faEye} />}
                  onClick={() =>
                    handleViewWaterListing(listingAlert.waterListingId)
                  }
                >
                  View
                </DropdownItem>
                <DropdownItem
                  iconBefore={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => HandleDeleteListingAlert(listingAlert._id)}
                >
                  Delete
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          ),
        },
      ],
    }));
  };

  const priceAlertTableHead = {
    cells: [
      { key: "region", content: "Region" },
      { key: "direction", content: "Direction" },
      { key: "pricePerML", content: "Price Per ML" },
      { key: "actions", content: "Actions" },
    ],
  };

  const listingAlertTableHead = {
    cells: [
      { key: "description", content: "Listing Description" },
      { key: "alertStatus", content: "Alert Status" },
      { key: "actions", content: "Actions" },
    ],
  };

  return (
    <div className="base-page">
      {selectedWaterListingId && (
        <ViewListing
          isOpen={showWaterListingModal}
          onClose={closeWaterListingModal}
          listingId={selectedWaterListingId}
        />
      )}
      <CreateNewNotificationModal
        isOpen={showCreateNewNotificationModal}
        onClose={closeCreateModal}
        onSuccess={createNewPriceAlertSuccess}
      />
      <div className={classes.actionButtonContainer}>
        <div>
          <div className="center">
            <FontAwesomeIcon style={{ fontSize: "50px" }} icon={faBell} />
          </div>
          <div className="center">
            <h1>Alerts</h1>
          </div>

          <p>Keep up to date with listing and water prices!</p>
          <div className="center">
            <Button
              appearance="primary"
              className="button-primary-1-dark"
              onClick={openCreateModal}
            >
              <FontAwesomeIcon icon={faPlus} />
              {"  "}
              Create New Price Alert
            </Button>
          </div>
        </div>
      </div>
      <br />
      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h5>Price Alerts</h5>
        {isPriceAlertsLoading ? (
          <p>Loading price alerts...</p>
        ) : (
          <DynamicTable
            head={priceAlertTableHead}
            rows={renderPriceAlertRows()}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={false}
          />
        )}
      </div>

      <br />

      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h5>Listing Alerts</h5>
        {isListingAlertsLoading ? (
          <p>Loading listing alerts...</p>
        ) : (
          <DynamicTable
            head={listingAlertTableHead}
            rows={renderListingAlertRows()}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={false}
          />
        )}
      </div>
    </div>
  );
};

export default PriceAlerts;

import React from "react";
import ForgotPasswordModal from "../component/modals/ForgotPasswordModal";

const ForgotPassword = () => {
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "30px" }}
      >
        <div
          style={{
            width: "500px",
            //boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            border: "2px solid rgba(99, 99, 99, 0.2)",
            padding: "50px",
            borderRadius: "20px",
          }}
        >
          <h2 className="text-center pb-4" style={{ fontWeight: "700" }}>
            Forgot Password
          </h2>
          <ForgotPasswordModal />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;

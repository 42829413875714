import React, { useEffect, useState, Fragment } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import Form, { HelperMessage, Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import { API } from "../../../config/config";
import { getAllColleagues } from "../../../action/colleagueAction";
import cookie from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { DatePicker } from "@atlaskit/datetime-picker";
import { markets } from "../../../component/regions/markets";
import { carryOverParkingRegions } from "../../../component/regions/carryOverParkingRegions";
import { forwardAllocationRegions } from "../../../component/regions/forwardAllocationRegions";
import { leaseOfEntitlementRegions } from "../../../component/regions/leaseOfEntitlementRegions";
import { permanentEntitlementRegions } from "../../../component/regions/permanentEntitlementRegions";
import { temporaryAllocationRegions } from "../../../component/regions/temporaryAllocationRegions";
import Checkbox from "@atlaskit/checkbox"; // Import Checkbox

const NewListing = ({ isOpen, onClose }) => {
  const [formValues, setFormValues] = useState({
    listingType: "",
    season: "",
    allocationType: "",
    tradeZone: "",
    volumeML: "",
    pricePerML: "",
    tradeZoneDetails: "",
    deliveryDate: null, // Set today's date as default
    leaseOfEntitlementAmountOfYears: "",
    leaseOfEntitlementStartDate: new Date().toISOString().split("T")[0],
  });
  const [colleagues, setColleagues] = useState([]); // To store fetched colleagues
  const [zoneOptions, setZoneOptions] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // Checkbox state

  const [selectedMarket, setSelectedMarket] = useState(null);

  const updateZoneOptions = (selectedMarket) => {
    switch (selectedMarket) {
      case "Temporary Allocation":
        setZoneOptions(temporaryAllocationRegions);
        break;
      case "Forward Allocation":
        setZoneOptions(forwardAllocationRegions);
        break;
      case "Carry Over Parking":
        setZoneOptions(carryOverParkingRegions);
        break;
      case "Lease of Entitlement":
        setZoneOptions(leaseOfEntitlementRegions);
        break;
      case "Permanent Entitlement":
        setZoneOptions(permanentEntitlementRegions);
        break;
      default:
        setZoneOptions([]);
    }
  };

  const handleRegionChange = (selectedOptions) => {
    // Call handleMultipleFormChanges with an array of name-value pairs
    handleMultipleFormChanges([
      { name: "tradeZone", value: selectedOptions.tradeZone },
      { name: "tradeZoneDetails", value: selectedOptions.tradeZoneDetails },
    ]);
  };

  const handleMultipleFormChanges = (fields) => {
    // Create an object from the array of fields and merge it with the existing form values
    const updatedFields = fields.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    setFormValues({ ...formValues, ...updatedFields });
  };

  const handleFormChange = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    console.log("formValues", formValues);
  };
  useEffect(() => {
    // Fetch colleagues when the modal is opened
    if (isOpen) {
      getAllColleagues()
        .then((colleagues) => {
          // Map colleagues to the format you want (label as name and value as _id)
          const mappedColleagues = colleagues.map((colleague) => ({
            label: colleague.name,
            value: colleague._id,
          }));
          setColleagues(mappedColleagues);
        })
        .catch((error) => console.error("Error fetching colleagues:", error));
    }
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare data with selected colleagues
      const requestData = {
        ...formValues,
      };

      console.log("requestData", requestData);
      console.log("formValues", formValues);

      const response = await fetch(`${API}/water-listing`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success("New water listing created successfully");
        //history.push(`/project/${data._id}`);

        // show success toast with the option to view the listing using a button
        onClose();
      } else {
        const data = await response.json();
        throw new Error(data.error);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
      console.error("Error creating water listing (2):", error);
    }
  };
  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal autoFocus={false} onClose={onClose}>
            <ModalHeader>
              <ModalTitle>
                <h3 style={{ color: "white" }}>New Water Listing</h3>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Form>
                {({ formProps }) => (
                  <form {...formProps} onSubmit={handleSubmit} id="projectForm">
                    <Field name="listingType" label="Listing Type:" isRequired>
                      {({ fieldProps }) => (
                        <Fragment>
                          <Select
                            inputId="listingType"
                            className="single-select"
                            classNamePrefix="react-select"
                            isRequired
                            options={[
                              { label: "Sell Order", value: "Sell Order" },
                              { label: "Buy Order", value: "Buy Order" },
                            ]}
                            placeholder="Select Type"
                            onChange={(selectedOptions) =>
                              handleFormChange(
                                "listingType",
                                selectedOptions.value
                              )
                            }
                          />

                          <HelperMessage>
                            Select whether you would like to Buy or Sell.
                          </HelperMessage>
                        </Fragment>
                      )}
                    </Field>
                    <Field
                      name="allocationType"
                      label="Allocation Type:"
                      isRequired
                    >
                      {({ fieldProps }) => (
                        <Fragment>
                          <Select
                            inputId="allocationType"
                            className="single-select"
                            classNamePrefix="react-select"
                            isRequired
                            options={markets}
                            placeholder="Select Type"
                            onChange={(selectedOptions) => {
                              handleFormChange(
                                "allocationType",
                                selectedOptions.value
                              );
                              setSelectedMarket(selectedOptions.value);
                              updateZoneOptions(selectedOptions.value);
                            }}
                          />
                        </Fragment>
                      )}
                    </Field>
                    <Field name="tradeZone" label="Trade Zone:" isRequired>
                      {({ fieldProps }) => (
                        <Fragment>
                          <Select
                            inputId="listingType"
                            className="single-select"
                            classNamePrefix="react-select"
                            isRequired
                            options={zoneOptions}
                            placeholder="Select Type"
                            onChange={(selectedOptions) =>
                              handleRegionChange(selectedOptions)
                            }
                            menuPortalTarget={document.body} // Ensure the dropdown is rendered in the body
                            menuPosition="fixed" // Fix the menu position
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the dropdown is on top of other elements
                              menu: (base) => ({
                                ...base,
                                zIndex: 9999,
                                position: "absolute", // Set the position to absolute to prevent clipping
                              }),
                              control: (base) => ({
                                ...base,
                                width: 300, // Adjust width as needed
                              }),
                            }}
                          />
                        </Fragment>
                      )}
                    </Field>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "100%", paddingRight: "5px" }}>
                        <Field
                          name="volumeML"
                          label="Total Volume (ML)"
                          isRequired
                        >
                          {({ fieldProps }) => (
                            <Textfield
                              elemAfterInput={
                                <span style={{ paddingRight: "10px" }}>ML</span>
                              }
                              type="number"
                              {...fieldProps}
                              onChange={(e) =>
                                handleFormChange("volumeML", e.target.value)
                              }
                              value={formValues.volumeML}
                            />
                          )}
                        </Field>
                      </div>

                      <div style={{ width: "100%", paddingLeft: "5px" }}>
                        <Field
                          name="pricePerML"
                          label="Price Per ML"
                          isRequired
                        >
                          {({ fieldProps }) => (
                            <Textfield
                              elemBeforeInput={
                                <span style={{ paddingLeft: "10px" }}>
                                  <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                              }
                              type="number"
                              {...fieldProps}
                              onChange={(e) =>
                                handleFormChange("pricePerML", e.target.value)
                              }
                              value={formValues.pricePerML}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    {selectedMarket === "Lease of Entitlement" && (
                      <Fragment>
                        <Field
                          name="leaseOfEntitlementAmountOfYears"
                          label="Lease of Entitlement Amount of Years:"
                          isRequired
                        >
                          {({ fieldProps }) => (
                            <Textfield
                              {...fieldProps}
                              onChange={(e) =>
                                handleFormChange(
                                  "leaseOfEntitlementAmountOfYears",
                                  e.target.value
                                )
                              }
                              value={formValues.leaseOfEntitlementAmountOfYears}
                            />
                          )}
                        </Field>

                        <Field
                          name="leaseOfEntitlementStartDate"
                          label="Lease of Entitlement Start Date:"
                          isRequired
                        >
                          {({ fieldProps }) => (
                            <DatePicker
                              selectProps={{
                                inputId: "lease-entitlement-start-date-picker",
                              }}
                              onChange={(selectedOptions) =>
                                handleFormChange(
                                  "leaseOfEntitlementStartDate",
                                  selectedOptions
                                )
                              }
                              value={formValues.leaseOfEntitlementStartDate}
                            />
                          )}
                        </Field>
                      </Fragment>
                    )}
                    {selectedMarket === "Forward Allocation" && (
                      <Field
                        name="deliveryDate"
                        label="Delivery Date:"
                        isRequired
                      >
                        {({ fieldProps }) => (
                          <DatePicker
                            selectProps={{
                              inputId:
                                "forward-allocation-delivery-date-picker",
                            }}
                            onChange={(selectedOptions) =>
                              handleFormChange("deliveryDate", selectedOptions)
                            }
                            value={formValues.deliveryDate}
                          />
                        )}
                      </Field>
                    )}
                    <br /> <br />
                    {/* <br />
                    <Label htmlFor="multi-select-example">Add Colleagues</Label>

                    <Select
                      inputId="multi-select-example"
                      className="multi-select"
                      classNamePrefix="react-select"
                      options={colleagues} // Use the fetched colleagues
                      menuPosition="fixed"
                      isMulti
                      isSearchable={true}
                      placeholder="Add Colleagues"
                      // Store selected colleagues in formValues.colleagues
                      onChange={(selectedOptions) =>
                        setFormValues({
                          ...formValues,
                          colleagues: selectedOptions,
                        })
                      }
                    /> */}
                    <Field name="agreementCheckbox" isRequired>
                      {({ fieldProps }) => (
                        <Checkbox
                          {...fieldProps}
                          label={
                            <Fragment>
                              By confirming, you affirm that all license holders
                              agree to abide by the{" "}
                              <a
                                style={{ color: "white" }}
                                href="./terms-and-conditions"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                terms and conditions
                              </a>{" "}
                              of the TBWE platform, as well as the applicable{" "}
                              <a
                                style={{ color: "white" }}
                                href="./fees"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                brokerage fees
                              </a>
                              .
                            </Fragment>
                          }
                          isChecked={isCheckboxChecked}
                          onChange={() =>
                            setIsCheckboxChecked(!isCheckboxChecked)
                          }
                        />
                      )}
                    </Field>
                  </form>
                )}
              </Form>
              <span style={{ display: "flex", height: "50px" }}></span>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button
                appearance="primary"
                type="submit"
                form="projectForm"
                autoFocus
                isDisabled={!isCheckboxChecked} // Disable if checkbox is not checked
              >
                Create
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default NewListing;

export const temporaryAllocationRegions = [
  {
    options: [
      {
        label: "All",
        value: "",
        tradeZone: "",
        tradeZoneDetails: "",
      },
    ],
  },
  {
    label: "NSW",
    options: [
      {
        label: "NSW Murrumbridgee_Zone 13 - Private Diverter",
        value: "NSW Murrumbridgee_Zone 13 - Private Diverter",
        tradeZone: "NSW Murrumbridgee_Zone 13",
        tradeZoneDetails: "NSW Murrumbridgee_Zone 13 - Private Diverter",
        state: "NSW",
      },
      {
        label: "NSW Murrumbridgee_Zone 13 - Murrumbidgee Irrigation Area",
        value: "NSW Murrumbridgee_Zone 13 - Murrumbidgee Irrigation Area",
        tradeZone: "NSW Murrumbridgee_Zone 13",
        tradeZoneDetails:
          "NSW Murrumbridgee_Zone 13 - Murrumbidgee Irrigation Area",
        state: "NSW",
      },
      {
        label: "NSW Murrumbridgee_Zone 13 - Coleambally Irrigation",
        value: "NSW Murrumbridgee_Zone 13 - Coleambally Irrigation",
        tradeZone: "NSW Murrumbridgee_Zone 13",
        tradeZoneDetails: "NSW Murrumbridgee_Zone 13 - Coleambally Irrigation",
        state: "NSW",
      },

      {
        label: "NSW Murray (Below Choke)_Zone 11 - Private Diverter",
        value: "NSW Murray (Below Choke)_Zone 11 - Private Diverter",
        tradeZone: "NSW Murray (Below Choke)_Zone 11",
        tradeZoneDetails: "NSW Murray (Below Choke)_Zone 11 - Private Diverter",
        state: "NSW",
      },
      {
        label: "NSW Murray (Below Choke)_Zone 11 - Western Murray Irrigation",
        value: "NSW Murray (Below Choke)_Zone 11 - Western Murray Irrigation",
        tradeZone: "NSW Murray (Below Choke)_Zone 11",
        tradeZoneDetails:
          "NSW Murray (Below Choke)_Zone 11 - Western Murray Irrigation",
        state: "NSW",
      },

      {
        label: "NSW Murray (Above Choke)_Zone 10 - Private Diverter",
        value: "NSW Murray (Above Choke)_Zone 10 - Private Diverter",
        tradeZone: "NSW Murray (Above Choke)_Zone 10",
        tradeZoneDetails: "NSW Murray (Above Choke)_Zone 10 - Private Diverter",
        state: "NSW",
      },
      {
        label: "NSW Murray (Above Choke)_Zone 10 - Western Murray Irrigation",
        value: "NSW Murray (Above Choke)_Zone 10 - Western Murray Irrigation",
        tradeZone: "NSW Murray (Above Choke)_Zone 10",
        tradeZoneDetails:
          "NSW Murray (Above Choke)_Zone 10 - Western Murray Irrigation",
        state: "NSW",
      },
    ],
  },
  {
    label: "VIC",
    options: [
      {
        label: "VIC Murray (Below Choke)_Zone 7",
        value: "VIC Murray (Below Choke)_Zone 7",
        tradeZone: "VIC Murray (Below Choke)_Zone 7",
        tradeZoneDetails: "VIC Murray (Below Choke)_Zone 7",
        state: "VIC",
      },

      {
        label: "VIC Murray (Above Choke)_Zone 6",
        value: "VIC Murray (Above Choke)_Zone 6",
        tradeZone: "VIC Murray (Above Choke)_Zone 6",
        tradeZoneDetails: "VIC Murray (Above Choke)_Zone 6",
        state: "VIC",
      },

      {
        label: "VIC Lower Broken Creek_Zone 6B",
        value: "VIC Lower Broken Creek_Zone 6B",
        tradeZone: "VIC Lower Broken Creek_Zone 6B",
        tradeZoneDetails: "VIC Lower Broken Creek_Zone 6B",
        state: "VIC",
      },

      {
        label: "VIC Loddon_Zone 5A",
        value: "VIC Loddon_Zone 5A",
        tradeZone: "VIC Loddon_Zone 5A",
        tradeZoneDetails: "VIC Loddon_Zone 5A",
        state: "VIC",
      },

      {
        label: "VIC Campaspe_Zone 4",
        value: "VIC Campaspe_Zone 4",
        tradeZone: "VIC Campaspe_Zone 4",
        tradeZoneDetails: "VIC Campaspe_Zone 4",
        state: "VIC",
      },

      {
        label: "VIC Lower Goulburn_Zone 3",
        value: "VIC Lower Goulburn_Zone 3",
        tradeZone: "VIC Lower Goulburn_Zone 3",
        tradeZoneDetails: "VIC Lower Goulburn_Zone 3",
        state: "VIC",
      },

      {
        label: "VIC broker_Zone 2",
        value: "VIC broker_Zone 2",
        tradeZone: "VIC broker_Zone 2",
        tradeZoneDetails: "VIC broker_Zone 2",
        state: "VIC",
      },

      {
        label: "VIC Goulburn_Zone 1A",
        value: "VIC Goulburn_Zone 1A",
        tradeZone: "VIC Goulburn_Zone 1A",
        tradeZoneDetails: "VIC Goulburn_Zone 1A",
        state: "VIC",
      },

      {
        label: "VIC Goulburn_Zone 1B",
        value: "VIC Goulburn_Zone 1B",
        tradeZone: "VIC Goulburn_Zone 1B",
        tradeZoneDetails: "VIC Goulburn_Zone 1B",
        state: "VIC",
      },
    ],
  },
  {
    label: "SA",
    options: [
      {
        label: "SA River Murray_Zone 12 - Private Diverter",
        value: "SA River Murray_Zone 12 - Private Diverter",
        tradeZone: "SA River Murray_Zone 12",
        tradeZoneDetails: "SA River Murray_Zone 12 - Private Diverter",
        state: "SA",
      },
      {
        label: "SA River Murray_Zone 12 - Central Irrigation Trust",
        value: "SA River Murray_Zone 12 - Central Irrigation Trust",
        tradeZone: "SA River Murray_Zone 12",
        tradeZoneDetails: "SA River Murray_Zone 12 - Central Irrigation Trust",
        state: "SA",
      },
      {
        label: "SA River Murray_Zone 12 - Renmark Irrigation Trust",
        value: "SA River Murray_Zone 12 - Renmark Irrigation Trust",
        tradeZone: "SA River Murray_Zone 12",
        tradeZoneDetails: "SA River Murray_Zone 12 - Renmark Irrigation Trust",
        state: "SA",
      },
    ],
  },
];

import React from "react";
import SignUpModal from "../component/modals/signUpModal";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import Typewriter from "typewriter-effect";

const useStyles = makeStyles(() => ({
  leftPaneDark: {
    float: "left",
    // backgroundColor: "#171927",
    backgroundColor: "#101118",
    flexGrow: 1,
    height: "calc(100vh - 52px)",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  rightPaneDark: {
    float: "right",
    height: "calc(100vh - 52px)",
    display: "flex",
    alignItems: "center",
  },
  "@media (max-width: 1000px)": {
    leftPaneDark: {
      display: "none", // Hide the left pane
    },
    rightPaneDark: {
      float: "none", // Remove floating
      justifyContent: "center",
      margin: "auto", // Center the right pane
      width: "100%", // Optional: Adjust width as needed
    },
  },
}));
const SignUp = () => {
  const location = useLocation();
  const classes = useStyles();
  const queryParams = new URLSearchParams(location.search);

  const returnUrl = queryParams.get("returnUrl");

  return (
    <React.Fragment>
      {/* <ToastContainer /> */}

      <div style={{ display: "flex" }}>
        <div className={classes.leftPaneDark}>
          {/* <video
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            src="/videos/DarkBackground.mp4"
          >
            Your browser does not support the video tag.
          </video> */}
          <div style={{ position: "absolute", marginTop: "-40px" }}>
            <div>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <div
                  style={{
                    fontStyle: "italic",
                    fontWeight: "700",
                    fontSize: "3.5rem",
                  }}
                >
                  <img
                    src="../images/TBWE_logo_blue.png"
                    width={400}
                    height={125}
                    alt="True Blue Water Exchange Logo"
                  />
                </div>
              </div>
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontStyle: "Italic", paddingTop: "20px" }}>
                  Move faster with True Blue Water Exchange
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div className={classes.rightPaneDark}>
          <div
            style={{
              width: "500px",
              padding: "10px 50px 50px 50px",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "15px",
              }}
            >
              {/* {" "}
              <img
                src="../images/lockClosed.png"
                width={40}
                height={40}
                alt="SignInIcon"
              /> */}
            </div>

            <h2 className="text-center pb-4" style={{ fontWeight: "600" }}>
              Sign Up
            </h2>
            <SignUpModal />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;

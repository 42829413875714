import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fetchData } from "../../action/infoTileAction";

const useStyles = makeStyles(() => ({
  homepageTile: {
    minHeight: "150px",
    borderRadius: "5px",
    padding: "20px",
    backgroundColor: "#24222B",
  },
  title: {
    fontWeight: 600,
  },
  subTitle: {
    fontWeight: 500,
    opacity: 0.7,
  },
  spacing: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "10px",
  },
}));

const InfoTile = ({ title, subTitle, dataType, market, zone }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const fetchedData = await fetchData(dataType, market, zone);
      setData(fetchedData);
    };

    getData();
  }, [dataType, market, zone]);

  return (
    <div className={classes.homepageTile}>
      <div>
        <h5 className={classes.title}>{title}</h5>
        <h6 className={classes.subTitle}>{subTitle}</h6>
      </div>
      <div className={classes.spacing}>
        <h5 className="gradient-text">{data !== null ? data : "..."}</h5>
      </div>
    </div>
  );
};

export default InfoTile;

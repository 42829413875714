import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

// Fetch all referral payments
export const getReferralPayments = async () => {
  try {
    const response = await fetch(`${API}/referral-payments`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    const data = await response.json();
    return data; // Assuming data is the array of referral payments
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

// Update the status of a referral payment
export const updateReferralPaymentStatus = async (paymentId, status) => {
  try {
    const response = await fetch(
      `${API}/referral-payment/${paymentId}/status`,
      {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
        body: JSON.stringify({ paymentId, status }),
      }
    );
    const data = await response.json();
    return data; // Assuming data is the updated referral payment
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

// Fetch a specific water listing associated with the referral payment
export const getWaterListingByReferral = async (paymentId) => {
  try {
    const response = await fetch(
      `${API}/referral-payment/${paymentId}/water-listing`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );
    const data = await response.json();
    return data; // Assuming data is the water listing information
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

import React, { useEffect } from "react";
import Badge from "@atlaskit/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import CalendarHeatmap from "../../component/charts/calendarHeatmap";
import FinancialChart from "../../component/charts/financialChart"; // Adjust the import path as needed
import AreaSplineChart from "../../component/charts/areaSplineChart"; // Adjust the import path as needed

const PriceHistory = () => {
  useEffect(() => {}, []);

  return (
    <div className="base-page">
      <div>
        <h2>Price History</h2>
        <h5 style={{ opacity: 0.8 }}>
          Explore Historical Price Data and Market Trends for In-Depth Analysis
        </h5>
        <FinancialChart
          height={600}
          width={1000}
          margin={{ left: 0, right: 48, top: 0, bottom: 24 }}
        />
      </div>

      <div></div>
    </div>
  );
};

export default PriceHistory;

import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

// Function to get water listings based on query parameters
export const getWaterListings = async (queryParams = {}) => {
  try {
    // If limit is undefined or -1, remove it from queryParams
    if (queryParams.limit === -1) {
      delete queryParams.limit;
    }

    // Construct query string from queryParams object
    const queryString = new URLSearchParams(queryParams).toString();

    const response = await fetch(`${API}/water-listings?${queryString}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    const data = await response.json();
    return data; // Assuming data is the response with water listings
  } catch (error) {
    console.error(error);
    return { error };
  }
};
// Function to get a single water listing by id
export const getWaterListingById = async (id) => {
  try {
    const response = await fetch(`${API}/water-listing/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const closeWaterListing = async (listingId) => {
  try {
    const response = await fetch(`${API}/water-listing/${listingId}/close`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const getBidHistory = async (id) => {
  try {
    const response = await fetch(`${API}/bid-history/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const buyWaterListing = async (orderData) => {
  try {
    const response = await fetch(`${API}/water-listing/accept`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(orderData),
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const editWaterListing = async (waterListingData) => {
  try {
    const response = await fetch(`${API}/water-listing`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(waterListingData),
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const placeBid = async (bidData) => {
  try {
    const response = await fetch(`${API}/bid`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(bidData),
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const acceptBid = async (bidData) => {
  try {
    const response = await fetch(`${API}/bid-accept`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(bidData),
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const rejectBid = async (bidData) => {
  try {
    const response = await fetch(`${API}/bid-reject`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(bidData),
    });
    const data = await response.json();
    return data; // Assuming data is the single water listing
  } catch (error) {
    console.error(error);
    return { error };
  }
};

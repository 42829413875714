import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import { getWaterListings } from "../../action/waterListingAction";
import { getRegistryTrades } from "../../action/waterTradeAction"; // Import the new function
import EmptyState from "../../component/misc/EmptyState";
import { toast } from "react-toastify";
import Badge from "@atlaskit/badge";
import ViewListing from "../../component/modals/listing/viewListing";
import ViewRegistryTrade from "../../component/modals/listing/viewRegistryTrade"; // Import the new modal
import Pagination from "@atlaskit/pagination"; // Import Atlaskit pagination
import { getReliability } from "../../helpers/marketZoneHelper";
const TradeActivity = ({
  market,
  zone,
  listingStatus,
  listingType,
  title,
  subTitle,
  limit = 10,
  rowsPerPage = 10,
  source = "TBWE",
  zoneDetails,
}) => {
  const [recentWaterListingActivity, setRecentWaterListingActivity] = useState(
    []
  );
  const [selectedWaterListing, setSelectedWaterListing] = useState(null);
  const [showWaterListingModal, setShowWaterListingModal] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState(null); // State for registry trade
  const [showRegistryTradeModal, setShowRegistryTradeModal] = useState(false); // Control Registry modal
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages based on the data

  const fetchWaterListingsRecentActivity = async () => {
    try {
      const query = {
        sortBy: "updatedAt",
        limit: limit === -1 ? undefined : limit,
        page,
      };

      if (listingStatus) {
        query.status = listingStatus;
      }

      if (listingType) {
        query.listingType = listingType;
      }

      if (market) {
        query.allocationType = market;
      }

      if (zone) {
        query.tradeZone = zone;
      }

      let data;
      if (source === "TBWE") {
        query.sortBy = "updatedAt";
        data = await getWaterListings(query);
        setRecentWaterListingActivity(data.listings);
      } else {
        let reliability = getReliability(zoneDetails);
        if (reliability !== null) {
          query.reliability = reliability;
        }
        query.sortBy = "tradeApprovalTimestamp";
        data = await getRegistryTrades(query);
        setRecentWaterListingActivity(data.trades);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      setTotalPages(data.pages || 1);
    } catch (error) {
      toast.error("Failed to fetch water trades");
    }
  };

  useEffect(() => {
    fetchWaterListingsRecentActivity();
  }, [
    zone,
    listingStatus,
    listingType,
    market,
    limit,
    page,
    source,
    zoneDetails,
  ]);

  const handleViewWaterListing = (listingId) => {
    const selectedListing = recentWaterListingActivity.find(
      (listing) => listing._id === listingId
    );
    setSelectedWaterListing(selectedListing);
    setShowWaterListingModal(true);
  };

  const handleViewRegistryTrade = (tradeId) => {
    const selectedTrade = recentWaterListingActivity.find(
      (trade) => trade._id === tradeId
    );
    setSelectedTrade(selectedTrade);
    setShowRegistryTradeModal(true);
  };

  const closeWaterListingModal = () => {
    setShowWaterListingModal(false);
    setSelectedWaterListing(null);
  };

  const closeRegistryTradeModal = () => {
    setShowRegistryTradeModal(false);
    setSelectedTrade(null);
  };

  const renderRecentActivity = () => {
    if (source === "Registry") {
      return recentWaterListingActivity.map((listing) => ({
        key: listing._id,
        // onClick: () => handleViewRegistryTrade(listing._id), // Open listing modal for TBWE source
        cells: [
          {
            key: "tradeApprovalTimestamp",
            content: listing.tradeApprovalTimestamp
              ? new Date(listing.tradeApprovalTimestamp).toLocaleString(
                  "en-AU",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }
                )
              : "N/A",
          },

          { key: "volumeML", content: `${listing.volumeML} ML` },
          {
            key: "pricePerML",
            content: (
              <span style={{ fontWeight: 700 }}>
                ${listing.pricePerML.toFixed(2)}
              </span>
            ),
          },
        ],
      }));
    } else {
      return recentWaterListingActivity.map((listing) => ({
        key: listing._id,
        onClick: () => handleViewWaterListing(listing._id), // Open listing modal for TBWE source
        cells: [
          { key: "listingType", content: listing.listingType },
          {
            key: "status",
            content: (
              <Badge
                appearance={listing.status === "Open" ? "primary" : "added"}
              >
                <span style={{ fontSize: "14px", padding: "4px" }}>
                  {listing.status}
                </span>
              </Badge>
            ),
          },
          {
            key: "volumeML",
            content: `${new Date(listing?.updatedAt).toLocaleDateString()}`,
          },
          { key: "volumeML", content: `${listing.volumeML} ML` },
          {
            key: "pricePerML",
            content: (
              <span style={{ fontWeight: 700 }}>
                ${listing.pricePerML.toFixed(2)}
              </span>
            ),
          },
        ],
      }));
    }
  };

  const handlePageChange = (e, page) => {
    setPage(page);
  };

  const tableHead = () => {
    if (source === "Registry") {
      return {
        cells: [
          { key: "tradeApprovalTimestamp", content: "Date" },
          { key: "volumeML", content: "Volume (ML)" },
          { key: "pricePerML", content: "Price Per ML" },
        ],
      };
    } else {
      return {
        cells: [
          { key: "listingType", content: "Listing Type" },
          { key: "status", content: "Status" },
          { key: "date", content: "Date" },
          { key: "volumeML", content: "Volume (ML)" },
          { key: "pricePerML", content: "Price Per ML" },
        ],
      };
    }
  };

  return (
    <div>
      {selectedWaterListing && source === "TBWE" && (
        <ViewListing
          isOpen={showWaterListingModal}
          onClose={closeWaterListingModal}
          listingId={selectedWaterListing._id}
        />
      )}
      {selectedTrade && source === "Registry" && (
        <ViewRegistryTrade
          isOpen={showRegistryTradeModal}
          onClose={closeRegistryTradeModal}
          tradeId={selectedTrade.waterTradeId}
        />
      )}
      {title && <h5 style={{ fontWeight: 700 }}>{title}</h5>}
      {subTitle && <div style={{ opacity: 0.7 }}>{subTitle}</div>}

      <br />
      <div>
        <DynamicTable
          head={tableHead()}
          rows={renderRecentActivity()}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
          emptyView={<EmptyState message="No recent activity to display." />}
          rowsPerPage={rowsPerPage}
        />
      </div>
      {limit !== -1 && source !== "Registry" && totalPages > 1 && (
        <Pagination
          pages={[...Array(totalPages).keys()].map((i) => i + 1)}
          selectedIndex={page - 1}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default TradeActivity;

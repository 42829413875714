export const permanentEntitlementRegions = [
  {
    options: [
      {
        label: "All",
        value: "",
        tradeZone: "",
        tradeZoneDetails: "",
      },
    ],
  },
  {
    label: "NSW",
    options: [
      {
        label: "NSW Murrumbridgee_Zone 13 - High Security",
        value: "NSW Murrumbridgee_Zone 13 - High Security",
        tradeZone: "NSW Murrumbridgee_Zone 13 - High Security",
        tradeZoneDetails: "NSW Murrumbridgee_Zone 13 - High Security",
        state: "NSW",
      },
      {
        label: "NSW Murrumbridgee_Zone 13 - General Security",
        value: "NSW Murrumbridgee_Zone 13 - General Security",
        tradeZone: "NSW Murrumbridgee_Zone 13 - General Security",
        tradeZoneDetails: "NSW Murrumbridgee_Zone 13 - General Security",
        state: "NSW",
      },
      // {
      //   label: "NSW Murrumbridgee_Zone 13 - Supplementary",
      //   value: "NSW Murrumbridgee_Zone 13 - Supplementary",
      //   tradeZone: "NSW Murrumbridgee_Zone 13 - Supplementary",
      //   tradeZoneDetails: "NSW Murrumbridgee_Zone 13 - Supplementary",
      //   state: "NSW",
      // },

      {
        label: "NSW Murray (Below Choke)_Zone 11 - High Security",
        value: "NSW Murray (Below Choke)_Zone 11 - High Security",
        tradeZone: "NSW Murray (Below Choke)_Zone 11 - High Security",
        tradeZoneDetails: "NSW Murray (Below Choke)_Zone 11 - High Security",
        state: "NSW",
      },

      {
        label: "NSW Murray (Below Choke)_Zone 11 - General Security",
        value: "NSW Murray (Below Choke)_Zone 11 - General Security",
        tradeZone: "NSW Murray (Below Choke)_Zone 11 - General Security",
        tradeZoneDetails: "NSW Murray (Below Choke)_Zone 11 - General Security",
        state: "NSW",
      },
      // {
      //   label: "NSW Murray (Below Choke)_Zone 11 - Supplementary",
      //   value: "NSW Murray (Below Choke)_Zone 11 - Supplementary",
      //   tradeZone: "NSW Murray (Below Choke)_Zone 11 - Supplementary",
      //   tradeZoneDetails: "NSW Murray (Below Choke)_Zone 11 - Supplementary",
      //   state: "NSW",
      // },

      {
        label: "NSW Murray (Above Choke)_Zone 10 - High Security",
        value: "NSW Murray (Above Choke)_Zone 10 - High Security",
        tradeZone: "NSW Murray (Above Choke)_Zone 10 - High Security",
        tradeZoneDetails: "NSW Murray (Above Choke)_Zone 10 - High Security",
        state: "NSW",
      },
      {
        label: "NSW Murray (Above Choke)_Zone 10 - General Security",
        value: "NSW Murray (Above Choke)_Zone 10 - General Security",
        tradeZone: "NSW Murray (Above Choke)_Zone 10 - General Security",
        tradeZoneDetails: "NSW Murray (Above Choke)_Zone 10 - General Security",
        state: "NSW",
      },
      // {
      //   label: "NSW Murray (Above Choke)_Zone 10 - Supplementary",
      //   value: "NSW Murray (Above Choke)_Zone 10 - Supplementary",
      //   tradeZone: "NSW Murray (Above Choke)_Zone 10 - Supplementary",
      //   tradeZoneDetails: "NSW Murray (Above Choke)_Zone 10 - Supplementary",
      //   state: "NSW",
      // },
    ],
  },
  {
    label: "VIC",
    options: [
      {
        label: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        value: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        tradeZone: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        tradeZoneDetails: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        value: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        tradeZone: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        tradeZoneDetails: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        state: "VIC",
      },

      {
        label: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        value: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        tradeZone: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        tradeZoneDetails: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        value: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        tradeZone: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        tradeZoneDetails: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        state: "VIC",
      },

      {
        label: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        value: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        tradeZone: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        tradeZoneDetails: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        value: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        tradeZone: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        tradeZoneDetails: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        state: "VIC",
      },
      {
        label: "VIC Loddon_Zone 5A - High Reliability",
        value: "VIC Loddon_Zone 5A - High Reliability",
        tradeZone: "VIC Loddon_Zone 5A - High Reliability",
        tradeZoneDetails: "VIC Loddon_Zone 5A - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Loddon_Zone 5A - Low Reliability",
        value: "VIC Loddon_Zone 5A - Low Reliability",
        tradeZone: "VIC Loddon_Zone 5A - Low Reliability",
        tradeZoneDetails: "VIC Loddon_Zone 5A - Low Reliability",
        state: "VIC",
      },

      {
        label: "VIC Campaspe_Zone 4 - High Reliability",
        value: "VIC Campaspe_Zone 4 - High Reliability",
        tradeZone: "VIC Campaspe_Zone 4 - High Reliability",
        tradeZoneDetails: "VIC Campaspe_Zone 4 - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Campaspe_Zone 4 - Low Reliability",
        value: "VIC Campaspe_Zone 4 - Low Reliability",
        tradeZone: "VIC Campaspe_Zone 4 - Low Reliability",
        tradeZoneDetails: "VIC Campaspe_Zone 4 - Low Reliability",
        state: "VIC",
      },

      {
        label: "VIC Lower Goulburn_Zone 3 - High Reliability",
        value: "VIC Lower Goulburn_Zone 3 - High Reliability",
        tradeZone: "VIC Lower Goulburn_Zone 3 - High Reliability",
        tradeZoneDetails: "VIC Lower Goulburn_Zone 3 - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Lower Goulburn_Zone 3 - Low Reliability",
        value: "VIC Lower Goulburn_Zone 3 - Low Reliability",
        tradeZone: "VIC Lower Goulburn_Zone 3 - Low Reliability",
        tradeZoneDetails: "VIC Lower Goulburn_Zone 3 - Low Reliability",
        state: "VIC",
      },

      {
        label: "VIC broker_Zone 2 - High Reliability",
        value: "VIC broker_Zone 2 - High Reliability",
        tradeZone: "VIC broker_Zone 2 - High Reliability",
        tradeZoneDetails: "VIC broker_Zone 2 - High Reliability",
        state: "VIC",
      },

      {
        label: "VIC broker_Zone 2 - Low Reliability",
        value: "VIC broker_Zone 2 - Low Reliability",
        tradeZone: "VIC broker_Zone 2 - Low Reliability",
        tradeZoneDetails: "VIC broker_Zone 2 - Low Reliability",
        state: "VIC",
      },
      {
        label: "VIC Goulburn_Zone 1A - High Reliability",
        value: "VIC Goulburn_Zone 1A - High Reliability",
        tradeZone: "VIC Goulburn_Zone 1A - High Reliability",
        tradeZoneDetails: "VIC Goulburn_Zone 1A - High Reliability",
        state: "VIC",
      },

      {
        label: "VIC Goulburn_Zone 1A - Low Reliability",
        value: "VIC Goulburn_Zone 1A - Low Reliability",
        tradeZone: "VIC Goulburn_Zone 1A - Low Reliability",
        tradeZoneDetails: "VIC Goulburn_Zone 1A - Low Reliability",
        state: "VIC",
      },
      {
        label: "VIC Goulburn_Zone 1B - High Reliability",
        value: "VIC Goulburn_Zone 1B - High Reliability",
        tradeZone: "VIC Goulburn_Zone 1B - High Reliability",
        tradeZoneDetails: "VIC Goulburn_Zone 1B - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Goulburn_Zone 1B - Low Reliability",
        value: "VIC Goulburn_Zone 1B - Low Reliability",
        tradeZone: "VIC Goulburn_Zone 1B - Low Reliability",
        tradeZoneDetails: "VIC Goulburn_Zone 1B - Low Reliability",
        state: "VIC",
      },
    ],
  },
  {
    label: "SA",
    options: [
      {
        label: "SA River Murray_Zone 12 - Class 3A",
        value: "SA River Murray_Zone 12 - Class 3A",
        tradeZone: "SA River Murray_Zone 12 - Class 3A",
        tradeZoneDetails: "SA River Murray_Zone 12 - Class 3A",
        state: "SA",
      },
      {
        label: "SA River Murray_Zone 12 - Class 1",
        value: "SA River Murray_Zone 12 - Class 1",
        tradeZone: "SA River Murray_Zone 12 - Class 1",
        tradeZoneDetails: "SA River Murray_Zone 12 - Class 1",
        state: "SA",
      },
    ],
  },
];

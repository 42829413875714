import React, { useEffect } from "react";
import AreaSplineChart from "../../component/charts/areaSplineChart";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    backgroundColor: "#24222b",
    padding: "20px",
    borderRadius: "5px",
  },
}));

const AreaSplineChartTile = ({ market, zone }) => {
  const classes = useStyles();

  useEffect(() => {
    // This effect runs whenever the market or zone changes
    // You can add any additional logic here if needed
    console.log("areaSplineChartTile.js: Market:", market, zone);
  }, [market, zone]);

  return (
    <div className={classes.homepageTileDark}>
      <AreaSplineChart
        backgroundColor={"#23222B"}
        market={market}
        zone={zone}
      />
    </div>
  );
};

export default AreaSplineChartTile;

import { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../config/config";
import cookie from "js-cookie";

export const useMarketData = (filters) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API}/water-history/financial-data`,
          { params: filters }
        );
        const formattedData = response.data.map((d) => ({
          date: new Date(d.date),
          open: d.open,
          high: d.high,
          low: d.low,
          close: d.close,
          volume: d.volume,
          tradeFromZone: d.tradeFromZone,
          tradeToZone: d.tradeToZone,
          tradeType: d.tradeType,
        }));
        setData(formattedData);
        setLoaded(true);
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };

    fetchData();
  }, [filters]);

  return { data, loaded };
};
export const getRegistryTrades = async (queryParams = {}) => {
  try {
    // Construct the query string from queryParams object
    const queryString = new URLSearchParams(queryParams).toString();

    // Log the query string for debugging purposes
    console.log("Constructed Query String:", queryString);

    const response = await fetch(
      `${API}/water-history/water-trades?${queryString}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );

    // Ensure the response is in the expected format
    const data = await response.json();
    return data; // Assuming data is the response with water trades
  } catch (error) {
    console.error("Error in getRegistryTrades:", error);
    return { error };
  }
};

export const getRecentFinancialData = async ({ market, zone }) => {
  try {
    const query = {
      ...(market && { allocationType: market }),
      ...(zone && { tradeZone: zone }), // Map zone to tradeZone
    };

    const queryString = new URLSearchParams(query).toString();
    const response = await fetch(
      `${API}/water-history/recent-financial-data?${queryString}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );

    const data = await response.json();
    return data; // Return the most recent trade data
  } catch (error) {
    console.error("Error in getRecentFinancialData:", error);
    return { error };
  }
};

export const getWaterTradeById = async (id) => {
  try {
    const response = await fetch(`${API}/water-trade/${id}`, {
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../theme/ThemeContext";

const useStyles = makeStyles(() => ({
  basePage: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  feeSection: {
    marginTop: "20px",
  },
  header: {
    textAlign: "center",
  },
  headerDark: {
    color: "#ddd", // Or any color suitable for dark theme
  },
  headerLight: {
    color: "#333", // Or any color suitable for light theme
  },
  feeSection: {
    marginTop: "20px",
  },
  feeCategory: {
    padding: "15px",
    marginBottom: "20px",
    borderRadius: "5px",
  },
  feeCategoryDark: {
    backgroundColor: "#24222B", // Dark theme background for fee category
  },
  feeCategoryLight: {
    backgroundColor: "#f4f4f4", // Light theme background for fee category
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  note: {
    fontSize: "0.9em",
  },
  noteDark: {
    color: "#aaa", // Dark theme text color for notes
  },
  noteLight: {
    color: "#666", // Light theme text color for notes
  },
  tableRow: {},
  tableRowDark: {
    //backgroundColor: "#101118", // Dark theme table row
    border: "1px solid dimgrey",
  },
  tableRowLight: {
    // backgroundColor: "#fff", // Light theme table row
  },
  td: { border: "none" },
}));

const Fees = () => {
  const history = useHistory();
  const { theme } = useTheme();
  const classes = useStyles();
  return (
    <div className="base-page">
      <h1
        className={`${classes.header} ${
          theme === "dark" ? classes.headerDark : classes.headerLight
        }`}
      >
        Fee Schedule
      </h1>
      <p>TRUE BLUE WATER EXCHANGE 2023/24</p>

      {/* Permanent Entitlement */}
      <section className={classes.feeSection}>
        <h2>Permanent Entitlement</h2>
        {/* SELLER */}
        <div
          className={`${classes.feeCategory} ${
            theme === "dark"
              ? classes.feeCategoryDark
              : classes.feeCategoryLight
          }`}
        >
          <h3>SELLER</h3>
          <table className={classes.table}>
            <tbody>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>Up to $500,000</td>
                <td>2.0%*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>$500,001 to $1,000,000</td>
                <td>1.5%*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>{"> $1,000,001"}</td>
                <td>1.0%*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td colSpan="2">Minimum Commission: $500.00*</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* BUYER */}
        <div
          className={`${classes.feeCategory} ${
            theme === "dark"
              ? classes.feeCategoryDark
              : classes.feeCategoryLight
          }`}
        >
          <h3>BUYER</h3>
          <table className={classes.table}>
            <tbody>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>Up to $200,000</td>
                <td>2.0%*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>{"> $200,001"}</td>
                <td>1.0%*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td colSpan="2">Minimum Commission: $500.00*</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* Temporary Allocation */}
      <section className={classes.feeSection}>
        <h2>Temporary Allocation</h2>
        {/* SELLER */}
        <div
          className={`${classes.feeCategory} ${
            theme === "dark"
              ? classes.feeCategoryDark
              : classes.feeCategoryLight
          }`}
        >
          <h3>SELLER</h3>
          <table className={classes.table}>
            <tbody>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>Seller Fee</td>
                <td>2.0%*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td colSpan="2">Minimum Charge: $100.00^</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* BUYER */}
        <div
          className={`${classes.feeCategory} ${
            theme === "dark"
              ? classes.feeCategoryDark
              : classes.feeCategoryLight
          }`}
        >
          <h3>BUYER</h3>
          <table className={classes.table}>
            <tbody>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>Buyer Fee</td>
                <td>2.0%*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td colSpan="2">Minimum Charge: $100.00*^</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* Lease of Entitlement */}
      <section className={classes.feeSection}>
        <h2>Lease of Entitlement</h2>
        {/* LESSOR */}
        <div
          className={`${classes.feeCategory} ${
            theme === "dark"
              ? classes.feeCategoryDark
              : classes.feeCategoryLight
          }`}
        >
          <h3>LESSOR</h3>
          <table className={classes.table}>
            <tbody>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>Fee</td>
                <td>2.0%*</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* LESSEE */}
        <div
          className={`${classes.feeCategory} ${
            theme === "dark"
              ? classes.feeCategoryDark
              : classes.feeCategoryLight
          }`}
        >
          <h3>LESSEE</h3>
          <table className={classes.table}>
            <tbody>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>Fee</td>
                <td>2.0%*</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* Carryover */}
      <section className={classes.feeSection}>
        <h2>Carryover</h2>
        {/* OWNER */}
        <div
          className={`${classes.feeCategory} ${
            theme === "dark"
              ? classes.feeCategoryDark
              : classes.feeCategoryLight
          }`}
        >
          <h3>OWNER</h3>
          <table className={classes.table}>
            <tbody>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td>Fee</td>
                <td>$2.50 per ML*</td>
              </tr>
              <tr
                className={
                  theme === "dark"
                    ? classes.tableRowDark
                    : classes.tableRowLight
                }
              >
                <td colSpan="2">Minimum Charge: $100.00*</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default Fees;

import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { isAuth } from "../../action/authAction";

const Protected = ({ history }) => {
  const location = useLocation();
  const route = `${location.pathname}${location.search}`; // Include both pathname and search params
  const [redirected, setRedirected] = useState(false); // Track if already redirected

  useEffect(() => {
    if (!isAuth() && !redirected) {
      // Allow access to routes without being signed in
      switch (true) {
        case route.includes("/ForgotPassword"):
        case route.includes("/resetpassword"):
        case route.includes("/about"):
        case route.includes("/terms-and-conditions"):
        case route.includes("/fees"):
        case route.includes("/signup"):
          console.log("/signup detected in Protected.js");
          setRedirected(true); // Prevent further redirection
          history.push(route);
          break;
        default:
          const currentReturnUrl = new URLSearchParams(location.search).get(
            "returnUrl"
          );
          if (!currentReturnUrl || !route.includes(currentReturnUrl)) {
            const returnUrl = encodeURIComponent(route);
            setRedirected(true); // Prevent further redirection
            history.push(`/signin?returnUrl=${returnUrl}`);
          } else {
            setRedirected(true); // Prevent further redirection
            history.push(`/signin`);
          }
          break;
      }
    }
  }, [history, route, location.search, redirected]);

  return <div></div>;
};

export default withRouter(Protected);

import React, { useState, Fragment } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Form, { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import { signup } from "../../../action/authAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateUserModal = ({ isOpen, onClose, onUserCreated }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
    brokerageFeePercentage: "",
  });

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await signup(formValues);
      if (response && !response.error) {
        toast.success("User created successfully");
        onClose();
        onUserCreated(); // Refresh the users list
      } else {
        throw new Error(response.error || "Error creating user");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error("Failed to create user");
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <h3 style={{ color: "white" }}>Create New User</h3>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Form>
              {({ formProps }) => (
                <form
                  {...formProps}
                  onSubmit={handleSubmit}
                  id="createUserForm"
                >
                  <Field name="name" label="Name:" isRequired>
                    {({ fieldProps }) => (
                      <Textfield
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange("name", e.target.value)
                        }
                        value={formValues.name}
                      />
                    )}
                  </Field>
                  <Field name="email" label="Email:" isRequired>
                    {({ fieldProps }) => (
                      <Textfield
                        type="email"
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange("email", e.target.value)
                        }
                        value={formValues.email}
                      />
                    )}
                  </Field>
                  <Field name="password" label="Password:" isRequired>
                    {({ fieldProps }) => (
                      <Textfield
                        type="password"
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange("password", e.target.value)
                        }
                        value={formValues.password}
                      />
                    )}
                  </Field>
                  <Field
                    name="brokerageFeePercentage"
                    label="Brokerage Fee Percentage:"
                    isRequired
                  >
                    {({ fieldProps }) => (
                      <Textfield
                        type="number"
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange(
                            "brokerageFeePercentage",
                            e.target.value
                          )
                        }
                        value={formValues.brokerageFeePercentage}
                      />
                    )}
                  </Field>
                </form>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button
              appearance="primary"
              type="submit"
              form="createUserForm"
              autoFocus
            >
              Create
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default CreateUserModal;

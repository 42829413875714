import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import { toast } from "react-toastify";
import {
  getWaterListings,
  closeWaterListing,
} from "../../action/waterListingAction";
import ViewListing from "../../component/modals/listing/viewListing";
import ConfirmActionModal from "../../component/modals/ConfirmActionModal";
import Pagination from "@atlaskit/pagination";
import EmptyState from "../../component/misc/EmptyState";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import { PopupSelect } from "@atlaskit/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const ListingsPage = () => {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedListing, setSelectedListing] = useState(null);
  const [isViewListingModalOpen, setIsViewListingModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [listingToClose, setListingToClose] = useState(null);
  const [openPage, setOpenPage] = useState(1);
  const [closedPage, setClosedPage] = useState(1);
  const [allPage, setAllPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    fetchListings();
  }, []);

  const fetchListings = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const query = {
        ownerId: user._id,
        limit: "-1",
        sortBy: "createdAt",
        sortOrder: "desc",
      };
      const data = await getWaterListings(query);
      if (data.error) {
        throw new Error(data.error);
      }
      setListings(data.listings);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch water listings");
      setIsLoading(false);
    }
  };

  const handleViewListingClick = (listingId) => {
    const selected = listings.find((listing) => listing._id === listingId);
    setSelectedListing(selected);
    setIsViewListingModalOpen(true);
  };

  const closeViewListingModal = () => {
    setIsViewListingModalOpen(false);
    setSelectedListing(null);
  };

  const handleOpenCloseModal = (listing) => {
    setListingToClose(listing);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmCloseListing = async () => {
    try {
      const response = await closeWaterListing(listingToClose._id);
      if (response.error) {
        throw new Error(response.error);
      }
      toast.success("Listing closed successfully.");
      fetchListings();
      setIsConfirmModalOpen(false);
    } catch (error) {
      toast.error("Failed to close listing.");
    }
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setListingToClose(null);
  };

  const paginateListings = (listings, currentPage) => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return listings.slice(startIndex, endIndex);
  };

  const renderRows = (listings) => {
    if (!Array.isArray(listings) || listings.length === 0) {
      return [
        {
          key: "no-listings",
          cells: [
            {
              key: "no-listings-label",
              content: <div>No listings available</div>,
              colSpan: 5,
            },
          ],
        },
      ];
    }

    return listings.map((listing) => ({
      key: listing._id,
      cells: [
        { key: "listingType", content: listing.listingType },
        { key: "status", content: listing.status },
        { key: "volumeML", content: `${listing.volumeML} ML` },
        { key: "pricePerML", content: `$${listing.pricePerML.toFixed(2)}` },
        {
          key: "actions",
          content: (
            <PopupSelect
              options={[
                {
                  label: (
                    <span>
                      <FontAwesomeIcon icon={faEye} /> View Listing
                    </span>
                  ),
                  value: "view",
                },
                listing.status === "Open" && {
                  label: (
                    <span>
                      <FontAwesomeIcon icon={faTimesCircle} /> Close Listing
                    </span>
                  ),
                  value: "close",
                },
              ].filter(Boolean)} // Filter out any falsy items like undefined
              onChange={(option) => {
                if (option.value === "view") {
                  handleViewListingClick(listing._id);
                } else if (option.value === "close") {
                  handleOpenCloseModal(listing);
                }
              }}
              target={({ isOpen, ...triggerProps }) => (
                <Button {...triggerProps}>...</Button>
              )}
            />
          ),
        },
      ],
    }));
  };

  // Filters for the tabs
  const openListings = listings.filter((listing) => listing.status === "Open");
  const closedListings = listings.filter(
    (listing) =>
      listing.status === "Closed" ||
      listing.status === "Accepted" ||
      listing.status === "Completed"
  );

  // Duplicate listings for "All"
  const allListings = [...listings];

  const tableHead = {
    cells: [
      { key: "listingType", content: "Listing Type" },
      { key: "status", content: "Status" },
      { key: "volumeML", content: "Volume (ML)" },
      { key: "pricePerML", content: "Price Per ML" },
      { key: "actions", content: "Actions" },
    ],
  };

  // Calculate the total pages based on filtered data
  const totalOpenPages = Math.ceil(openListings.length / rowsPerPage);
  const totalClosedPages = Math.ceil(closedListings.length / rowsPerPage);
  const totalAllPages = Math.ceil(allListings.length / rowsPerPage);

  const handlePageChange = (newPage, tab) => {
    if (tab === "open") setOpenPage(newPage);
    if (tab === "closed") setClosedPage(newPage);
    if (tab === "all") setAllPage(newPage);
  };

  return (
    <div className="base-page">
      <h1 style={{ fontWeight: "700" }}>My Listings</h1>

      <Tabs id="listing-tabs">
        <TabList>
          <Tab>Open</Tab>
          <Tab>Closed</Tab>
          <Tab>All</Tab>
        </TabList>

        <TabPanel>
          {isLoading ? (
            <p>Loading listings...</p>
          ) : openListings.length ? (
            <div style={{ width: "100%", padding: "20px", overflowX: "auto" }}>
              <DynamicTable
                head={tableHead}
                rows={renderRows(paginateListings(openListings, openPage))}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={rowsPerPage}
                emptyView={<EmptyState message="No open listings available." />}
              />
              {totalOpenPages > 1 && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    pages={[...Array(totalOpenPages).keys()].map((i) => i + 1)}
                    selectedIndex={openPage - 1}
                    onChange={(e, page) => handlePageChange(page, "open")}
                  />
                </div>
              )}
            </div>
          ) : (
            <p>No open listings available.</p>
          )}
        </TabPanel>

        <TabPanel>
          {isLoading ? (
            <p>Loading listings...</p>
          ) : closedListings.length ? (
            <div style={{ width: "100%", padding: "20px", overflowX: "auto" }}>
              <DynamicTable
                head={tableHead}
                rows={renderRows(paginateListings(closedListings, closedPage))}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={rowsPerPage}
                emptyView={
                  <EmptyState message="No closed listings available." />
                }
              />
              {totalClosedPages > 1 && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    pages={[...Array(totalClosedPages).keys()].map(
                      (i) => i + 1
                    )}
                    selectedIndex={closedPage - 1}
                    onChange={(e, page) => handlePageChange(page, "closed")}
                  />
                </div>
              )}
            </div>
          ) : (
            <p>No closed listings available.</p>
          )}
        </TabPanel>

        <TabPanel>
          {isLoading ? (
            <p>Loading listings...</p>
          ) : allListings.length ? (
            <div style={{ width: "100%", padding: "20px" }}>
              <DynamicTable
                head={tableHead}
                rows={renderRows(paginateListings(allListings, allPage))}
                loadingSpinnerSize="large"
                isLoading={false}
                rowsPerPage={rowsPerPage}
                emptyView={<EmptyState message="No listings available." />}
              />
              {totalAllPages > 1 && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    pages={[...Array(totalAllPages).keys()].map((i) => i + 1)}
                    selectedIndex={allPage - 1}
                    onChange={(e, page) => handlePageChange(page, "all")}
                  />
                </div>
              )}
            </div>
          ) : (
            <p>No listings available.</p>
          )}
        </TabPanel>
      </Tabs>

      {selectedListing && (
        <ViewListing
          isOpen={isViewListingModalOpen}
          onClose={closeViewListingModal}
          listingId={selectedListing._id}
        />
      )}

      <ConfirmActionModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={handleConfirmCloseListing}
        heading="Close Listing"
        message="Are you sure you want to close this listing?"
      />
    </div>
  );
};

export default ListingsPage;

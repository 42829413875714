import React, { useState, useEffect } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { isAuth, signout } from "../../action/authAction";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useTheme } from "../../theme/ThemeContext";
import Button from "@atlaskit/button";
import SignOutIcon from "@atlaskit/icon/glyph/sign-out";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faBars,
  faTimes,
  faUserPlus,
  faDroplet,
  faArrowUpFromGroundWater,
  faTruckDroplet,
  faTarpDroplet,
  faAnglesRight,
  faTags,
  faChartLine,
  faUserTag,
  faBell,
  faScroll,
  faCreditCard,
  faUsers,
  faPeopleGroup,
  faPlus,
  faFire,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons"; // Same icons as side navbar
import { makeStyles } from "@material-ui/core/styles";
import VidRaisedHandIcon from "@atlaskit/icon/glyph/vid-raised-hand";
import HomeIcon from "@atlaskit/icon/glyph/home";
import NewListing from "../modals/listing/newListing"; // Ensure the correct path for NewListing modal

const useStyles = makeStyles(() => ({
  mobileNavBarButton: {
    padding: "20px !important",
    backgroundColor: "#23252e",
    border: "1px solid black",
    color: "#fff", // Ensure text color is white
  },
  mobileNavContainer: {
    maxHeight: "60vh", // Set the maximum height to 60% of the viewport height
    overflowY: "auto", // Enable vertical scrolling
  },
}));

const Header = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const toggle = () => setIsOpen(!isOpen); // Toggles the mobile dropdown state
  const { theme } = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const [isNewListingModalOpen, setNewListingModalOpen] = useState(false); // Manage modal state

  // Check if the window width is small (mobile)
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Open New Listing Modal
  const openNewListingModal = () => {
    setIsOpen(false);
    setNewListingModalOpen(true);
  };

  // Close New Listing Modal
  const closeNewListingModal = () => {
    setNewListingModalOpen(false);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle closing the dropdown when a link is clicked
  const handleNavItemClick = () => {
    if (isMobile && isOpen) {
      toggle();
    }
  };

  const renderSideNavItemsInTopNav = () => (
    <>
      {/* Admin Dashboard and other admin items */}
      {isAuth() && isAuth().role === 99 && (
        <>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/admin"
              onClick={handleNavItemClick}
            >
              <NavLink
                className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
              >
                <VidRaisedHandIcon padding="10px" label="" />
                Admin Dashboard
              </NavLink>
            </Link>
          </NavItem>

          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/referrals"
              onClick={handleNavItemClick}
            >
              <NavLink
                className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
              >
                <FontAwesomeIcon
                  style={{ paddingRight: "10px" }}
                  icon={faPeopleGroup}
                />
                Referrals
              </NavLink>
            </Link>
          </NavItem>

          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/users"
              onClick={handleNavItemClick}
            >
              <NavLink
                className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
              >
                <FontAwesomeIcon
                  style={{ paddingRight: "10px" }}
                  icon={faUsers}
                />
                Users
              </NavLink>
            </Link>
          </NavItem>

          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/manage-water-licenses"
              onClick={handleNavItemClick}
            >
              <NavLink
                className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
              >
                <FontAwesomeIcon
                  style={{ paddingRight: "10px" }}
                  icon={faScroll}
                />
                Manage Water Licenses
              </NavLink>
            </Link>
          </NavItem>
        </>
      )}

      {/* Create Water Listing */}
      <NewListing
        isOpen={isNewListingModalOpen}
        onClose={closeNewListingModal}
      />

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/dashboard"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <HomeIcon label="" />
            <span style={{ paddingLeft: "10px" }}>Dashboard</span>
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <NavLink
          className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          onClick={openNewListingModal} // Opens the modal on click
        >
          <FontAwesomeIcon style={{ paddingRight: "10px" }} icon={faPlus} />
          Create Water Listing
        </NavLink>
      </NavItem>

      {/* Side nav items as top nav items for mobile */}
      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/user/listings"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faUserTag}
            />
            My Water Listings
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/history"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faChartLine}
            />
            Price History
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/heat-map"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon style={{ paddingRight: "10px" }} icon={faFire} />
            Trade Heat Map
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/price-alerts"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon style={{ paddingRight: "10px" }} icon={faBell} />
            Alerts
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/water-licenses"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon style={{ paddingRight: "10px" }} icon={faScroll} />
            Water Licenses
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/orders/all"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faCreditCard}
            />
            Orders
          </NavLink>
        </Link>
      </NavItem>

      {/* Water Trade Market items */}
      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to={`/dashboard?market=${encodeURIComponent("Temporary Allocation")}`}
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faDroplet}
            />
            Temporary Allocation
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to={`/dashboard?market=${encodeURIComponent(
            "Permanent Entitlement"
          )}`}
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faArrowUpFromGroundWater}
            />
            Permanent Entitlement
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to={`/dashboard?market=${encodeURIComponent("Carry Over Parking")}`}
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faTruckDroplet}
            />
            Carry Over Parking
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to={`/dashboard?market=${encodeURIComponent("Lease of Entitlement")}`}
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faTarpDroplet}
            />
            Lease of Entitlement
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to={`/dashboard?market=${encodeURIComponent("Forward Allocation")}`}
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon
              style={{ paddingRight: "10px" }}
              icon={faAnglesRight}
            />
            Forward Allocation
          </NavLink>
        </Link>
      </NavItem>

      <NavItem>
        <Link
          style={{ textDecoration: "none" }}
          to="/water-trading/bids"
          onClick={handleNavItemClick}
        >
          <NavLink
            className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
          >
            <FontAwesomeIcon style={{ paddingRight: "10px" }} icon={faTags} />
            Bids
          </NavLink>
        </Link>
      </NavItem>
    </>
  );

  const renderNavItems = () => (
    <>
      {!isAuth() && isMobile === false && (
        <>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/Contact"
              onClick={handleNavItemClick}
            >
              <NavLink className={`header-navlink-${theme}`}>Contact</NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/About"
              onClick={handleNavItemClick}
            >
              <NavLink className={`header-navlink-${theme}`}>About Us</NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/signup"
              onClick={handleNavItemClick}
            >
              <NavLink className={`header-navlink-${theme}`}>Sign Up</NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/signin"
              onClick={handleNavItemClick}
            >
              <button className="btn btn-primary">Sign In</button>
            </Link>
          </NavItem>
        </>
      )}

      {!isAuth() && isMobile === true && (
        <>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/signup"
              onClick={handleNavItemClick}
            >
              <NavLink
                className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
              >
                <FontAwesomeIcon
                  style={{ paddingRight: "10px" }}
                  icon={faUserPlus}
                />
                Sign Up
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/signin"
              onClick={handleNavItemClick}
            >
              <NavLink
                className={`header-navlink-${theme} ${classes.mobileNavBarButton}`}
              >
                <FontAwesomeIcon
                  style={{ paddingRight: "10px" }}
                  icon={faArrowRightToBracket}
                />
                Sign In
              </NavLink>
            </Link>
          </NavItem>
        </>
      )}

      {isAuth() && isMobile === false && (
        <>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/Contact"
              onClick={handleNavItemClick}
            >
              <NavLink className={`header-navlink-${theme}`}>Contact</NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/About"
              onClick={handleNavItemClick}
            >
              <NavLink className={`header-navlink-${theme}`}>About Us</NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              style={{ textDecoration: "none" }}
              to="/dashboard"
              onClick={handleNavItemClick}
            >
              <NavLink className={`header-navlink-${theme}`}>
                {isAuth().name}'s Dashboard
              </NavLink>
            </Link>
          </NavItem>
          <DropdownMenu
            trigger={({ triggerRef, ...props }) => (
              <Button {...props} ref={triggerRef} appearance="subtle">
                <img
                  style={{ borderRadius: "50%" }}
                  src="/images/user.png"
                  width={28}
                  alt="User"
                />
              </Button>
            )}
          >
            <DropdownItemGroup>
              <DropdownItem
                iconBefore={<SettingsIcon />}
                onClick={() => history.push("/settings")}
              >
                Settings
              </DropdownItem>
              <DropdownItem
                iconBefore={<SignOutIcon />}
                onClick={() => signout(() => history.push("/signin"))}
              >
                Sign Out
              </DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        </>
      )}

      {/* Add side navigation items in the top navbar when mobile and logged in */}
      {isAuth() && isMobile && (
        <div className={classes.mobileNavContainer}>
          {renderSideNavItemsInTopNav()}
        </div>
      )}
    </>
  );

  const renderDefaultHeader = () => (
    <div>
      <ToastContainer />
      <Navbar
        color=""
        expand="md"
        className={`header-navbar-${theme}`}
        style={{
          padding: "5px",
          position: "fixed",
          width: "100%",
          zIndex: 100,
          //backgroundColor: "#24222B", // Transparent in fullscreen, white in mobile
        }}
      >
        <Link style={{ textDecoration: "none", display: "flex" }} to="/">
          <img
            src="../images/tbLogo.png"
            width={40}
            height={40}
            alt="True Blue Water Exchange Logo"
          />
          <NavbarBrand className={`header-navbar-brand-${theme}`}>
            True Blue Water Exchange
          </NavbarBrand>
        </Link>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon={isOpen && isMobile ? faTimes : faBars} />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar style={{ marginLeft: "auto" }}>
            {renderNavItems()}
          </Nav>
        </Collapse>
      </Navbar>
      <div style={{ height: "50px" }} />
    </div>
  );

  const renderHomepageHeader = () => (
    <div style={{ position: "fixed", top: 0, zIndex: 100 }}>
      <Navbar
        color=""
        expand="md"
        className={`header-navbar-transparent`} // Transparent when fullscreen, solid color when mobile
        style={{
          padding: "5px",
          position: "fixed",
          width: "100%",
          zIndex: 100,
          backgroundColor: isMobile ? "#101118" : "transparent", // Transparent in fullscreen, white in mobile
        }}
      >
        <Link style={{ textDecoration: "none", display: "flex" }} to="/">
          <img
            src="../images/tbLogo.png"
            width={40}
            height={40}
            alt="True Blue Water Exchange Logo"
          />
          <NavbarBrand className={`header-navbar-brand-${theme}`}>
            True Blue Water Exchange
          </NavbarBrand>
        </Link>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon={isOpen && isMobile ? faTimes : faBars} />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar style={{ marginLeft: "auto" }}>
            {renderNavItems()}
          </Nav>
        </Collapse>
      </Navbar>
      <div style={{ height: "50px" }} />
    </div>
  );

  return (
    <div>
      {location.pathname === "/"
        ? renderHomepageHeader()
        : renderDefaultHeader()}
    </div>
  );
};

export default withRouter(Header);

import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Form, { HelperMessage, Field } from "@atlaskit/form";
import { postPriceAlert } from "../../../action/priceAlertAction";
import Toggle from "@atlaskit/toggle";
import { toast } from "react-toastify";
import Flag from "@atlaskit/flag";
// Import regions based on allocation type
import { allRegions } from "../../../component/regions/allRegions";
import { temporaryAllocationRegions } from "../../../component/regions/temporaryAllocationRegions";
import { forwardAllocationRegions } from "../../../component/regions/forwardAllocationRegions";
import { carryOverParkingRegions } from "../../../component/regions/carryOverParkingRegions";
import { leaseOfEntitlementRegions } from "../../../component/regions/leaseOfEntitlementRegions";
import { permanentEntitlementRegions } from "../../../component/regions/permanentEntitlementRegions";
import { getUserPhoneNumber } from "../../../action/userAction"; // Import the function

const CreateNewNotificationModal = ({ isOpen, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    region: null,
    allocationType: null,
    direction: null,
    pricePerML: "",
    emailNotification: false,
    textMessageNotification: false,
  });
  const [zoneOptions, setZoneOptions] = useState([]); // State to store zone options
  const [showFlag, setShowFlag] = useState(false); // State to show the Atlaskit flag

  // Update zone options based on allocation type
  const updateZoneOptions = (allocationType) => {
    switch (allocationType) {
      case "Temporary Allocation":
        setZoneOptions(temporaryAllocationRegions);
        break;
      case "Forward Allocation":
        setZoneOptions(forwardAllocationRegions);
        break;
      case "Carry Over Parking":
        setZoneOptions(carryOverParkingRegions);
        break;
      case "Lease of Entitlement":
        setZoneOptions(leaseOfEntitlementRegions);
        break;
      case "Permanent Entitlement":
        setZoneOptions(permanentEntitlementRegions);
        break;
      default:
        setZoneOptions([]);
    }
  };

  const handleFormChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleAllocationTypeChange = (value) => {
    handleFormChange("allocationType", value);
    updateZoneOptions(value.value); // Update zone options based on selected allocation type
  };

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const data = await getUserPhoneNumber();
        if (!data.phoneNumber) {
          setShowFlag(true); // Show the flag if phone number is missing
        }
      } catch (error) {
        console.error("Error fetching phone number:", error);
      }
    };

    fetchPhoneNumber();
  }, []);

  const handleSubmit = async () => {
    // Prepare the data object for the API
    try {
      const dataToSubmit = {
        region: formData.region.value,
        allocationType: formData.allocationType.value,
        direction: formData.direction.value,
        pricePerML: formData.pricePerML,
        emailNotification: formData.emailNotification,
        textMessageNotification: formData.textMessageNotification,
      };

      const data = await postPriceAlert(dataToSubmit);
      if (data.error) {
        throw new Error(data.error);
      } else {
        toast.success("Price alert created successfully");
        onSuccess(); // Update table
        onClose(); // Close the modal after submission
      }
    } catch (error) {
      console.error("Error creating price alert:", error);
      toast.error(`Error: ${error}`);
    }
  };

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={onClose}>
            <ModalHeader>
              <ModalTitle>
                <b>Create a new Price Alert</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div style={{ minHeight: "300px" }}>
                <div
                  style={{
                    justifyContent: "center",
                    padding: "20px 40px 20px 40px",
                    minWidth: "100%",
                  }}
                >
                  <Field name="allocationType" label="Market Type" isRequired>
                    {({ fieldProps }) => (
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            minWidth: "100%",
                          }),
                        }}
                        inputId="single-select-example"
                        className="single-select"
                        classNamePrefix="react-select"
                        options={[
                          // Allocation types
                          {
                            label: "Temporary Allocation",
                            value: "Temporary Allocation",
                          },
                          {
                            label: "Permanent Entitlement",
                            value: "Permanent Entitlement",
                          },
                          {
                            label: "Carry Over Parking",
                            value: "Carry Over Parking",
                          },
                          {
                            label: "Lease of Entitlement",
                            value: "Lease of Entitlement",
                          },
                          {
                            label: "Forward Allocation",
                            value: "Forward Allocation",
                          },
                        ]}
                        placeholder="Select Allocation Type"
                        onChange={handleAllocationTypeChange}
                      />
                    )}
                  </Field>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    padding: "20px 40px 20px 40px",
                    minWidth: "100%",
                  }}
                >
                  <Field name="region" label="Region" isRequired>
                    {({ fieldProps }) => (
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            minWidth: "100%",
                          }),
                        }}
                        inputId="single-select-example"
                        className="single-select"
                        classNamePrefix="react-select"
                        options={zoneOptions} // Use zoneOptions based on selected allocation type
                        placeholder="Select Region"
                        onChange={(value) => handleFormChange("region", value)}
                      />
                    )}
                  </Field>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  <Field name="direction" label="Direction" isRequired>
                    {({ fieldProps }) => (
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            width: 250,
                          }),
                        }}
                        inputId="single-select-example"
                        className="single-select"
                        classNamePrefix="react-select"
                        options={[
                          {
                            label: "Less than or equal to",
                            value: "Less than or equal to",
                          },
                          {
                            label: "Greater than or equal to",
                            value: "Greater than or equal to",
                          },
                        ]}
                        placeholder="Select Direction"
                        onChange={(value) =>
                          handleFormChange("direction", value)
                        }
                      />
                    )}
                  </Field>

                  <Field name="pricePerML" label="Price Per (ML)" isRequired>
                    {({ fieldProps }) => (
                      <Textfield
                        elemAfterInput={
                          <span style={{ paddingRight: "10px" }}>ML</span>
                        }
                        type="number"
                        {...fieldProps}
                        onChange={(e) =>
                          handleFormChange("pricePerML", e.target.value)
                        }
                      />
                    )}
                  </Field>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <label>Email Notification:</label>
                    <span>
                      <Toggle
                        isChecked={formData.emailNotification}
                        onChange={() =>
                          handleFormChange(
                            "emailNotification",
                            !formData.emailNotification
                          )
                        }
                      />
                    </span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label>Text Message Notification:</label>
                    <span>
                      <Toggle
                        isChecked={formData.textMessageNotification}
                        onChange={() =>
                          handleFormChange(
                            "textMessageNotification",
                            !formData.textMessageNotification
                          )
                        }
                      />
                    </span>
                  </div>
                </div>
                {showFlag && (
                  <Flag
                    id="missing-phone-flag"
                    title="Missing Phone Number"
                    description="Please add your phone number to receive text notifications."
                    actions={[
                      {
                        content: "Go to Settings",
                        onClick: () => (window.location.href = "/settings"), // Redirect to settings page
                      },
                    ]}
                    appearance="warning"
                  />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button appearance="primary" onClick={handleSubmit}>
                Create
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default CreateNewNotificationModal;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ViewListing from "../../component/modals/listing/viewListing";
import ScatterChart1 from "../../component/charts/scatterChart1";
import { toast } from "react-toastify";
import FilterModal from "../../component/modals/listing/filterModal";
import Button from "@atlaskit/button";
import AreaSplineChartTile from "../../component/tile/areaSplineChartTile";
import WaterTradeInfoTile from "../../component/tile/waterTradeInfoTile";
import TradeActivity from "../../component/charts/tradeActivity";
import { getWaterListings } from "../../action/waterListingAction";
import { getZoneId } from "../../helpers/marketZoneHelper";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
    width: "100%",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ececec",
    padding: "20px",
    width: "100%",
  },
  halfGridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  fullWidthContainer: {
    width: "100%",
    marginTop: "20px",
  },
  filterButton: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  tileWrapper: {
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#24222B",
  },
  sectionTitle: {
    fontWeight: 600,
    marginBottom: "10px",
  },
}));

const CarryOverParking = () => {
  const classes = useStyles();

  const [showWaterListingModal, setShowWaterListingModal] = useState(false);
  const [selectedWaterListing, setSelectedWaterListing] = useState(null);
  const [filter, setFilter] = useState({
    listingType: "",
    status: "",
    region: "",
  });
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [openCarryOverParking, setOpenCarryOverParking] = useState([]);

  useEffect(() => {
    fetchOpenCarryOverParking();
  }, [filter]);

  const fetchOpenCarryOverParking = async () => {
    try {
      const data = await getWaterListings({
        sortBy: "updatedAt",
        sortOrder: "desc",
        allocationType: "Carry Over Parking",
        status: "Open",
        tradeZone: filter.region,
        ...filter, // Include other filters if needed
      });

      if (data.error) {
        throw new Error(data.error);
      }

      setOpenCarryOverParking(data.listings);
    } catch (error) {
      toast.error("Failed to fetch water listings");
    }
  };

  const handleViewWaterListing = (listingId) => {
    setSelectedWaterListing(listingId);
    setShowWaterListingModal(true);
  };

  const closeWaterListingModal = () => {
    setShowWaterListingModal(false);
    setSelectedWaterListing(null);
  };

  const handleApplyFilters = (newFilters) => {
    setFilter(newFilters);
  };

  return (
    <div className="base-page">
      {selectedWaterListing && (
        <ViewListing
          isOpen={showWaterListingModal}
          onClose={closeWaterListingModal}
          listingId={selectedWaterListing}
        />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>Carry Over Parking</h2>
        <div className={classes.filterButton}>
          <Button appearance="primary" onClick={() => setFilterModalOpen(true)}>
            Apply Filters
          </Button>
        </div>
      </div>

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={filter}
        onApplyFilters={handleApplyFilters}
      />

      <div className={classes.halfGridContainer}>
        <div className={classes.tileWrapper}>
          <TradeActivity
            title="Buy Orders"
            zone={filter.region} // Pass region as zone
            limit={-1} // Fetch all entries
            listingStatus="Open"
            listingType="Buy Order"
            market="Carry Over Parking"
            onRowClick={handleViewWaterListing}
          />
        </div>
        <div className={classes.tileWrapper}>
          <TradeActivity
            title="Sell Orders"
            zone={filter.region} // Pass region as zone
            limit={-1} // Fetch all entries
            listingStatus="Open"
            listingType="Sell Order"
            market="Carry Over Parking"
            onRowClick={handleViewWaterListing}
          />
        </div>
      </div>

      <br />

      <div className={classes.halfGridContainer}>
        <div className={classes.tileWrapper}>
          <TradeActivity
            title="Recent Trades on TBWE"
            subTitle={"Last 100 Transactions"}
            zone={filter.region} // Pass region as zone
            limit={100} // Fetch all entries
            listingStatus="Accepted"
            market="Carry Over Parking"
            onRowClick={handleViewWaterListing}
          />
        </div>
        <div className={classes.tileWrapper}>
          <h3 className={classes.sectionTitle}>Open Listings</h3>
          <ScatterChart1
            data={openCarryOverParking.map((listing) => ({
              x: listing.volumeML,
              y: listing.pricePerML,
              id: listing._id,
            }))}
            chartId="LiveListingsChart"
            handlePointClick={(event, elements, chart) => {
              if (elements.length === 0) return;
              const firstElement = elements[0];
              const clickedData =
                chart.data.datasets[firstElement.datasetIndex].data[
                  firstElement.index
                ];
              if (clickedData && clickedData.id) {
                handleViewWaterListing(clickedData.id);
              }
            }}
          />
        </div>
      </div>
      <br />
      <div className={classes.fullWidthContainer}>
        <h3 className={classes.sectionTitle}>Market Insights</h3>
        <AreaSplineChartTile market={"Carry Over Parking"} />
      </div>
    </div>
  );
};

export default CarryOverParking;

import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const getPurchasedWaterOrders = async () => {
  try {
    const response = await fetch(`${API}/water-orders/purchased`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching purchased water orders:", error);
    return { error };
  }
};

export const getSoldWaterOrders = async () => {
  try {
    const response = await fetch(`${API}/water-orders/sold`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching sold water orders:", error);
    return { error };
  }
};

export const getAllWaterOrders = async () => {
  try {
    const response = await fetch(`${API}/water-orders`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching all water orders:", error);
    return { error };
  }
};

export const getOrderById = async (orderId) => {
  try {
    const response = await fetch(`${API}/water-orders/${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      credentials: "include",
    });

    return response;
  } catch (error) {
    console.error("Error fetching order:", error);
    throw error;
  }
};

export const updateWaterOrderStatus = async (orderId, status) => {
  try {
    const response = await fetch(`${API}/water-orders/${orderId}/status`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({ status }), // Send the new status in the request body
    });
    return response;
  } catch (error) {
    console.error("Error updating water order status:", error);
    return { error };
  }
};

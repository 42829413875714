import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button";
import { requireRole } from "./../../action/authAction"; // Import the requireAdmin function
import CreateOrganisationModal from "../../component/modals/admin/CreateOrganisationModal";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import DynamicTable from "@atlaskit/dynamic-table";
import EmptyState from "../../component/misc/EmptyState";
import MapBoxComponent from "../../component/map/nswDamMapBox";
import { toast } from "react-toastify";
import Badge from "@atlaskit/badge";
import { getWaterListings } from "../../action/waterListingAction"; // Import the getWaterListings action
import ViewListing from "../../component/modals/listing/viewListing";
import InfoTile from "../../component/tile/infoTile";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ececec",
    padding: "20px",
  },
  halfGridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)", // Default grid layout
    gap: "10px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)", // Stacking the columns when width is less than 1200px
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)", // Stacking the columns when width is less than 1200px
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // Default grid layout
    gap: "10px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)", // Stacking the columns when width is less than 1200px
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)", // Stacking the columns when width is less than 1200px
    },
  },
}));

const Index = () => {
  const classes = useStyles();

  const { theme } = useTheme();

  const [isCreateOrganisationModalOpen, setCreateOrganisationModalOpen] =
    useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [recentWaterListingActivity, setRecentWaterListingActivity] = useState(
    []
  );
  const [recentTemporaryAllocation, setRecentTemporaryAllocation] = useState(
    []
  );
  const [recentPermanentEntitlement, setRecentPermanentEntitlement] = useState(
    []
  );

  const [recentCarryOverParking, setRecentCarryOverParking] = useState([]);

  const [recentLeaseOfEntitlement, setRecentLeaseOfEntitlement] = useState([]);

  const [recentForwardAllocations, setRecentForwardAllocations] = useState([]);

  const [showWaterListingModal, setShowWaterListingModal] = useState(false);
  const [selectedWaterListing, setSelectedWaterListing] = useState(null);

  const openNewProjectModal = () => {
    setShowNewProjectModal(true);
  };

  const closeNewProjectModal = () => {
    setShowNewProjectModal(false);
  };

  useEffect(() => {
    fetchWaterListingsRecentActivity();
    // fetchRecentTemporaryAllocations();
    // fetchRecentPermanentEntitlement();
    // fetchRecentCarryOverParking();
    // fetchRecentLeaseOfEntilement();
    // fetchRecentForwardAllocation();
  }, []);

  const fetchWaterListingsRecentActivity = async () => {
    try {
      const data = await getWaterListings({
        limit: 40,
        sortBy: "updatedAt",
        sortOrder: "desc",
      }); // Adjust parameters as needed
      if (data.error) {
        throw new Error(data.error);
      }
      setRecentWaterListingActivity(data.listings); // Assuming data is the array of water listings
    } catch (error) {
      toast.error("Failed to fetch water listings");
    }
  };

  const fetchRecentTemporaryAllocations = async () => {
    try {
      const data = await getWaterListings({
        limit: 5,
        sortBy: "updatedAt",
        sortOrder: "desc",
        allocationType: "Temporary Allocation",
      }); // Adjust parameters as needed
      if (data.error) {
        throw new Error(data.error);
      }
      setRecentTemporaryAllocation(data.listings); // Assuming data is the array of water listings
    } catch (error) {
      toast.error("Failed to fetch water listings");
    }
  };

  const fetchRecentPermanentEntitlement = async () => {
    try {
      const data = await getWaterListings({
        limit: 5,
        sortBy: "updatedAt",
        sortOrder: "desc",
        allocationType: "Permanent Entitlement",
      }); // Adjust parameters as needed
      if (data.error) {
        throw new Error(data.error);
      }
      setRecentPermanentEntitlement(data.listings); // Assuming data is the array of water listings
    } catch (error) {
      toast.error("Failed to fetch water listings");
    }
  };

  const fetchRecentCarryOverParking = async () => {
    try {
      const data = await getWaterListings({
        limit: 5,
        sortBy: "updatedAt",
        sortOrder: "desc",
        allocationType: "Carry Over Parking",
      }); // Adjust parameters as needed
      if (data.error) {
        throw new Error(data.error);
      }
      setRecentCarryOverParking(data.listings); // Assuming data is the array of water listings
    } catch (error) {
      toast.error("Failed to fetch water listings");
    }
  };

  const fetchRecentLeaseOfEntilement = async () => {
    try {
      const data = await getWaterListings({
        limit: 5,
        sortBy: "updatedAt",
        sortOrder: "desc",
        allocationType: "Lease of Entitlement",
      }); // Adjust parameters as needed
      if (data.error) {
        throw new Error(data.error);
      }
      setRecentLeaseOfEntitlement(data.listings); // Assuming data is the array of water listings
    } catch (error) {
      toast.error("Failed to fetch water listings");
    }
  };

  const fetchRecentForwardAllocation = async () => {
    try {
      const data = await getWaterListings({
        limit: 5,
        sortBy: "updatedAt",
        sortOrder: "desc",
        allocationType: "Forward Allocation",
      }); // Adjust parameters as needed
      if (data.error) {
        throw new Error(data.error);
      }
      setRecentForwardAllocations(data.listings); // Assuming data is the array of water listings
    } catch (error) {
      toast.error("Failed to fetch water listings");
    }
  };

  const handleViewWaterListing = (listingId) => {
    const selectedListing = recentWaterListingActivity.find(
      (listing) => listing._id === listingId
    );
    setSelectedWaterListing(selectedListing);
    setShowWaterListingModal(true);
  };

  const closeWaterListingModal = () => {
    setShowWaterListingModal(false);
    setSelectedWaterListing(null);
  };

  const renderRecentActivity = () => {
    console.log("renderRecentActivity: ", recentWaterListingActivity);
    return recentWaterListingActivity.map((listing) => ({
      key: listing._id, // Assuming _id is the unique identifier of the listing
      onClick: () => handleViewWaterListing(listing._id),
      cells: [
        { key: "listingType", content: listing.listingType },
        {
          key: "status",
          content: (
            <Badge appearance={listing.status === "Open" ? "primary" : "added"}>
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {listing.status}
              </span>
            </Badge>
          ),
        },
        { key: "volumeML", content: `${listing.volumeML} ML` },
        {
          key: "pricePerML",
          content: (
            <span style={{ fontWeight: 700 }}>
              ${listing.pricePerML.toFixed(2)}
            </span>
          ),
        },
      ],
    }));
  };

  const renderRecentTemporaryAllocations = () => {
    return recentTemporaryAllocation.map((listing) => ({
      key: listing._id, // Assuming _id is the unique identifier of the listing
      onClick: () => handleViewWaterListing(listing._id),
      cells: [
        { key: "listingType", content: listing.listingType },
        {
          key: "status",
          content: (
            <Badge appearance={listing.status === "Open" ? "primary" : "added"}>
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {listing.status}
              </span>
            </Badge>
          ),
        },
        { key: "volumeML", content: `${listing.volumeML} ML` },
        {
          key: "pricePerML",
          content: (
            <span style={{ fontWeight: 700 }}>
              ${listing.pricePerML.toFixed(2)}
            </span>
          ),
        },
      ],
    }));
  };

  const renderRecentPermanentEntitlement = () => {
    return recentPermanentEntitlement.map((listing) => ({
      key: listing._id, // Assuming _id is the unique identifier of the listing
      onClick: () => handleViewWaterListing(listing._id),
      cells: [
        { key: "listingType", content: listing.listingType },
        {
          key: "status",
          content: (
            <Badge appearance={listing.status === "Open" ? "primary" : "added"}>
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {listing.status}
              </span>
            </Badge>
          ),
        },
        { key: "volumeML", content: `${listing.volumeML} ML` },
        {
          key: "pricePerML",
          content: (
            <span style={{ fontWeight: 700 }}>
              ${listing.pricePerML.toFixed(2)}
            </span>
          ),
        },
      ],
    }));
  };

  const renderRecentCarryOverParking = () => {
    return recentCarryOverParking.map((listing) => ({
      key: listing._id, // Assuming _id is the unique identifier of the listing
      onClick: () => handleViewWaterListing(listing._id),
      cells: [
        { key: "listingType", content: listing.listingType },
        {
          key: "status",
          content: (
            <Badge appearance={listing.status === "Open" ? "primary" : "added"}>
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {listing.status}
              </span>
            </Badge>
          ),
        },
        { key: "volumeML", content: `${listing.volumeML} ML` },
        {
          key: "pricePerML",
          content: (
            <span style={{ fontWeight: 700 }}>
              ${listing.pricePerML.toFixed(2)}
            </span>
          ),
        },
      ],
    }));
  };

  const renderRecentLeaseOfEntitlement = () => {
    return recentLeaseOfEntitlement.map((listing) => ({
      key: listing._id, // Assuming _id is the unique identifier of the listing
      onClick: () => handleViewWaterListing(listing._id),
      cells: [
        { key: "listingType", content: listing.listingType },
        {
          key: "status",
          content: (
            <Badge appearance={listing.status === "Open" ? "primary" : "added"}>
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {listing.status}
              </span>
            </Badge>
          ),
        },
        { key: "volumeML", content: `${listing.volumeML} ML` },
        {
          key: "pricePerML",
          content: (
            <span style={{ fontWeight: 700 }}>
              ${listing.pricePerML.toFixed(2)}
            </span>
          ),
        },
      ],
    }));
  };

  const renderRecentForwardAllocation = () => {
    return recentForwardAllocations.map((listing) => ({
      key: listing._id, // Assuming _id is the unique identifier of the listing
      onClick: () => handleViewWaterListing(listing._id),
      cells: [
        { key: "listingType", content: listing.listingType },
        {
          key: "status",
          content: (
            <Badge appearance={listing.status === "Open" ? "primary" : "added"}>
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {listing.status}
              </span>
            </Badge>
          ),
        },
        { key: "volumeML", content: `${listing.volumeML} ML` },
        {
          key: "pricePerML",
          content: (
            <span style={{ fontWeight: 700 }}>
              ${listing.pricePerML.toFixed(2)}
            </span>
          ),
        },
      ],
    }));
  };

  const tableHead = {
    cells: [
      { key: "listingType", content: "Listing Type" },
      { key: "status", content: "Status" },
      { key: "volumeML", content: "Volume (ML)" },
      { key: "pricePerML", content: "Price Per ML" },
    ],
  };

  return (
    <div className="base-page">
      {selectedWaterListing && (
        <ViewListing
          isOpen={showWaterListingModal}
          onClose={closeWaterListingModal}
          listingId={selectedWaterListing._id}
        />
      )}
      <div style={{ display: "flex", width: "100%" }}>
        <CreateOrganisationModal
          isOpen={isCreateOrganisationModalOpen}
          onClose={() => setCreateOrganisationModalOpen(false)}
        />
        <h2 style={{ width: "100%", fontWeight: "700" }}>Admin Panel</h2>
      </div>
      <br />
      <div className={classes.gridContainer}>
        <InfoTile
          title={"Users"}
          subTitle={"Total"}
          dataType={"User Count"}
          market={null}
          zone={null}
        />

        <InfoTile
          title={"Total Trade Volume"}
          subTitle={"Last 7 days"}
          dataType={"Trade Volume"}
          market={null}
          zone={null}
        />

        <InfoTile
          title={"Trade Count"}
          subTitle={"Last 7 days"}
          dataType={"Trade Count"}
          market={null}
          zone={null}
        />
      </div>
      <br />
      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h5 style={{ fontWeight: 700 }}>Recent Activity</h5>
        <br />
        <div>
          <DynamicTable
            head={tableHead}
            rows={renderRecentActivity()}
            defaultPage={1}
            loadingSpinnerSize="large"
            rowsPerPage={10}
            isLoading={false}
            emptyView={<EmptyState message="No recent activity to display." />}
          />
        </div>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        ></div>
      </div>

      <br />
      {/* <div className={classes.gridContainer}>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Recent Temporary Allocations</h5>
          <br />
          <div>
            <DynamicTable
              head={tableHead}
              rows={renderRecentTemporaryAllocations()}
              defaultPage={1}
              loadingSpinnerSize="large"
              isLoading={false}
              emptyView={
                <EmptyState message="No recent activity to display." />
              }
            />
          </div>
        </div>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Recent permanent Entitlements</h5>
          <br />
          <div>
            <DynamicTable
              head={tableHead}
              rows={renderRecentPermanentEntitlement()}
              defaultPage={1}
              loadingSpinnerSize="large"
              isLoading={false}
              limit={5}
              emptyView={
                <EmptyState message="No recent activity to display." />
              }
            />
          </div>
        </div>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Recent Carry Over Parking</h5>
          <br />
          <div>
            <DynamicTable
              head={tableHead}
              rows={renderRecentCarryOverParking()}
              defaultPage={1}
              loadingSpinnerSize="large"
              isLoading={false}
              emptyView={
                <EmptyState message="No recent activity to display." />
              }
            />
          </div>
        </div>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Recent Lease of Entitlement</h5>
          <br />
          <div>
            <DynamicTable
              head={tableHead}
              rows={renderRecentLeaseOfEntitlement()}
              defaultPage={1}
              loadingSpinnerSize="large"
              isLoading={false}
              emptyView={
                <EmptyState message="No recent activity to display." />
              }
            />
          </div>
        </div>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Recent Forward Allocations</h5>
          <br />
          <div>
            <DynamicTable
              head={tableHead}
              rows={renderRecentForwardAllocation()}
              defaultPage={1}
              loadingSpinnerSize="large"
              isLoading={false}
              emptyView={
                <EmptyState message="No recent activity to display." />
              }
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default requireRole([99])(Index);

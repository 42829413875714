import React, { useEffect, useState } from "react";
import HomepageInfoTileSection from "../../component/section/homepageInfoTileSection";
import TradeActivityTile from "../../component/tile/tradeActivityTile";
import FinancialChartTile from "../../component/tile/financialChartTile";
import { makeStyles } from "@material-ui/core/styles";
import AreaSplineChartTile from "../../component/tile/areaSplineChartTile";
import WaterTradeInfoTile from "../../component/tile/waterTradeInfoTile";
import WaterZoneMapBoxTile from "../../component/tile/waterZoneMapBoxTile";
import { getZoneId } from "../../helpers/marketZoneHelper";
import InfinitePartnerScroller from "../../component/misc/InfinitePartnerScroller";
import HomepageInfoCTA from "./homepageInfoCTA";
import Footer from "../../component/misc/footer";

const useStyles = makeStyles(() => ({
  halfGridContainer: {
    display: "grid",
    margin: "10px",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  gridContainer: {
    margin: "10px",
  },
  dataSectionTitle: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  waterZoneMapContainer: {
    maxWidth: "100%",
    minWidth: "200px",
    width: "100%",
    margin: "0px 50px 50px 50px",
  },
}));

const HomepageDataSection = ({
  market,
  zone,
  onZoneChange,
  onMarketChange,
}) => {
  const classes = useStyles();
  const [dataSectionTitle, setDataSectionTitle] = useState("");

  useEffect(() => {
    if (market && zone) {
      setDataSectionTitle(`${market} ${zone} Water Market`);
    }
  }, [market, zone, dataSectionTitle]);

  return (
    <div>
      {market && zone ? (
        <div>
          {/* <HomepageInfoTileSection market={market} zone={zone} /> */}
          <div className={classes.halfGridContainer}>
            <TradeActivityTile
              title={"Open Sell Orders"}
              subTitle={`${market} - ${zone}`}
              listingType={"Sell Order"}
              listingStatus={"Open"}
              market={market}
              zone={zone}
            />
            <TradeActivityTile
              title={"Open Buy Orders"}
              subTitle={`${market} - ${zone}`}
              listingType={"Buy Order"}
              listingStatus={"Open"}
              market={market}
              zone={zone}
            />
          </div>
          {market === "Temporary Allocation" ||
          market === "Permanent Entitlement" ? (
            <>
              <div className={classes.halfGridContainer}>
                <TradeActivityTile
                  title="Recent Trades on TBWE"
                  subTitle={"Last 100 Transactions"}
                  listingStatus={"Closed"}
                  limit={100} // Fetch all entries
                  market={market}
                  zone={zone}
                />
                <TradeActivityTile
                  subTitle={"Last 100 Transactions"}
                  title="Registry Data"
                  zone={getZoneId(zone)} // Pass region as zone
                  zoneDetails={zone}
                  limit={100} // Fetch all entries
                  market={market}
                  source="Registry"
                />
              </div>

              <div className={classes.gridContainer}>
                {/* <WaterTradeInfoTile title={dataSectionTitle} /> */}
                <AreaSplineChartTile market={market} zone={zone} />
              </div>
            </>
          ) : (
            <div className={classes.gridContainer}>
              <TradeActivityTile
                title="Recent Trades on TBWE"
                subTitle={"Last 100 Transactions"}
                listingStatus={"Closed"}
                limit={100} // Fetch all entries
                market={market}
                zone={zone}
              />
            </div>
          )}
          {/* <Footer /> */}
        </div>
      ) : (
        <div>
          {/* <div className="center">
            <div className={classes.waterZoneMapContainer}>
              <WaterZoneMapBoxTile
                styleUrl="mapbox://styles/truebluewaterexchange/cm0w4jhdf00xs01pw1xnpgtk0"
                center={[145.2099, -32.865143]}
                zoom="5"
                apiUrl="/water-info/nsw"
                height={700}
                onMarketChange={onMarketChange}
                onZoneChange={onZoneChange}
                title={"Trading Zone Information"}
                subtitle={
                  "Select a ZONE to view a current summary of the water market."
                }
                extraPadding={130}
              />
            </div>
          </div>
          <HomepageInfoCTA />
          <InfinitePartnerScroller /> */}
        </div>
      )}
    </div>
  );
};

export default HomepageDataSection;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../config/config"; // Adjust the import path to your configuration
import { getZoneId, getReliability } from "../../helpers/marketZoneHelper";

const AreaSplineChart = ({
  backgroundColor = "#101118",
  market = "All Markets",
  zone = -1,
}) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Starting data fetch...");
      try {
        let reliability = "";
        if (market === "Permanent Entitlement") {
          reliability = getReliability(zone);
          if (reliability) {
            reliability = `&reliability=${reliability}`;
          }
        }
        console.log("Reliability:", reliability);

        if (zone !== -1) {
          zone = getZoneId(zone);
        }

        let url = `${API}/water-history/financial-data?tradeFromZone=${zone}&tradeType=${market}${reliability}`;

        const response = await axios.get(url);
        console.log("API Response:", response.data); // Log the full response data

        const formattedData = response.data.map((d) => [
          new Date(d.date).getTime(),
          d.high,
        ]);

        console.log("Formatted Data:", formattedData); // Log the formatted data
        setChartData(formattedData);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching market data:", error); // Log any errors
        setLoading(false); // Set loading to false even if there is an error
      }
    };

    fetchData();
  }, [market, zone]);

  useEffect(() => {
    if (chartData.length === 0) return;

    console.log("Initializing Highcharts...");
    console.log("Chart Data:", chartData); // Log chart data to ensure it's correctly set

    // Ensure Highcharts Stock is available globally
    if (!window.Highcharts || !window.Highcharts.stockChart) {
      console.error("Highcharts or Highcharts Stock is not loaded");
      return;
    }

    // Initialize the Highcharts stock chart after the component is fully mounted
    setTimeout(() => {
      try {
        window.Highcharts.stockChart("highcharts-container", {
          rangeSelector: {
            selected: 1,
            buttonTheme: {
              fill: "#0F1118", // Background color of the buttons
              stroke: "none",
              style: {
                color: "#FFFFFF", // Text color of the buttons
              },
              states: {
                hover: {
                  fill: "#1C1E24", // Background color of the buttons on hover
                  style: {
                    color: "#FFFFFF", // Text color of the buttons on hover
                  },
                },
                select: {
                  fill: "#2C2E34", // Background color of the selected buttons
                  style: {
                    color: "#FFFFFF", // Text color of the selected buttons
                  },
                },
              },
            },
          },
          chart: {
            type: "areaspline",
            backgroundColor: backgroundColor, // Set background color here
          },
          title: {
            text: "Highest Price Per ML",
            style: { color: "#FFFFFF" }, // Keep title color as white
          },
          xAxis: {
            type: "datetime",
            title: {
              text: "Date",
              style: { color: "#FFFFFF" }, // Keep x-axis title color as white
            },
            labels: {
              style: { color: "#FFFFFF" }, // Keep x-axis labels color as white
            },
            lineColor: "#7C7C7C", // Change x-axis line color to grey
            tickColor: "#7C7C7C", // Change x-axis tick color to grey
            gridLineColor: "#7C7C7C", // Change x-axis grid line color to grey
          },
          yAxis: {
            title: {
              text: "Highest Price Per ML",
              style: { color: "#FFFFFF" }, // Keep y-axis title color as white
            },
            labels: {
              style: { color: "#FFFFFF" }, // Keep y-axis labels color as white
            },
            lineColor: "#7C7C7C", // Change y-axis line color to grey
            tickColor: "#7C7C7C", // Change y-axis tick color to grey
            gridLineColor: "#7C7C7C", // Change y-axis grid line color to grey
          },
          series: [
            {
              name: "Highest Price Per ML",
              data: chartData,
              type: "areaspline",
              threshold: null,
              tooltip: {
                valueDecimals: 2,
              },
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, window.Highcharts.getOptions().colors[0]],
                  [
                    1,
                    window.Highcharts.color(
                      window.Highcharts.getOptions().colors[0]
                    )
                      .setOpacity(1)
                      .get("rgba"),
                  ],
                ],
              },
              color: "#7cb5ec", // Change series color for better contrast
            },
          ],
          legend: {
            itemStyle: {
              color: "#FFFFFF", // Keep legend text color as white
            },
          },
          navigator: {
            enabled: true, // Enable navigator
            outlineColor: "#7C7C7C", // Change outline color of the navigator to grey
            maskFill: "rgba(255, 255, 255, 0.2)", // Change mask fill color
            series: {
              color: "#7cb5ec", // Change series color in the navigator
              fillOpacity: 0.05, // Change fill opacity in the navigator
            },
            xAxis: {
              labels: {
                style: {
                  color: "#FFFFFF", // Keep text color of the navigator as white
                },
              },
              lineColor: "#7C7C7C", // Change navigator x-axis line color to grey
              tickColor: "#7C7C7C", // Change navigator x-axis tick color to grey
              gridLineColor: "#7C7C7C", // Change navigator x-axis grid line color to grey
            },
          },
          credits: {
            enabled: false,
          },
        });
        console.log("Highcharts initialized successfully.");
      } catch (initError) {
        console.error("Error initializing Highcharts:", initError);
      }
    }, 0); // Adding a small timeout to ensure the DOM is fully updated
  }, [chartData]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while fetching data
  }

  return <div id="highcharts-container" style={{ height: "400px" }}></div>;
};

export default AreaSplineChart;

import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

// Fetch all referrals
export const getReferrals = async () => {
  try {
    const response = await fetch(`${API}/referrals`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const getActiveReferrals = async () => {
  try {
    const response = await fetch(`${API}/referrals/active`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
// Fetch a single referral by ID
export const getReferralById = async (id) => {
  try {
    const response = await fetch(`${API}/referral/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

// Create a new referral
export const createReferral = async (referralData) => {
  try {
    const response = await fetch(`${API}/referral`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(referralData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData.message || "An error occurred while creating the referral.";
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error("An error occurred:", error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

// Update a referral status
export const updateReferralStatus = async (id, status) => {
  try {
    const response = await fetch(`${API}/referral/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({ status }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

// Delete a referral
export const deleteReferral = async (id) => {
  try {
    const response = await fetch(`${API}/referral/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

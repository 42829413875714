import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createReferral } from "../../../action/referralAction";
import { getUsers } from "../../../action/userAction";
import Select from "@atlaskit/select";

const CreateReferralModal = ({ isOpen, onClose, onSuccess, onFailure }) => {
  const [values, setValues] = useState({
    referrerId: null,
    referralCode: "",
    referrerReward: 0,
    referredReward: 0,
  });
  const [users, setUsers] = useState([]);
  const [qrCodeSVG, setQrCodeSVG] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        const data = await response.json(); // Ensure JSON parsing
        console.log("createReferralModal.js: data", data);
        if (Array.isArray(data.users)) {
          setUsers(
            data.users.map((user) => ({
              label: `${user.name} - ${user._id}`,
              value: user._id,
              user,
            }))
          );
        } else {
          console.error("Unexpected data format:", data);
          toast.error("Failed to load users.");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Error fetching users.");
      }
    };
    fetchUsers();
  }, []);

  const handleChange = (name) => (e) => {
    setValues({
      ...values,
      [name]: e.target.value,
    });
  };

  const handleUserSelect = (selectedOption) => {
    setValues({
      ...values,
      referrerId: selectedOption ? selectedOption.user : null,
    });
  };

  const handleCreateReferral = async () => {
    if (
      !values.referrerId ||
      values.referralCode === "" ||
      values.referrerReward < 0 ||
      values.referredReward < 0
    ) {
      toast.error("Please fill in all fields with valid values.");
    } else {
      try {
        const data = await createReferral(values);

        if (data.error) {
          throw new Error(data.error);
        } else {
          setQrCodeSVG(data.qrCodeSVG);
          setShowSuccess(true);
          toast.success("Referral created successfully!");
          onSuccess();
        }
      } catch (error) {
        console.error("Error creating referral:", error);
        toast.error(`Failed to create referral. ${error}`);
        onFailure();
      }
    }
  };

  const handleModalClose = () => {
    setValues({
      referrerId: null,
      referralCode: "",
      referrerReward: 0,
      referredReward: 0,
    });
    setShowSuccess(false);
    setQrCodeSVG(null);
    onClose();
  };

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal
            autoFocus={false}
            onClose={handleModalClose}
            className="centeredModal"
          >
            <ModalHeader>
              <ModalTitle>
                <b>Create New Referral</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              {!showSuccess ? (
                <div>
                  <form>
                    <div className="form-group">
                      <Field name="referrerId" label="Select Referrer:">
                        {({ fieldProps }) => (
                          <Select
                            {...fieldProps}
                            options={users}
                            onChange={handleUserSelect}
                            placeholder="Select Referrer"
                            value={
                              values.referrerId && {
                                label: `${values.referrerId.name} - ${values.referrerId._id}`,
                                value: values.referrerId._id,
                              }
                            }
                            menuPortalTarget={document.body} // Ensure the dropdown is rendered in the body
                            menuPosition="fixed" // Fix the menu position
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the dropdown is on top of other elements
                              menu: (base) => ({
                                ...base,
                                zIndex: 9999,
                                position: "absolute", // Set the position to absolute to prevent clipping
                              }),
                              control: (base) => ({
                                ...base,
                                width: 300, // Adjust width as needed
                              }),
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="form-group">
                      <Field name="referralCode" label="Referral Code:">
                        {({ fieldProps }) => (
                          <Textfield
                            {...fieldProps}
                            placeholder="Enter Referral Code"
                            onChange={handleChange("referralCode")}
                            required
                            value={values.referralCode}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="form-group">
                      <Field name="referrerReward" label="Referrer Reward (%):">
                        {({ fieldProps }) => (
                          <Textfield
                            {...fieldProps}
                            placeholder="e.g. 10"
                            type="number"
                            onChange={handleChange("referrerReward")}
                            required
                            value={values.referrerReward}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="form-group">
                      <Field name="referredReward" label="Referred Reward (%):">
                        {({ fieldProps }) => (
                          <Textfield
                            {...fieldProps}
                            placeholder="e.g. 5"
                            type="number"
                            onChange={handleChange("referredReward")}
                            required
                            value={values.referredReward}
                          />
                        )}
                      </Field>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <h4>Referral Created Successfully!</h4>
                  <div
                    id="qr-code-svg"
                    style={{
                      maxWidth: "300px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: "20px",
                    }}
                    dangerouslySetInnerHTML={{ __html: qrCodeSVG }}
                  />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              {!showSuccess ? (
                <>
                  <Button appearance="subtle" onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button appearance="primary" onClick={handleCreateReferral}>
                    Create Referral
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    appearance="primary"
                    onClick={() => console.log("Download functionality here")}
                  >
                    Download QR Code
                  </Button>
                  <Button appearance="subtle" onClick={handleModalClose}>
                    Close
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default CreateReferralModal;

import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

// Function to get water listings based on query parameters
export const getWaterLicenses = async () => {
  try {
    const response = await fetch(`${API}/water-licenses`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response; // Assuming data is the response with water listings
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const getAllPendingVerificationWaterLicenses = async () => {
  try {
    const response = await fetch(
      `${API}/water-licenses/pending-verification/all`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const getAllVerifiedWaterLicenses = async () => {
  try {
    const response = await fetch(`${API}/water-licenses/verified/all`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const getAllRejectedWaterLicenses = async () => {
  try {
    const response = await fetch(`${API}/water-licenses/rejected/all`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const createWaterLicense = async (licenseData) => {
  try {
    const response = await fetch(`${API}/water-license`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(licenseData),
    });
    const responseData = await response.json(); // Assuming the server responds with JSON
    return responseData; // This should contain the server's response to the POST request, such as the details of the newly created license
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const verifyWaterLicense = async (licenseId) => {
  const response = await fetch(`${API}/water-license/verify/${licenseId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.get("jwt_auth")}`,
    },
  });
  return response.json();
};

export const rejectWaterLicense = async (licenseId) => {
  const response = await fetch(`${API}/water-license/reject/${licenseId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.get("jwt_auth")}`,
    },
  });
  return response.json();
};

export const setWaterLicensePendingVerification = async (licenseId) => {
  const response = await fetch(`${API}/water-license/pending/${licenseId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.get("jwt_auth")}`,
    },
  });
  return response.json();
};

export const getWaterLicenseById = async (licenseId) => {
  try {
    const response = await fetch(`${API}/water-license/${licenseId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching water license by ID:", error);
    return { error };
  }
};

export const deleteWaterLicenseById = async (licenseId) => {
  try {
    const response = await fetch(`${API}/water-license/${licenseId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching water license by ID:", error);
    return { error };
  }
};

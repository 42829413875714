import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../theme/ThemeContext";

const useStyles = makeStyles(() => ({
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "200px",
  },
  container: { maxWidth: "1000px" },
  details: { opacity: 0.7, paddingBottom: "10px" },
  heading: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
  subHeading: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginTop: "20px",
  },
  subSubHeading: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginTop: "15px",
  },
}));

const TermsAndConditions = () => {
  const history = useHistory();
  const { theme } = useTheme();
  const classes = useStyles();

  return (
    <div className="base-page">
      <div className={classes.outerContainer}>
        <div className={classes.container}>
          <h1 className={classes.heading}>Terms and Conditions</h1>

          <h2 className={classes.subHeading}>Part 1 – Definitions & Legals</h2>

          <h3 className={classes.subSubHeading}>1.1 Application</h3>
          <p className={classes.details}>
            These Terms and Conditions (as amended from time to time and
            displayed on the website www.truebluewaterexchange.com.au), along
            with your Registration to the TBWE Platform constitute the entire
            agreement ("Agreement") between True Blue Water Exchange Pty Ltd
            ("True Blue Water Exchange," "We," or "Us") and you, the User ("You"
            or "User"). You agree to be bound by this Agreement.
          </p>

          <h3 className={classes.subSubHeading}>1.2 Definitions</h3>
          <p className={classes.details}>
            <strong>Buyer:</strong> A person or entity that submits an offer to
            purchase Water Entitlement, whether accepted or not. <br />
            <strong>Seller:</strong> A person or entity that submits an offer to
            sell Water Entitlement, whether accepted or not. <br />
            <strong>Water Entitlement:</strong> Includes Water Share, Water
            Allocation, or any other water rights that can be traded. <br />
            <strong>Permanent Transfer:</strong> The sale or transfer of a Water
            Share or other Permanent Water Entitlement. <br />
            <strong>Temporary Transfer:</strong> The sale or transfer of Water
            Allocation for a limited period. <br />
            <strong>True Blue Water Exchange Platform:</strong> The online water
            trading platform operated by True Blue Water Exchange. <br />
            <strong>Online Account:</strong> An account created by the User to
            participate in water transactions via the platform. <br />
            <strong>Fees and Charges:</strong> All amounts payable to True Blue
            Water Exchange, including commissions, transaction fees, and other
            disbursements. <br />
            <strong>Water Corporation:</strong> Any regulatory authority as
            defined under applicable water trading legislation.
          </p>

          <h3 className={classes.subSubHeading}>1.3 Rules of Construction</h3>
          <p className={classes.details}>
            Words defined in relevant water legislation (such as the Water Act)
            will carry the same meaning herein. Words defined herein will apply
            to singular and plural uses, and references to any gender will
            include all genders.
          </p>

          <h3 className={classes.subSubHeading}>1.4 Waiver</h3>
          <p className={classes.details}>
            The failure of any party to exercise or enforce a right under this
            Agreement does not constitute a waiver of such right. All rights
            under this Agreement remain enforceable unless expressly waived in
            writing.
          </p>

          <h3 className={classes.subSubHeading}>1.5 Applicable Law</h3>
          <p className={classes.details}>
            This Agreement is governed by the laws of South Australia. Any
            disputes arising under this Agreement will be resolved in the courts
            of South Australia.
          </p>

          <h3 className={classes.subSubHeading}>1.6 Severability</h3>
          <p className={classes.details}>
            If any provision of this Agreement is found to be invalid or
            unenforceable, the remaining provisions will continue in full force
            and effect.
          </p>

          <h2 className={classes.subHeading}>
            Part 2 – True Blue Water Exchange Platform Terms & Conditions
          </h2>

          <h3 className={classes.subSubHeading}>2.1 Appointment as Agent</h3>
          <p className={classes.details}>
            By registering on the platform, You appoint True Blue Water Exchange
            Pty Ltd as Your agent to buy or sell Water Entitlement on Your
            behalf.
          </p>

          <h3 className={classes.subSubHeading}>2.2 Commission and Fees</h3>
          <p className={classes.details}>
            In consideration for services rendered, You agree to pay commissions
            and fees as outlined in the fee schedule for Permanent, Temporary,
            Forward Allocation, Carry Over, and Lease of Entitlement
            Transactions. Commissions are payable upon settlement, contract
            abandonment, or 90 days without settlement due to inaction.
          </p>

          <h3 className={classes.subSubHeading}>2.3 Payment Authorization</h3>
          <p className={classes.details}>
            You authorize True Blue Water Exchange to deduct commissions, fees,
            and charges from any sale proceeds held by us before remittance to
            You.
          </p>

          <h3 className={classes.subSubHeading}>2.4 Acknowledgements</h3>
          <p className={classes.details}>
            You acknowledge that True Blue Water Exchange does not offer legal
            or financial advice and reserves the right to refuse offers at its
            discretion. You must provide requested documentation for water
            trades.
          </p>

          <h2 className={classes.subHeading}>
            Part 3 – Internet Terms & Conditions
          </h2>

          <h3 className={classes.subSubHeading}>3.1 Application</h3>
          <p className={classes.details}>
            These terms apply to all Users accessing True Blue Water Exchange’s
            online platform.
          </p>

          <h3 className={classes.subSubHeading}>3.2 Acknowledgements</h3>
          <p className={classes.details}>
            By using the platform, You acknowledge that True Blue Water Exchange
            facilitates transactions but does not guarantee completion of
            trades. You are bound by the terms in place at the time of the
            transaction.
          </p>

          <h3 className={classes.subSubHeading}>3.3 User Responsibilities</h3>
          <p className={classes.details}>
            You agree to provide accurate information, maintain the
            confidentiality of Your login details, and accept responsibility for
            actions under Your account.
          </p>

          <h3 className={classes.subSubHeading}>3.4 Security</h3>
          <p className={classes.details}>
            You are responsible for securing Your account login credentials.
            True Blue Water Exchange reserves the right to suspend services for
            security concerns without prior notice.
          </p>

          <h2 className={classes.subHeading}>
            Part 4 – Sale or Purchase of Water Entitlement
          </h2>

          <h3 className={classes.subSubHeading}>
            4.1 Seller’s Responsibilities
          </h3>
          <p className={classes.details}>
            You must provide the Buyer with a Contract of Sale and documentation
            within 7 business days. The Buyer must respond within 5 business
            days, with the sale being binding only upon signed contract and
            deposit payment.
          </p>

          <h3 className={classes.subSubHeading}>
            4.2 Buyer’s Responsibilities
          </h3>
          <p className={classes.details}>
            Once an offer is accepted and the contract signed, both parties are
            legally bound. Payment must be completed within 48 hours of contract
            signing.
          </p>

          <h2 className={classes.subHeading}>
            Part 5 – Sale or Purchase of Water Allocations (Temporary Trade)
          </h2>

          <h3 className={classes.subSubHeading}>5.1 Payment Terms</h3>
          <p className={classes.details}>
            Payment must be made within 48 hours, with True Blue Water Exchange
            holding funds until the Water Corporation confirms transfer.
          </p>

          <h3 className={classes.subSubHeading}>5.2 Agency Authorization</h3>
          <p className={classes.details}>
            You authorize True Blue Water Exchange to act as Your agent and
            disclose necessary information for trade facilitation.
          </p>

          <h2 className={classes.subHeading}>Privacy Policy</h2>
          <p className={classes.details}>
            True Blue Water Exchange is committed to protecting your privacy.
            Personal information will only be shared as necessary to facilitate
            transactions.
          </p>

          <h2 className={classes.subHeading}>Indemnity</h2>
          <p className={classes.details}>
            You agree to indemnify True Blue Water Exchange from any claims or
            damages arising from platform use or Agreement violation.
          </p>

          <h2 className={classes.subHeading}>Force Majeure</h2>
          <p className={classes.details}>
            True Blue Water Exchange is not liable for delays due to events
            beyond our control.
          </p>

          <p className={classes.details}>
            These Terms and Conditions aim to protect both the Users and True
            Blue Water Exchange, ensuring a secure and transparent environment
            for all water transactions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

import React, { useState, useEffect } from "react";
import {
  getUserPhoneNumber,
  updateUserPhoneNumber,
} from "../../action/userAction";
import Button from "@atlaskit/button/standard-button";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const UpdatePhoneNumberModal = () => {
  const [values, setValues] = useState({
    phoneNumber: "",
    message: "",
    error: "",
    loading: false,
  });

  const { phoneNumber, message, loading, error } = values;

  const handleLoad = async () => {
    setValues({ ...values, loading: true });
    try {
      const data = await getUserPhoneNumber();
      if (data.phoneNumber) {
        setValues({ ...values, phoneNumber: data.phoneNumber, loading: false });
      } else {
        setValues({
          ...values,
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching phone number:", error);
      setValues({
        ...values,
        error: "No phone number found",
        loading: false,
      });
    }
  };

  useEffect(() => {
    handleLoad();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });
    try {
      const data = await updateUserPhoneNumber(phoneNumber);
      if (data.message) {
        setValues({ ...values, message: data.message, loading: false });
      } else {
        setValues({
          ...values,
          error: data.error || "Failed to update phone number",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error updating phone number:", error);
      setValues({
        ...values,
        error: "An error occurred. Please try again later.",
        loading: false,
      });
    }
  };

  const handlePhoneNumberChange = (value) => {
    setValues({ ...values, phoneNumber: value, message: "", error: "" });
  };

  const showLoading = () =>
    loading ? <div className="alert alert-info">Loading...</div> : "";
  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";

  const phoneNumberForm = () => (
    <div className="custom-modal">
      <form onSubmit={handleSubmit}>
        <PhoneInput
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Enter your phone number"
          defaultCountry="AU"
        />
        <br />
        <Button style={{ float: "right" }} appearance="primary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );

  return (
    <div className="container">
      {showLoading()}
      {showError()}
      {showMessage()}
      {phoneNumberForm()}
    </div>
  );
};

export default UpdatePhoneNumberModal;

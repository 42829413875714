import React, { useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../../theme/ThemeContext";
import { editWaterListing } from "../../../action/waterListingAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditListing = ({
  isOpen,
  onClose,
  onSuccess,
  onFailure,
  waterListing,
}) => {
  const [values, setValues] = useState({
    pricePerML: "",
  });

  const handleChange = (name) => (e) => {
    const updatedPricePerML = e.target.value;
    setValues({
      ...values,
      [name]: updatedPricePerML,
      message: "",
    });
  };

  const handleEditListing = async () => {
    if (values.pricePerML === "") {
      toast.error("Enter a bid value.");
    } else {
      try {
        const data = await editWaterListing({
          pricePerML: values.pricePerML,
          waterListingId: waterListing._id,
        }); // Adjust parameters as needed

        if (data.error) {
          throw new Error(data.error);
        } else {
          onSuccess();
          toast.success("Water Listing price changed!");
          onClose();
        }
      } catch (error) {
        console.error("Error submitting bid:", error);
        toast.error(`Failed to submit bid. ${error}`);
      }
    }
  };

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal autoFocus={false} onClose={onClose} className="centeredModal">
            <ModalHeader>
              <ModalTitle>
                <b>Edit Listing Price</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>
                <form>
                  <div className="form-group">
                    <Field name="pricePerML" label="New Price Per ML:">
                      {({ fieldProps }) => (
                        <Textfield
                          elemBeforeInput={
                            <span style={{ paddingLeft: "10px" }}>
                              <FontAwesomeIcon icon={faDollarSign} />
                            </span>
                          }
                          appearance="standard"
                          label="Standard"
                          placeholder="e.g. 35"
                          onChange={handleChange("pricePerML")}
                          required
                          type="number"
                          value={values.pricePerML}
                        />
                      )}
                    </Field>
                  </div>
                  <p>
                    Note: Editing the price of a listing will delete all bids
                    for the listing.
                  </p>
                </form>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button appearance="warning" onClick={handleEditListing}>
                Edit Listing
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default EditListing;

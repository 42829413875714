import React from "react";
import WaterListingSearch from "../../component/search/waterListingSearch";

const WaterListingSearchSection = ({
  onZoneChange,
  onMarketChange,
  selectedMarket,
  selectedZone,
}) => {
  return (
    <div
      style={{
        //backgroundImage: `url("./images/MurrayRiver.jpeg")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        margin: "50px",
        borderRadius: "20px",
      }}
      className="justify-content-center d-flex"
    >
      <div
        style={{
          paddingTop: "150px",
          paddingBottom: "150px",
        }}
      >
        <div className="center" style={{ paddingBottom: "100px" }}>
          <img
            src="https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Misc/TBWE_logo_blue.webp"
            width={400}
            height={125}
          />
        </div>
        <div className="center">
          <div className="homepage-title">Accelerate Your Water Trading</div>
        </div>
        <div className="center">
          <div className="homepage-subtitle">
            True Blue Water Exchange allows customers to trade more efficiently
            with more data
          </div>
        </div>
        <WaterListingSearch
          onZoneChange={onZoneChange}
          onMarketChange={onMarketChange}
          selectedMarket={selectedMarket}
          selectedZone={selectedZone}
        />
      </div>
    </div>
  );
};

export default WaterListingSearchSection;

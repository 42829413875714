import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./component/navigation/header";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import HomeAdmin from "./pages/admin/index";
import ManageOrganisations from "./pages/admin/manageOrganisations";
import HomeUser from "./pages/user/index";
import DynamicSidebar from "./component/navigation/dynamicSidebar";
import Protected from "./component/private/Protected";
import Homepage from "./pages/Homepage";
import Colleagues from "./pages/organisation/colleagues";
import AllOrders from "./pages/orders/all";
import Settings from "./pages/settings";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import CarryOverParking from "./pages/waterTrading/carryOverParking";
import forwardAllocation from "./pages/waterTrading/forwardAllocation";
import leaseOfEntitlement from "./pages/waterTrading/leaseOfEntitlement";
import permanentEntitlement from "./pages/waterTrading/permanentEntitlement";
import temporaryAllocation from "./pages/waterTrading/temporaryAllocation";
import { useTheme } from "./theme/ThemeContext";
import { token, setGlobalTheme } from "@atlaskit/tokens";
import AccountConfirmationMiddleware from "./middleware/accountConfirmationMiddleware";
import AccountConfirmation from "./pages/user/accountConfirmation";
import UseHubSpotChat from "./component/hubspot/useHubSpotChat";
import CreateOrganisation from "./pages/organisation/createOrganisation";
import WaterLicenses from "./pages/user/waterLicenses";
import Fees from "./pages/other/fees";
import TermsAndConditions from "./pages/other/termsAndConditions";
import bids from "./pages/waterTrading/bids";
import XeroAuth from "./pages/admin/xeroAuth";
import PriceHistory from "./pages/priceHistory/PriceHistory";
import HeatMap from "./pages/priceHistory/HeatMap";
import PriceAlerts from "./pages/priceAlerts/priceAlerts";
import users from "./pages/admin/users";
import manageWaterLicenses from "./pages/admin/manageWaterLicenses";
import userProfile from "./pages/user/userProfile";
import AboutPage from "./pages/misc/about";
import ContactPage from "./pages/misc/contact";
import PhoneNumberPage from "./pages/phoneNumber";
import ReferralPage from "./pages/admin/referralPage";
import ListingsPage from "./pages/user/listings";

function App() {
  const { theme } = useTheme();

  setGlobalTheme({
    light: "light",
    dark: "dark",
    colorMode: theme,
  });

  // Use useEffect to update the body background color based on the theme
  useEffect(() => {
    // Define the body background color based on the theme
    const bodyBackgroundColor = theme === "dark" ? "#101118" : "white";
    const bodyColor = theme === "dark" ? "white" : "black";
    document.body.style.backgroundColor = bodyBackgroundColor;
    document.body.style.color = bodyColor;
  }, [theme]);
  return (
    <div
      style={{ backgroundColor: token("elevation.surface") }}
      className="App"
    >
      <Router>
        <Header />
        <UseHubSpotChat />
        <Protected />
        <Switch>
          <Route path="/" component={Homepage} exact />
          <Route path="/signup" component={SignUp} exact />
          <Route path="/signin" component={SignIn} exact />
          <Route path="/ForgotPassword" component={ForgotPassword} exact />
          <Route
            path="/resetpassword/:token?"
            render={(props) => <ResetPassword {...props} />}
            exact
          />
          <Route path="/about" component={AboutPage} exact />
          <Route path="/contact" component={ContactPage} exact />
          <Route
            path="/confirm-account/:token?"
            component={AccountConfirmation}
            exact
          />
          <Route path="/fees" component={Fees} exact />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditions}
            exact
          />
          {/* <Route
            path="/moveToLargerDevice"
            component={MoveToLargerDevice}
            exact
          /> */}

          <Route
            path="/create-organisation"
            component={CreateOrganisation}
            exact
          />
          <div style={{ display: "flex" }}>
            <DynamicSidebar />
            <AccountConfirmationMiddleware>
              <Route path="/dashboard" component={HomeUser} exact />
              <Route path="/history" component={PriceHistory} exact />
              <Route path="/price-alerts" component={PriceAlerts} exact />
              <Route path="/heat-map" component={HeatMap} exact />

              <Route path="/xero-auth" component={XeroAuth} exact />

              <Route
                path="/organisation/colleagues"
                component={Colleagues}
                exact
              />
              <Route
                path="/water-trading/carry-over-parking"
                component={CarryOverParking}
                exact
              />
              <Route
                path="/water-trading/forward-allocation"
                component={forwardAllocation}
                exact
              />
              <Route
                path="/water-trading/lease-of-entitlement"
                component={leaseOfEntitlement}
                exact
              />
              <Route
                path="/water-trading/permanent-entitlement"
                component={permanentEntitlement}
                exact
              />
              <Route
                path="/water-trading/temporary-allocation"
                component={temporaryAllocation}
                exact
              />

              <Route path="/water-trading/bids" component={bids} exact />
              <Route path="/water-licenses" component={WaterLicenses} exact />
              <Route
                path="/manage-water-licenses"
                component={manageWaterLicenses}
                exact
              />
              <Route path="/user" component={userProfile} exact />
              <Route path="/users" component={users} exact />

              <Route path="/orders/all" component={AllOrders} exact />
              <Route path="/user/listings" component={ListingsPage} exact />
              <Route path="/settings" component={Settings} exact />
              <Route path="/phone-number" component={PhoneNumberPage} exact />
              <Route path="/referrals" component={ReferralPage} exact />
              <Route path="/admin" component={HomeAdmin} exact />
              <Route
                path="/manage-organisations"
                component={ManageOrganisations}
                exact
              />
            </AccountConfirmationMiddleware>
          </div>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

export const carryOverParkingRegions = [
  {
    options: [
      {
        label: "All",
        value: "",
        tradeZone: "",
        tradeZoneDetails: "",
      },
    ],
  },
  {
    label: "NSW",
    options: [
      {
        label: "NSW Murrumbridgee_Zone 13 - General Security",
        value: "NSW Murrumbridgee_Zone 13 - General Security",
        tradeZone: "NSW Murrumbridgee_Zone 13 - General Security",
        tradeZoneDetails: "NSW Murrumbridgee_Zone 13 - General Security",
        state: "NSW",
      },

      {
        label: "NSW Murray (Below Choke)_Zone 11 - General Security",
        value: "NSW Murray (Below Choke)_Zone 11 - General Security",
        tradeZone: "NSW Murray (Below Choke)_Zone 11 - General Security",
        tradeZoneDetails: "NSW Murray (Below Choke)_Zone 11 - General Security",
        state: "NSW",
      },

      {
        label: "NSW Murray (Above Choke)_Zone 10 - General Security",
        value: "NSW Murray (Above Choke)_Zone 10 - General Security",
        tradeZone: "NSW Murray (Above Choke)_Zone 10 - General Security",
        tradeZoneDetails: "NSW Murray (Above Choke)_Zone 10 - General Security",
        state: "NSW",
      },
    ],
  },
  {
    label: "VIC",
    options: [
      {
        label: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        value: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        tradeZone: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        tradeZoneDetails: "VIC Murray (Below Choke)_Zone 7 - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        value: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        tradeZone: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        tradeZoneDetails: "VIC Murray (Below Choke)_Zone 7 - Low Reliability",
        state: "VIC",
      },

      {
        label: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        value: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        tradeZone: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        tradeZoneDetails: "VIC Murray (Above Choke)_Zone 6 - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        value: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        tradeZone: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        tradeZoneDetails: "VIC Murray (Above Choke)_Zone 6 - Low Reliability",
        state: "VIC",
      },

      {
        label: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        value: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        tradeZone: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        tradeZoneDetails: "VIC Lower Broken Creek_Zone 6B - High Reliability",
        state: "VIC",
      },
      {
        label: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        value: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        tradeZone: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        tradeZoneDetails: "VIC Lower Broken Creek_Zone 6B - Low Reliability",
        state: "VIC",
      },
      {
        label: "VIC Goulburn_Zone 1A - High Reliability",
        value: "VIC Goulburn_Zone 1A - High Reliability",
        tradeZone: "VIC Goulburn_Zone 1A - High Reliability",
        tradeZoneDetails: "VIC Goulburn_Zone 1A - High Reliability",
        state: "VIC",
      },

      {
        label: "VIC Goulburn_Zone 1A - Low Reliability",
        value: "VIC Goulburn_Zone 1A - Low Reliability",
        tradeZone: "VIC Goulburn_Zone 1A - Low Reliability",
        tradeZoneDetails: "VIC Goulburn_Zone 1A - Low Reliability",
        state: "VIC",
      },
    ],
  },
];

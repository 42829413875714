import React from "react";

const InfiniteScrollText = () => {
  return (
    <div className="m-scroll">
      <div className="m-scroll__title">
        <div>
          <h1>
            <a href="" className="gradient-text">
              Water Moves Fast with TBWE
            </a>{" "}
            <a href="" className="gradient-text">
              Water Moves Fast with TBWE
            </a>{" "}
            <a href="" className="gradient-text">
              Water Moves Fast with TBWE
            </a>
            &nbsp;
          </h1>
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollText;

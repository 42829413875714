// src/components/Homepage.js
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WaterListingSearchSection from "../component/section/waterListingSearchSection";
import HomepageDataSection from "../component/section/homepageDataSection";
import ViewListing from "../component/modals/listing/viewListing";
import { getWaterListingById } from "../action/waterListingAction"; // Import the action to fetch a single water listing
import { useLocation } from "react-router-dom";
import Footer from "../component/misc/footer";
import InfiniteTextScroll from "../component/misc/InfiniteTextScroll";

const useStyles = makeStyles(() => ({}));

const Homepage = () => {
  const classes = useStyles();
  const [market, setMarket] = useState(null);
  const [zone, setZone] = useState(null);

  const [selectedWaterListing, setSelectedWaterListing] = useState(null);
  const [showWaterListingModal, setShowWaterListingModal] = useState(false);
  const location = useLocation();

  const handleZoneChange = (newZone) => {
    setZone(newZone);
  };

  const handleMarketChange = (newMarket) => {
    setMarket(newMarket);
  };

  const textData = [
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
    "First piece of text",
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const waterListingId = queryParams.get("waterListingId");

    if (waterListingId) {
      fetchWaterListing(waterListingId);
    }
  }, [location]);

  const fetchWaterListing = async (listingId) => {
    try {
      const data = await getWaterListingById(listingId);
      if (data.error) {
        throw new Error(data.error);
      }
      setSelectedWaterListing(data);
      setShowWaterListingModal(true);
    } catch (error) {
      console.error("Failed to fetch water listing:", error);
    }
  };

  const closeWaterListingModal = () => {
    setShowWaterListingModal(false);
    setSelectedWaterListing(null);
  };

  return (
    <div>
      {selectedWaterListing && (
        <ViewListing
          isOpen={showWaterListingModal}
          onClose={closeWaterListingModal}
          listingId={selectedWaterListing._id}
        />
      )}
      <WaterListingSearchSection
        onZoneChange={handleZoneChange}
        onMarketChange={handleMarketChange}
        selectedMarket={market}
        selectedZone={zone}
      />
      <HomepageDataSection
        market={market}
        zone={zone}
        onMarketChange={handleMarketChange}
        onZoneChange={handleZoneChange}
      />

      <InfiniteTextScroll initialText={textData} />

      <Footer />
    </div>
  );
};

export default Homepage;

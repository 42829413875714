import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "@atlaskit/button/standard-button";
import { useHistory } from "react-router-dom";

const HomepageInfoCTA = () => {
  const history = useHistory(); // Create history object

  const handleGoToDashboard = () => {
    history.push("/dashboard"); // Navigate to dashboard
  };

  return (
    <div className="homepage-info-cta">
      <div className="tile tile-large" style={{ padding: "30px" }}>
        <div className="gradient-text" style={{ paddingLeft: "20px" }}>
          Not your average water <br />
          Broking Specialist
        </div>
        <br />
        <ul>
          <FontAwesomeIcon icon={faCheck} /> Benefit from our access to one of
          Australia's largest independent databases
          <br />
          <br />
          <FontAwesomeIcon icon={faCheck} /> Tailored strategy for your
          enterprise
          <br />
          <br /> <FontAwesomeIcon icon={faCheck} /> Market trend expertise
          <br />
          <br /> <FontAwesomeIcon icon={faCheck} /> Focus on long-term
          relationships
          <br />
          <br /> <FontAwesomeIcon icon={faCheck} /> Extensive knowledge of the
          water trading rules and industry.
        </ul>
        <div className="center" style={{ paddingTop: "40px" }}>
          <Button
            appearance="primary"
            className="button-primary-1-dark"
            onClick={handleGoToDashboard}
          >
            Go to Dashboard
          </Button>
        </div>
      </div>

      <div className="tile tile-small">
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "18px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          src="https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Assets/MapRegionImages/NSW-Murrumbridgee_Zone-13.webp"
          alt="image"
        />
      </div>
    </div>
  );
};

export default HomepageInfoCTA;

import React, { useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import Select from "@atlaskit/select";
import { Field } from "@atlaskit/form";
import { temporaryAllocationRegions } from "../../../component/regions/temporaryAllocationRegions";

const FilterModal = ({ isOpen, onClose, filters, onApplyFilters }) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const handleFilterChange = (filterName, selectedOption) => {
    setLocalFilters({
      ...localFilters,
      [filterName]: selectedOption ? selectedOption.value : "",
    });
  };

  const handleApplyFilters = () => {
    onApplyFilters(localFilters);
    onClose();
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={onClose}>
          <ModalHeader>
            <ModalTitle>Filters</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {/* <Field name="listingType" label="Listing Type">
              {({ fieldProps }) => (
                <Select
                  {...fieldProps}
                  options={[
                    { label: "All", value: "" },
                    { label: "Sell Order", value: "Sell Order" },
                    { label: "Buy Order", value: "Buy Order" },
                  ]}
                  placeholder="Select Listing Type"
                  onChange={(selectedOption) =>
                    handleFilterChange("listingType", selectedOption)
                  }
                  value={
                    localFilters.listingType
                      ? {
                          label: localFilters.listingType,
                          value: localFilters.listingType,
                        }
                      : null
                  }
                  menuPortalTarget={document.body} // Render the dropdown menu in the document body
                  menuPosition="fixed" // Ensure the dropdown is positioned correctly
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Ensure it stays on top
                />
              )}
            </Field> */}
            <Field name="region" label="Region">
              {({ fieldProps }) => (
                <Select
                  {...fieldProps}
                  options={temporaryAllocationRegions}
                  placeholder="Select Region"
                  onChange={(selectedOption) =>
                    handleFilterChange("region", selectedOption)
                  }
                  value={
                    localFilters.region
                      ? {
                          label: localFilters.region,
                          value: localFilters.region,
                        }
                      : null
                  }
                  menuPortalTarget={document.body} // Render the dropdown menu in the document body
                  menuPosition="fixed" // Ensure the dropdown is positioned correctly
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Ensure it stays on top
                />
              )}
            </Field>
            {/* Add more filters here as needed */}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={handleApplyFilters}>
              Apply
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default FilterModal;

import React, { useEffect, useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { saveAs } from "file-saver";
import domtoimage from "dom-to-image";

const ViewQRCodeModal = ({ isOpen, onClose, referral }) => {
  const [currentReferral, setCurrentReferral] = useState(null);
  const [referralLink, setReferralLink] = useState("");

  useEffect(() => {
    if (referral) {
      setCurrentReferral(referral);
      setReferralLink(
        `${window.location.origin}/signup?referral=${referral.referralCode}`
      );
    }
  }, [referral]);

  const handleDownloadQRCode = () => {
    const svgElement = document.querySelector("#qr-code-svg-modal svg");

    if (!svgElement) {
      console.error("QR Code SVG not found");
      return;
    }

    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const svgSize = svgElement.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;

    const img = new Image();
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);

    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      URL.revokeObjectURL(url);

      // Download the image
      canvas.toBlob((blob) => {
        saveAs(blob, `${currentReferral.referralCode}_referral_qr.png`);
      }, "image/png");
    };

    img.src = url;
  };

  if (!currentReferral) {
    return null; // Handle case where referral is not yet selected
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={onClose} className="centeredModal">
          <ModalHeader>
            <ModalTitle>
              <b>{currentReferral.referralCode} QR Code</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <p>QR Code: {currentReferral.referralCode}</p>
              <p>
                <a
                  style={{ color: "white" }}
                  href={referralLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {referralLink}
                </a>
              </p>

              <div
                id="qr-code-svg-modal"
                dangerouslySetInnerHTML={{ __html: currentReferral.qrCodeSVG }}
                style={{
                  maxWidth: "300px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "20px",
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button appearance="primary" onClick={handleDownloadQRCode}>
              Download QR Code
            </Button>
            <Button appearance="subtle" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ViewQRCodeModal;

import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button";
import { requireRole } from "./../../action/authAction";
import DynamicTable from "@atlaskit/dynamic-table";
import EmptyState from "../../component/misc/EmptyState";
import Lozenge from "@atlaskit/lozenge";
import { getUsers } from "../../action/userAction";
import Select from "@atlaskit/select";
import UserInfoModal from "../../component/modals/user/userInfoModal";
import { PopupSelect } from "@atlaskit/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import CreateUserModal from "../../component/modals/user/createUserModal";
import EditUserModal from "../../component/modals/user/editUserModal";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isUserInfoModalOpen, setUserInfoModalOpen] = useState(false);
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      if (response.ok) {
        const data = await response.json();
        setUsers(data.users);
        setFilteredUsers(data.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tableHead = {
    cells: [
      { key: "name", content: "Name" },
      { key: "email", content: "Email" },
      { key: "adminVerified", content: "Admin Verified" },
      { key: "brokerageFeePercentage", content: "Brokerage Fee Percentage" },
      { key: "actions", content: "Actions" },
    ],
  };

  const handleSearchChange = (selectedOption) => {
    setSearchValue(selectedOption);
    if (selectedOption) {
      setFilteredUsers(
        users.filter((user) => user._id === selectedOption.value)
      );
    } else {
      setFilteredUsers(users);
    }
  };

  const searchOptions = users.map((user) => ({
    label: `${user.name} - ${user.email}`,
    value: user._id,
  }));

  const handleViewUserInfo = (userId) => {
    setSelectedUserId(userId);
    setUserInfoModalOpen(true);
  };

  const handleEditUser = (userId) => {
    setSelectedUserId(userId);
    setEditUserModalOpen(true);
  };

  const handleUserUpdated = () => {
    setSelectedUserId(null); // Reset selected user

    fetchUsers(); // Refresh the users list
  };

  const renderUsersRows = () => {
    return filteredUsers.map((user, index) => ({
      key: `row-${index}`,
      cells: [
        {
          key: user.name,
          content: (
            <Button
              appearance="link"
              onClick={() => handleViewUserInfo(user._id)}
            >
              {user.name}
            </Button>
          ),
        },
        { key: user.email, content: user.email },
        {
          key: user.adminVerified,
          content: user.adminVerified ? (
            <Lozenge appearance="success">Verified</Lozenge>
          ) : (
            <Lozenge appearance="moved">Not Verified</Lozenge>
          ),
        },
        {
          key: user.brokerageFeePercentage,
          content: `${user.brokerageFeePercentage.toFixed(2)}%`,
        },
        {
          key: "actions",
          content: (
            <PopupSelect
              options={[
                {
                  label: (
                    <span>
                      <FontAwesomeIcon icon={faEdit} /> Edit User
                    </span>
                  ),
                  value: "edit",
                },
                {
                  label: (
                    <span>
                      <FontAwesomeIcon icon={faEye} /> View User Details
                    </span>
                  ),
                  value: "view",
                },
              ]}
              onChange={(option) => {
                if (option.value === "edit") {
                  handleEditUser(user._id);
                } else if (option.value === "view") {
                  handleViewUserInfo(user._id);
                }
              }}
              target={({ isOpen, ...triggerProps }) => (
                <Button {...triggerProps}>...</Button>
              )}
            />
          ),
        },
      ],
    }));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="base-page">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ fontWeight: 700 }}>Users</h2>
        <Button
          appearance="primary"
          onClick={() => setCreateUserModalOpen(true)}
        >
          Create User
        </Button>
      </div>

      <br />
      <Select
        options={searchOptions}
        onChange={handleSearchChange}
        placeholder="Search users..."
        isClearable
        value={searchValue}
      />
      <br />
      <div style={{ paddingBottom: "20px", overflowX: "auto" }}>
        {/* Make the table scrollable */}
        <DynamicTable
          head={tableHead}
          rows={renderUsersRows()}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
          rowsPerPage={15}
          isRankable
          emptyView={<EmptyState message="No users to display." />}
        />
      </div>
      <UserInfoModal
        isOpen={isUserInfoModalOpen}
        onClose={() => setUserInfoModalOpen(false)}
        userId={selectedUserId}
      />
      <EditUserModal
        isOpen={isEditUserModalOpen}
        onClose={() => setEditUserModalOpen(false)}
        userId={selectedUserId}
        onUserUpdated={handleUserUpdated}
      />
      <CreateUserModal
        isOpen={isCreateUserModalOpen}
        onClose={() => setCreateUserModalOpen(false)}
        onUserCreated={handleUserUpdated}
      />
    </div>
  );
};

export default requireRole([99])(Users);

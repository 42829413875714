import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import Select from "@atlaskit/select";
import Button from "@atlaskit/button/new";
import Spinner from "@atlaskit/spinner"; // Atlaskit Loading Spinner
import {
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  BarSeries,
  CandlestickSeries,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons,
  withDeviceRatio,
  withSize,
} from "react-financial-charts";
import { useMarketData } from "../../action/waterTradeAction";
import OHLCTooltip from "./OHLCTooltip";
import { markets } from "../../component/regions/markets";
import { carryOverParkingRegions } from "../../component/regions/carryOverParkingRegions";
import { forwardAllocationRegions } from "../../component/regions/forwardAllocationRegions";
import { leaseOfEntitlementRegions } from "../../component/regions/leaseOfEntitlementRegions";
import { permanentEntitlementRegions } from "../../component/regions/permanentEntitlementRegions";
import { temporaryAllocationRegionsConsolidated } from "../../component/regions/temporaryAllocationRegionsConsolidated";
import { getReliability, getZoneId } from "../../helpers/marketZoneHelper";
const axisStyles = {
  strokeStyle: "#383E55",
  strokeWidth: 2,
  tickLabelFill: "#9EAAC7",
  tickStrokeStyle: "#383E55",
  gridLinesStrokeStyle: "rgba(56, 62, 85, 0.5)",
};

const coordinateStyles = {
  fill: "#383E55",
  textFill: "#FFFFFF",
};

const zoomButtonStyles = {
  fill: "#383E55",
  fillOpacity: 0.75,
  strokeWidth: 0,
  textFill: "#9EAAC7",
};

const crossHairStyles = {
  strokeStyle: "#9EAAC7",
};

const openCloseColor = (d) => {
  if (!d || typeof d.close === "undefined" || typeof d.open === "undefined") {
    return "#9EAAC7"; // Default color if data is not available
  }
  return d.close > d.open ? "#26a69a" : "#ef5350";
};
const yExtentsCalculator = ({ plotData }) => {
  let min;
  let max;
  for (const { low, high } of plotData) {
    if (min === undefined) min = low;
    if (max === undefined) max = high;
    if (low !== undefined && min > low) min = low;
    if (high !== undefined && max < high) max = high;
  }
  if (min === undefined) min = 0;
  if (max === undefined) max = 0;

  const padding = (max - min) * 0.1;
  return [min - padding, max + padding * 2];
};

const FinancialChart = ({
  dateTimeFormat,
  height,
  margin,
  priceDisplayFormat,
  ratio,
  width,
}) => {
  const [market, setMarket] = useState(null);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [zone, setZone] = useState(null);
  const [filters, setFilters] = useState({
    tradeFromZone: -1,
    tradeToZone: -1,
    reliability: null,
  });

  const { data: initialData, loaded } = useMarketData(filters);

  const [resetCount, setResetCount] = useState(0);

  const updateZoneOptions = (marketValue) => {
    switch (marketValue) {
      case "Temporary Allocation":
        setZoneOptions(temporaryAllocationRegionsConsolidated);
        break;
      case "Forward Allocation":
        setZoneOptions(forwardAllocationRegions);
        break;
      case "Carry Over Parking":
        setZoneOptions(carryOverParkingRegions);
        break;
      case "Lease of Entitlement":
        setZoneOptions(leaseOfEntitlementRegions);
        break;
      case "Permanent Entitlement":
        setZoneOptions(permanentEntitlementRegions);
        break;
      default:
        setZoneOptions([]);
    }
  };

  const handleMarketChange = (selectedOption) => {
    setMarket(selectedOption);
    updateZoneOptions(selectedOption?.value);
    setZone(null);
    setFilters({ ...filters, tradeType: selectedOption?.value });
  };

  const handleZoneChange = (selectedOption) => {
    setZone(selectedOption);
    setFilters({
      ...filters,
      tradeFromZone: getZoneId(selectedOption?.value),
      reliability: getReliability(selectedOption?.value),
    });
  };

  const handleClear = () => {
    setMarket(null);
    setZoneOptions([]);
    setZone(null);
    setFilters({
      tradeFromZone: -1,
      tradeToZone: -1,
    });
  };

  // Show Atlaskit loading spinner if data is not loaded yet
  if (!loaded) {
    return (
      <div className="center" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            //alignItems: "center",
            height: "400px",
          }}
        >
          <Spinner size="large" />
        </div>
      </div>
    );
  }

  const timeDisplayFormat = timeFormat(dateTimeFormat);
  const xScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => d.date);
  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(initialData);

  const min = xAccessor(data[Math.max(0, data.length - parseInt(width / 5))]);
  const max = xAccessor(data[data.length - 1]);
  const xExtents = [min, max + 1];

  const gridHeight = height - margin.top - margin.bottom;
  const barChartHeight = gridHeight / 5;
  const barChartOrigin = (_, h) => [0, h - barChartHeight];

  return (
    <div>
      <ChartCanvas
        height={height}
        ratio={ratio}
        width={width}
        margin={margin}
        seriesName={`Chart ${resetCount}`}
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
        zoomAnchor={lastVisibleItemBasedZoomAnchor}
      >
        <Chart
          id={1}
          height={barChartHeight}
          origin={barChartOrigin}
          yExtents={(d) => d.volume}
        >
          <BarSeries
            fillStyle={(d) =>
              d.close > d.open
                ? "rgba(38, 166, 154, 0.3)"
                : "rgba(239, 83, 80, 0.3)"
            }
            yAccessor={(d) => d.volume}
          />
        </Chart>

        <Chart id={2} yExtentsCalculator={yExtentsCalculator}>
          <XAxis {...axisStyles} showGridLines />
          <MouseCoordinateX
            displayFormat={timeDisplayFormat}
            {...coordinateStyles}
          />
          <YAxis {...axisStyles} showGridLines />
          <MouseCoordinateY
            rectWidth={margin.right}
            displayFormat={priceDisplayFormat}
            {...coordinateStyles}
          />

          <EdgeIndicator
            itemType="last"
            rectWidth={margin.right}
            fill={openCloseColor}
            lineStroke={openCloseColor}
            displayFormat={priceDisplayFormat}
            yAccessor={(d) => d.close}
          />

          <CandlestickSeries />
          <OHLCTooltip
            origin={[8, 16]}
            textFill={openCloseColor}
            className="react-no-select"
          />
          <ZoomButtons
            onReset={() => setResetCount(resetCount + 1)}
            {...zoomButtonStyles}
          />
        </Chart>
        <CrossHairCursor {...crossHairStyles} />
      </ChartCanvas>

      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          width: "100%",
          minWidth: "440px",
        }}
      >
        <div style={{ flex: "1" }}>
          <Select
            options={markets}
            placeholder="Select Market"
            onChange={handleMarketChange}
            value={market}
          />
        </div>
        <div style={{ flex: "1" }}>
          <Select
            options={zoneOptions}
            placeholder="Select Zone"
            isDisabled={!market}
            onChange={handleZoneChange}
            value={zone}
          />
        </div>
        <div>
          <Button onClick={handleClear}>Clear</Button>
        </div>
      </div>
    </div>
  );
};

FinancialChart.propTypes = {
  dateTimeFormat: PropTypes.string,
  height: PropTypes.number,
  margin: PropTypes.object,
  priceDisplayFormat: PropTypes.func,
  ratio: PropTypes.number,
  width: PropTypes.number,
};

FinancialChart.defaultProps = {
  dateTimeFormat: "%d %b '%y \xa0 %H:%M",
  height: 0,
  margin: { left: 0, right: 48, top: 0, bottom: 24 },
  priceDisplayFormat: format(".2f"),
  ratio: 0,
  width: 0,
};

export default withSize({ style: { minHeight: 600 } })(
  withDeviceRatio()(FinancialChart)
);

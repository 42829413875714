import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const TeamTile = ({
  name,
  role,
  description,
  email,
  twitter,
  facebook,
  phone,
  imgUrl,
}) => {
  return (
    <div className="team-tile">
      <img
        src={imgUrl}
        style={{ height: "300px", width: "100%", objectFit: "contain" }}
      />
      <br />
      <br />
      <h3>{name}</h3>
      <p>
        <strong>{role}</strong>
      </p>
      <p>{description}</p>
      <div className="footer-social">
        <a
          href={`mailto:${email}`}
          className="social-icon"
          style={{ fontSize: "1.5rem", color: "white" }}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
        <a
          href={`tel:${phone}`}
          className="social-icon"
          style={{ fontSize: "1.5rem", color: "white" }}
        >
          <FontAwesomeIcon icon={faPhone} />
        </a>
      </div>
    </div>
  );
};

export default TeamTile;

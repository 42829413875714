import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const getListingAlerts = async () => {
  try {
    const response = await fetch(`${API}/listing-alerts`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const postListingAlert = async (listingAlertData) => {
  try {
    const response = await fetch(`${API}/listing-alert`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(listingAlertData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const deleteListingAlert = async (listingAlertId) => {
  try {
    const response = await fetch(`${API}/listing-alert`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({ listingAlertId: listingAlertId }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

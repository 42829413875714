import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@atlaskit/button/standard-button";
import { useHistory } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const AboutUsInfoCTA = () => {
  const history = useHistory(); // Create history object

  const handleEmailUs = () => {
    window.location.href =
      "mailto:info@tbwater.com.au?subject=Inquiry%20from%20Website";
  };

  return (
    <div className="homepage-info-cta">
      <div className="tile tile-small" style={{ minHeight: "600px" }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "18px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          src="https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Assets/MapRegionImages/VIC-Goulburn_Zone-1A.webp"
          alt="image"
        />
      </div>
      <div
        className="tile tile-large"
        style={{ padding: "30px", minHeight: "600px" }}
      >
        <div className="tbwe-text" style={{ paddingLeft: "20px" }}>
          About us
        </div>
        <br />
        <p>
          <strong>
            Welcome to True Blue Water Exchange, your 100% Australian-owned and
            operated water brokerage firm.
          </strong>
        </p>
        <p>
          With main office locations in Irymple (Sunraysia) and Coleambally
          (Riverina), our team spans four states—Victoria, New South Wales,
          South Australia, and Queensland—offering localized expertise across
          Australia’s key agricultural regions. <br /> <br />
          At TBWE, we provide a seamless, client-focused trading experience,
          including the option to trade online through our innovative trading
          platform. Specializing in the buying, selling, and leasing of water
          entitlements on behalf of water market participants, we deliver
          tailored advice on water products, trading rules, and market trends.
          Our mission is to understand each client’s goals and provide unique
          strategies that support their long-term success, helping them make
          informed, future-focused decisions. We are dedicated to exceptional
          service and client satisfaction, prioritising building strong
          relationships within the agricultural community.
          <br /> <br />
          Our vision is to expand our networks and solidify TBWE’s position as a
          leading water brokerage, delivering results that exceed expectations.
        </p>
        <div className="center" style={{ paddingTop: "40px" }}>
          <Button
            appearance="primary"
            className="button-primary-1-dark"
            onClick={handleEmailUs}
          >
            <FontAwesomeIcon icon={faEnvelope} /> Email us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsInfoCTA;

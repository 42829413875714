import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

// Fetches the user's phone number
export const getUserPhoneNumber = async () => {
  try {
    const response = await fetch(`${API}/user/phone`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Returns { phoneNumber: "user's phone number" }
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

// Updates the user's phone number
export const updateUserPhoneNumber = async (phoneNumber) => {
  try {
    const response = await fetch(`${API}/user/phone`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({ phoneNumber }),
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Returns { message: "success message" }
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

// Existing functions
export const getBrokerageFee = async () => {
  try {
    const response = await fetch(`${API}/user/brokerage-fee`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const getBrokerageFeeWaterTrade = async (waterListingId) => {
  try {
    const response = await fetch(
      `${API}/user/brokerage-fee-water-trade/${waterListingId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await fetch(`${API}/users`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const getUser = async (userId) => {
  try {
    const response = await fetch(`${API}/user/${userId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Returns the user object including the referredBy field
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const getUserById = async (userId) => {
  try {
    const response = await fetch(`${API}/user/${userId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Returns the user object including the referredBy field
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const updateUser = async (userId, updatedData) => {
  try {
    const response = await fetch(`${API}/user/${userId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(updatedData),
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Returns the updated user object
    } else {
      const errorData = await response.json();
      return { error: errorData.message };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

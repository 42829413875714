import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  createWaterLicense,
  getWaterLicenses,
  deleteWaterLicenseById,
} from "../../action/waterLicenseAction";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import Select from "@atlaskit/select";
import { markets } from "../../component/regions/markets";
import { carryOverParkingRegions } from "../../component/regions/carryOverParkingRegions";
import { forwardAllocationRegions } from "../../component/regions/forwardAllocationRegions";
import { leaseOfEntitlementRegions } from "../../component/regions/leaseOfEntitlementRegions";
import { permanentEntitlementRegions } from "../../component/regions/permanentEntitlementRegions";
import { temporaryAllocationRegions } from "../../component/regions/temporaryAllocationRegions";
import DynamicTable from "@atlaskit/dynamic-table";
import EmptyState from "../../component/misc/EmptyState";
import Lozenge from "@atlaskit/lozenge";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PopupSelect } from "@atlaskit/select";

const useStyles = makeStyles(() => ({
  containerOuter: {
    display: "flex",
    justifyContent: "center",
  },
  containerDark: {
    backgroundColor: "#24222b",
    minWidth: "300px",
    padding: "40px",
    borderRadius: "10px",
    width: "100%",
  },
  containerLight: {
    backgroundColor: "#ececec",
    minWidth: "300px",
    padding: "20px",
    borderRadius: "10px",
  },
}));

const WaterLicenses = () => {
  const history = useHistory();
  const [licenses, setLicenses] = useState([]); // State to store fetched licenses
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [ownerOfWaterAccount, setOwnerOfWaterAccount] = useState("");
  const [abaNumber, setAbaNumber] = useState("");
  const [weeNumber, setWeeNumber] = useState("");

  const [numberOfUnits, setNumberOfUnits] = useState("");

  const [nowRef, setNowRef] = useState("");
  const [wal, setWal] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [selectedState, setSelectedState] = useState("");

  const [zoneOptions, setZoneOptions] = useState([]);

  const [licenseZoneDetails, setLicenseZoneDetails] = useState("");

  const { theme } = useTheme();
  const classes = useStyles();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from being submitted
    handleContinue();
    //toggleModal(); // Open the modal
  };

  const handleZoneSelection = (selectedOption) => {
    setSelectedZone(selectedOption.tradeZone);
    setSelectedState(selectedOption.state);
    setLicenseZoneDetails(selectedOption.tradeZoneDetails || ""); // Assuming 'details' is the field containing zone details
  };

  const handleDeleteLicense = async (licenseId) => {
    try {
      await deleteWaterLicenseById(licenseId);
      toast.success("Water license deleted successfully!");
      fetchWaterLicenses(); // Refresh the list after deletion
    } catch (error) {
      toast.error("Error deleting water license.");
    }
  };

  const updateZoneOptions = (selectedMarket) => {
    switch (selectedMarket) {
      case "Temporary Allocation":
        setZoneOptions(temporaryAllocationRegions);
        break;
      case "Forward Allocation":
        setZoneOptions(forwardAllocationRegions);
        break;
      case "Carry Over Parking":
        setZoneOptions(carryOverParkingRegions);
        break;
      case "Lease of Entitlement":
        setZoneOptions(leaseOfEntitlementRegions);
        break;
      case "Permanent Entitlement":
        setZoneOptions(permanentEntitlementRegions);
        break;
      default:
        setZoneOptions([]);
    }
  };

  const fetchWaterLicenses = async () => {
    try {
      const response = await getWaterLicenses();
      if (response.ok) {
        const data = await response.json();
        setLicenses(data); // Set the fetched licenses into state
      }
    } catch (error) {
      console.log(error);
    }
  };
  const tableHead = {
    cells: [
      { key: "licenseZone", content: "License Zone" },
      { key: "marketType", content: "Market Type" },
      { key: "nowRef", content: "NOW Ref" },
      { key: "wal", content: "WAL" },
      { key: "abaNumber", content: "ABA" },
      { key: "weeNumber", content: "WEE No." },
      { key: "accountNumber", content: "Account Number" },
      { key: "verified", content: "Status" },
      { key: "actions", content: "Actions" },
    ],
  };

  // Function to render the licenses as rows
  const renderLicenseRows = () => {
    return licenses.map((license, index) => ({
      key: `row-${index}`,
      cells: [
        { key: license.licenseZone, content: license.licenseZone },
        { key: license.marketType, content: license.marketType },
        { key: license.nowRef, content: license.nowRef },
        { key: license.wal, content: license.wal },
        { key: license.abaNumber, content: license.abaNumber },
        { key: license.weeNumber, content: license.weeNumber },
        { key: license.accountNumber, content: license.accountNumber },
        {
          key: license.verified,
          content:
            license.verified === "Verified" ? (
              <Lozenge appearance="success">Verified</Lozenge>
            ) : (
              <Lozenge appearance="moved">Pending Verification</Lozenge>
            ),
        },
        {
          key: "actions",
          content: (
            <PopupSelect
              options={[{ label: "Delete Water License", value: "delete" }]}
              onChange={() => handleDeleteLicense(license._id)}
              target={({ isOpen, ...triggerProps }) => (
                <Button {...triggerProps}>...</Button>
              )}
            />
          ),
        },
      ],
    }));
  };
  const clearFormFields = () => {
    setSelectedMarket(null);
    setSelectedZone(null);
    setOwnerOfWaterAccount("");
    setAbaNumber("");
    setWeeNumber("");
    setNowRef("");
    setWal("");
    setNumberOfUnits("");
    setSelectedState("");
  };

  const renderTextFieldsBasedOnState = () => {
    switch (selectedState) {
      case "NSW":
        return (
          <>
            <br />
            <span
              style={{ fontSize: "24px", padding: "10px", fontWeight: "600" }}
            >
              License, farm or water account number
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              {selectedMarket !== "Temporary Allocation" &&
                selectedMarket !== "Forward Allocation" && (
                  <>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <span
                        style={{
                          padding: "10px",
                          fontSize: "18px",
                          width: "50%",
                          opacity: 0.8,
                        }}
                      >
                        Account Number
                      </span>

                      <div style={{ paddingRight: 10, width: "50%" }}>
                        <Field name="accountNumber">
                          {({ fieldProps }) => (
                            <Textfield
                              appearance="standard"
                              label="Standard"
                              placeholder="Account Number"
                              type="text"
                              style={{ width: "100%" }}
                              value={accountNumber}
                              onChange={(e) => setAccountNumber(e.target.value)} // Update state on change
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <span
                        style={{
                          padding: "10px",
                          fontSize: "18px",
                          width: "50%",
                          opacity: 0.8,
                        }}
                      >
                        WAL
                      </span>

                      <div style={{ paddingRight: 10, width: "50%" }}>
                        <Field name="wal">
                          {({ fieldProps }) => (
                            <Textfield
                              appearance="standard"
                              label="Standard"
                              placeholder="WAL"
                              type="text"
                              style={{ width: "100%" }}
                              value={wal}
                              onChange={(e) => setWal(e.target.value)} // Update state on change
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </>
                )}
              {(selectedMarket === "Temporary Allocation" ||
                selectedMarket === "Forward Allocation") && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <span
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      width: "50%",
                      opacity: 0.8,
                    }}
                  >
                    Account Number
                  </span>

                  <div style={{ paddingRight: 10, width: "50%" }}>
                    <Field name="accountNumber">
                      {({ fieldProps }) => (
                        <Textfield
                          appearance="standard"
                          label="Standard"
                          placeholder="Account Number"
                          type="text"
                          style={{ width: "100%" }}
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)} // Update state on change
                        />
                      )}
                    </Field>
                  </div>
                </div>
              )}
            </div>
            <br />
            <span
              style={{ fontSize: "24px", padding: "10px", fontWeight: "600" }}
            >
              Volume / Number of permanent water shares (ML)
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    width: "50%",
                    opacity: 0.8,
                  }}
                >
                  Number of units (ML){" "}
                </span>

                <div style={{ paddingRight: 10, width: "50%" }}>
                  <Field name="numberOfUnits">
                    {({ fieldProps }) => (
                      <Textfield
                        appearance="standard"
                        label="Standard"
                        placeholder="Number of Units"
                        type="number"
                        style={{ width: "100%" }}
                        onChange={(e) => setNumberOfUnits(e.target.value)} // Update state on change
                        value={numberOfUnits}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </>
        );
      case "VIC":
        return (
          <>
            <br />
            <span
              style={{ fontSize: "24px", padding: "10px", fontWeight: "600" }}
            >
              License, farm or water account number
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    width: "50%",
                    opacity: 0.8,
                  }}
                >
                  ABA Number
                </span>

                <div style={{ paddingRight: 10, width: "50%" }}>
                  <Field name="abaNumber">
                    {({ fieldProps }) => (
                      <Textfield
                        appearance="standard"
                        label="Standard"
                        placeholder="ABA Number"
                        type="text"
                        style={{ width: "100%" }}
                        onChange={(e) => setAbaNumber(e.target.value)} // Update state on change
                        value={abaNumber}
                      />
                    )}
                  </Field>
                </div>
              </div>

              {selectedMarket !== "Temporary Allocation" &&
                selectedMarket !== "Forward Allocation" && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <span
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        width: "50%",
                        opacity: 0.8,
                      }}
                    >
                      WEE Number
                    </span>

                    <div style={{ paddingRight: 10, width: "50%" }}>
                      <Field name="weeNumber">
                        {({ fieldProps }) => (
                          <Textfield
                            appearance="standard"
                            label="Standard"
                            placeholder="WEE Number"
                            type="text"
                            style={{ width: "100%" }}
                            onChange={(e) => setWeeNumber(e.target.value)} // Update state on change
                            value={weeNumber}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )}
            </div>

            <span
              style={{ fontSize: "24px", padding: "10px", fontWeight: "600" }}
            >
              Volume / Number of permanent water shares (ML)
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    width: "50%",
                    minWidth: "200px",
                    opacity: 0.8,
                  }}
                >
                  Number of units (ML){" "}
                </span>

                <div
                  style={{ paddingRight: 10, width: "50%", minWidth: "200px" }}
                >
                  <Field name="numberOfUnits">
                    {({ fieldProps }) => (
                      <Textfield
                        appearance="standard"
                        label="Standard"
                        placeholder="Number of Units"
                        type="number"
                        style={{ width: "100%" }}
                        value={numberOfUnits}
                        onChange={(e) => setNumberOfUnits(e.target.value)} // Update state on change
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </>
        );
      case "SA":
        return (
          <>
            <br />
            <span
              style={{ fontSize: "24px", padding: "10px", fontWeight: "600" }}
            >
              License, farm or water account number
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    width: "50%",
                    opacity: 0.8,
                  }}
                >
                  Account Number
                </span>

                <div style={{ paddingRight: 10, width: "50%" }}>
                  <Field name="accountNumber">
                    {({ fieldProps }) => (
                      <Textfield
                        appearance="standard"
                        label="Standard"
                        placeholder="Account Number"
                        type="text"
                        style={{ width: "100%" }}
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)} // Update state on change
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <br />

            <span
              style={{ fontSize: "24px", padding: "10px", fontWeight: "600" }}
            >
              Volume / Number of permanent water shares (ML)
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    width: "50%",
                    minWidth: "200px",
                    opacity: 0.8,
                  }}
                >
                  Number of units (ML){" "}
                </span>

                <div
                  style={{ paddingRight: 10, width: "50%", minWidth: "200px" }}
                >
                  <Field name="numberOfUnits">
                    {({ fieldProps }) => (
                      <Textfield
                        appearance="standard"
                        label="Standard"
                        placeholder="Number of Units"
                        type="number"
                        style={{ width: "100%" }}
                        value={numberOfUnits}
                        onChange={(e) => setNumberOfUnits(e.target.value)} // Update state on change
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleContinue = async () => {
    const licenseData = {
      marketType: selectedMarket,
      licenseZone: selectedZone,
      licenseZoneDetails: licenseZoneDetails, // New field for licenseZoneDetails
      nowRef: nowRef,
      wal: wal,
      ownerOfWaterAccount: ownerOfWaterAccount,
      abaNumber: abaNumber,
      weeNumber: weeNumber,
      numberOfUnits: numberOfUnits,
      accountNumber: accountNumber,
    };

    try {
      const response = await createWaterLicense(licenseData);
      console.log(response);
      toast.success("Water license submitted!");
      fetchWaterLicenses(); // Assuming this refreshes the list of licenses
      clearFormFields();
    } catch (error) {
      console.error("Error posting water license", error);
    }
  };

  useEffect(() => {
    fetchWaterLicenses();
  }, [zoneOptions]);

  return (
    <div className="base-page">
      <div style={{ width: "100%" }}>
        <h2 style={{ fontWeight: 700 }}>Your Water Licenses</h2>
      </div>
      <hr />
      <span style={{ opacity: 0.8 }}>
        In order to carry out water trades it is necessary to provide details of
        your water licences. Accordingly please enter the required details below
        or contact your water broker if you require any assistance.
      </span>
      <br />

      <br />
      <ModalTransition>
        {isModalOpen && (
          <Modal autoFocus={false} onClose={toggleModal}>
            <ModalHeader>
              <ModalTitle>Please Confirm</ModalTitle>
            </ModalHeader>
            <ModalBody>
              Adding this water license will{" "}
              <span style={{ fontWeight: 700 }}>replace</span> any water license
              for the same zone and you will need to be reverified. If you don't
              already have a water license for this zone please press Continue.
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={toggleModal}>
                Cancel
              </Button>
              <Button appearance="primary" onClick={handleContinue}>
                Continue
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

      <div className={classes.containerOuter}>
        <div
          className={
            theme === "dark" ? classes.containerDark : classes.containerLight
          }
        >
          <span
            style={{ fontSize: "24px", padding: "10px", fontWeight: "600" }}
          >
            Create a new entitlement or water account
          </span>
          <hr style={{ margin: "0px" }} />
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  width: "50%",
                  minWidth: "200px",
                  opacity: 0.8,
                }}
              >
                Owner of the water entitlement / water account
              </span>

              <div
                style={{ paddingRight: 10, width: "50%", minWidth: "200px" }}
              >
                <Field name="numberOfUnits">
                  {({ fieldProps }) => (
                    <Textfield
                      appearance="standard"
                      label="Standard"
                      placeholder=""
                      type="text"
                      style={{ width: "100%" }}
                      onChange={(e) => setOwnerOfWaterAccount(e.target.value)} // Update state on change
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  width: "50%",
                  minWidth: "200px",
                  opacity: 0.8,
                }}
              >
                Market Type
              </span>

              <div
                style={{ paddingRight: 10, width: "50%", minWidth: "200px" }}
              >
                <Field name="market">
                  {({ fieldProps }) => (
                    <Select
                      {...fieldProps}
                      options={markets}
                      placeholder="Select Market"
                      onChange={(selectedOption) => {
                        setSelectedMarket(selectedOption.value);
                        updateZoneOptions(selectedOption.value);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "100%",
                        }),
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  width: "50%",
                  minWidth: "200px",
                  opacity: 0.8,
                }}
              >
                Select Zone
              </span>

              <div
                style={{ paddingRight: 10, width: "50%", minWidth: "200px" }}
              >
                <Field name="zone">
                  {({ fieldProps }) => (
                    <Select
                      {...fieldProps}
                      options={zoneOptions}
                      placeholder="Select Zone"
                      onChange={(selectedOption) =>
                        handleZoneSelection(selectedOption)
                      }
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "100%",
                        }),
                      }}
                      required={true}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          {renderTextFieldsBasedOnState()}
          <div
            style={{ paddingTop: "35px", paddingLeft: "10px", float: "right" }}
          >
            <Button type="button" appearance="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div style={{ paddingBottom: "20px", overflowX: "auto" }}>
        <DynamicTable
          head={tableHead}
          rows={renderLicenseRows()}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
          rowsPerPage={5}
          isRankable
          emptyView={<EmptyState message="No water licenses to display." />}
        />
      </div>
    </div>
  );
};

export default WaterLicenses;

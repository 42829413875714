import React from "react";
import PreferencesIcon from "@atlaskit/icon/glyph/preferences";
import { Link } from "react-router-dom";
import { ButtonItem, Section } from "@atlaskit/menu";
import { useTheme } from "../theme/ThemeContext";
import { faCloudSun, faUser, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Settings = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="base-page">
      <h2 style={{ fontWeight: "600" }}>Settings</h2>
      <div>
        <Section title="Account">
          <Link
            className={`settings-link-${theme}`}
            to="/resetpassword"
            style={{ textDecoration: "none" }}
          >
            <ButtonItem
              iconBefore={<PreferencesIcon label="Change Password" />}
              description="Update your password to be more secure!"
            >
              Change Password
            </ButtonItem>
          </Link>
          {/* <Link
            className={`settings-link-${theme}`}
            onClick={toggleTheme}
            style={{ textDecoration: "none" }}
            to="/settings"
          >
            <ButtonItem
              iconBefore={<FontAwesomeIcon icon={faCloudSun} />}
              description="Change the theme of TB Water Exchange!"
            >
              Change Theme
            </ButtonItem>
          </Link> */}
          <Link
            className={`settings-link-${theme}`}
            to="/phone-number"
            style={{ textDecoration: "none" }}
          >
            <ButtonItem
              iconBefore={<FontAwesomeIcon icon={faPhone} />}
              description="Adding your phone number allows you to get price alert notifications!"
            >
              Add Phone Number
            </ButtonItem>
          </Link>
        </Section>
        {/* <Section title="Communication & Support">
          <ButtonItem
            iconBefore={<SendIcon label="Change Password" />}
            description="Invite people to share your projects!"
          >
            Invite People
          </ButtonItem>
        </Section> */}
        <br />
        <Section title="Account Info">
          <span
            style={{
              display: "flex",
              padding: "15px",
              fontSize: "14px",
              opacity: 0.7,
            }}
          >
            User ID: {JSON.parse(localStorage.getItem("user"))._id}
          </span>
          <span
            style={{
              display: "flex",
              padding: "15px",
              fontSize: "14px",
              opacity: 0.7,
            }}
          >
            Email: {JSON.parse(localStorage.getItem("user")).email}
          </span>
        </Section>
      </div>
    </div>
  );
};

export default Settings;

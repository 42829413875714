import React, { useEffect } from "react";
import TradeActivity from "../../component/charts/tradeActivity";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    backgroundColor: "#24222b",
    padding: "20px",
    borderRadius: "5px",
  },
}));

const TradeActivityTile = ({
  market,
  zone,
  listingStatus,
  listingType,
  title,
  subTitle,
  limit = 10, // Default to 10 if limit is not provided
  rowsPerPage = 10, // Default to 10 rows per page
  source = "TBWE",
  zoneDetails,
}) => {
  const classes = useStyles();

  useEffect(() => {
    // This effect runs whenever the market or zone changes
    // You can add any additional logic here if needed
  }, [market, zone]);

  return (
    <div className={classes.homepageTileDark}>
      <TradeActivity
        market={market}
        zone={zone}
        listingStatus={listingStatus}
        listingType={listingType}
        title={title}
        subTitle={subTitle}
        limit={limit}
        rowsPerPage={rowsPerPage}
        source={source}
        zoneDetails={zoneDetails}
      />
    </div>
  );
};

export default TradeActivityTile;

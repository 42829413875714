import React from "react";
import ContactUsInfoCTA from "../../component/section/contactUsInfoCTA";
import TeamTile from "../../component/aboutUs/teamTile";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobile,
  faPhone,
  faLocationDot,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(() => ({
  halfGridContainer: {
    width: "100%",
    display: "grid",
    marginLeft: "50px",
    marginRight: "50px",
    marginTop: "25px",
    marginBottom: "25px",

    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "50px",
    "@media (max-width: 1330px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width: 975px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  homepageTileDark: {
    backgroundColor: "#24222b",
    padding: "20px",
    borderRadius: "20px",
    width: "100%",
  },
}));

const ContactPage = () => {
  const classes = useStyles();

  return (
    <div className="about-page">
      <ContactUsInfoCTA />
      <div className="center">
        <h2 style={{ padding: "40px", fontWeight: "700", fontSize: "40px" }}>
          Get in touch with us
        </h2>
      </div>
      <div className="center">
        <div className={classes.halfGridContainer}>
          <div className={classes.homepageTileDark}>
            <div className="center" style={{ padding: "20px" }}>
              <FontAwesomeIcon style={{ fontSize: "60px" }} icon={faPhone} />
            </div>
            <div className="center">
              <div className="tbwe-text">Phone</div>
            </div>
            <div className="center">
              <strong>1300 382 092</strong>
            </div>
          </div>
          <div className={classes.homepageTileDark}>
            <div className="center" style={{ padding: "20px" }}>
              <FontAwesomeIcon style={{ fontSize: "60px" }} icon={faMobile} />
            </div>
            <div className="center">
              <div className="tbwe-text">mobile</div>
            </div>
            <div className="center">
              <strong>0410 468 191</strong>
            </div>
          </div>
        </div>
      </div>
      <div className="center">
        <div className={classes.halfGridContainer}>
          <div className={classes.homepageTileDark}>
            <div className="center" style={{ padding: "20px" }}>
              <FontAwesomeIcon style={{ fontSize: "60px" }} icon={faEnvelope} />
            </div>
            <div className="center">
              <div className="tbwe-text">Email</div>
            </div>
            <div className="center">
              <strong>info@tbwater.com.au</strong>
            </div>
          </div>
          <div className={classes.homepageTileDark}>
            <div className="center" style={{ padding: "20px" }}>
              <FontAwesomeIcon style={{ fontSize: "60px" }} icon={faClock} />
            </div>
            <div className="center">
              <div className="tbwe-text">Business Hours</div>
            </div>
            <div className="center">
              <strong>Monday – Friday: 8am – 6pm</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="center">
        <div className={classes.halfGridContainer}>
          <div className={classes.homepageTileDark}>
            <div className="center" style={{ padding: "20px" }}>
              <FontAwesomeIcon
                style={{ fontSize: "60px" }}
                icon={faLocationDot}
              />
            </div>
            <div className="center">
              <div className="tbwe-text">Locations</div>
            </div>
            <div
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <div className="center" style={{ paddingBottom: "10px" }}>
                <strong>
                  Head Office: 671 Koorlong Avenue, Irymple VIC 3498
                </strong>
              </div>
              <div className="center">
                <strong>
                  NSW Riverina Office: 29 Brolga Place, Coleambally NSW 2707
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default ContactPage;
